<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Grupo
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Grupos
    v-flex(xs12)
      template
        v-dialog(v-model='dialog', persistent max-width='700px')
              v-btn.mb-2(v-if="cre" slot='activator', style="border-radius:5px;background-color:#ff5700;") Nuevo Grupo
              v-container(style='background-color:white !important;max-width: 700px;')
                v-card-title.justify-center
                    span.headline Grupo
                div(style="margin-top: 20px;")
                    v-flex(xs12 sm12)
                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                            v-flex(xs6)
                              v-form(enctype="multipart/form-data")
                                v-text-field(id="nombre"
                                outline
                                v-model="form.titulo"
                                prepend-inner-icon="description"
                                label="Título web:")

                                v-text-field(id="nombre"
                                outline
                                v-model="form.descripcion"
                                prepend-inner-icon="description"
                                label="Descripción:")

                                v-combobox(prepend-inner-icon="description" outline v-model='form.valores' :hide-selected='true' label='Inserte valores asociados' :multiple='true' persistent-hint='' :small-chips='true' :clearable='false')
                                  template(v-slot:no-data='')
                                    div
                                      span(style='padding-left: 5px;') Escriba la presentación del producto y presione 
                                      kbd enter
                                      p
                                      span(style='padding-left: 5px;') para crear agregar uno nuevo.

                                div
                                  v-flex(v-if="loading === true", xs12 sm12 md12)
                                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                      v-progress-circular(indeterminate='', color='primary')
                                  v-flex(v-if="loading === false", xs12 sm12 md12)
                                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                      v-btn(style="background:#ff5700;border-radius:10px;", @click.native='save') Guardar
                                      v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir

        div(style="margin-top:20px")
          v-flex(xs12)
            v-layout(wrap)
              v-flex(xs12)
                v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:100%;')
        v-layout(align-start='', justify-start='', row='', fill-height='')
          v-flex(xs12 style='margin-top: 5px;')
            v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
                v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                    span.white--text Lista de Grupos
            v-data-table.elevation-1(:headers='headers', :items="grupo", :search='search2', style="margin-top: 5px;")
              template(slot='items', slot-scope='props')
                td {{ props.item.titulo }}
                td {{ props.item.descripcion }}
                td {{ props.item.valores }}
                td.justify-start.layout.px-0
                        v-btn.mx-0(v-if="mod" icon, @click='editItem(props.item)', style='margin-top: -8px;')
                            v-icon(color='teal') edit
                        v-btn.mx-0(v-if="elm" icon, @click='deleteItem(props.item)', style='margin-top: -8px;')
                            v-icon(color='pink') delete
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mGrupo",
  components: {},
  data: () => ({
    search2: "",
    loading: false,
    form: {
      titulo: "",
      descripcion: "",
      valores: [],
    },
    dialog: false,
    grupo: [],
    ruta: serverImg,
    headers: [
      { text: "Título web", value: "titulo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Valores", value: "valores" },
      { text: "Acciones" }
    ],
    editedIndex: -1,
    defaultForm: {
      titulo: "",
      descripcion: "",
      valores: [],
    },
    cre: "",
    mod: "",
    elm: ""
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Grupo") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      Api.get("grupo").then(res => {
        this.grupo = res.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.grupo.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("grupo/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.editedIndex = -1;
      this.dialog = false;
      this.initialize();
    },
    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        Api.put("grupo", this.form)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.close();
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            this.$message.error("A ocurrido un error." + err);
          });
      } else {
        Api.post("grupo", this.form)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.close();
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$message.error("A ocurrido un error." + err);
          });
      }
    }
  }
};
</script>
