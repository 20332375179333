<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Supervisores
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Supervisores
    v-dialog(v-model='dialog', persistent max-width='700px')
          //- v-btn.mb-2(v-if='cre' slot='activator', style="border-radius:5px;background-color:#ff6500;") Nuevo Supervisor
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Supervisor
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md6, sm6)
                          v-form(enctype="multipart/form-data")

                            v-text-field(outline
                            v-model="form.login"
                            prepend-inner-icon="description"
                            label="Usuario:")

                            v-text-field(outline
                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            v-model="form.password"
                            prepend-inner-icon="lock"
                            label="Contraseña:")

                            //- v-autocomplete(outline
                            //- v-model="form.zonas"
                            //- :items='zonas'
                            //- multiple
                            //- prepend-inner-icon="description"
                            //- label="Zonas:")

                            //- v-checkbox(v-model='checkk' label='Asignar todas las zonas' color='#ff6500' hide-details='' style='margin-bottom: 20px;')

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Supervisores
        v-data-table.elevation-1(:headers='headers', :items="resultados", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{props.item.co_ven}}
            td {{props.item.ven_des}}
            td(v-if='props.item.cedula') {{props.item.cedula}}
            td(v-else) - -
            td(v-if='props.item.login') {{props.item.login}}
            td(v-else) - -
            td {{props.item.tipo}}
            td {{!props.item.inactivo ? 'Activo' : 'Inactivo'}}
            td.justify-start.layout.px-0
                v-btn(v-if='mod').mx-0(icon, @click='editItem(props.item)', style='margin-top: -7px;')
                    v-icon(color='teal') edit
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Supervisores",
  data: () => ({
    ruta: serverImg,
    loading: false,
    resultados: [],
    show1: false,
    checkk: false,
    form: {
      login: "",
      zonas: [],
      password: ""
    },
    dialog: false,
    editedIndex: -1,
    cre: "",
    mod: "",
    elm: "",
    iconos: [],
    zonas: [],
    headers: [
      { text: "Código", value: "co_ven" },
      { text: "Vendedor", value: "ven_des" },
      { text: "Cédula", value: "cedula" },
      { text: "Usuario", value: "login" },
      { text: "Tipo", value: "tipo" },
      { text: "Estatus", value: "inactivo" },
      { text: "Acción" }
    ],
    defaultForm: {
      login: "",
      zonas: [],
      password: ""
    }
  }),
  watch: {
    checkk(val) {
      if (val) {
        this.form.zonas = [];
        for (let i = 0; i < this.zonas.length; i++) {
          const el = this.zonas[i];
          this.form.zonas.push(el.value)
        }
      } else {
        this.form.zonas = [];
      }
    }
  },
  created() {
    this.initialize();
    this.verificacion();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Supervisores") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      Api.get("usuario").then(res => {
        this.resultados = res.data;
      });
      Api.get("zonas").then(res => {
        this.zonas = [];
        for (let i = 0; i < res.data.length; i++) {
          const el = res.data[i];
          this.zonas.push({value: el.co_zon, text: el.zon_des.trim()})
        }
      });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    editItem(item) {
      this.editedIndex = this.resultados.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("usuario/admin/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    save() {
      this.loading = true;
      if (this.form.login && this.form.password) {
        this.form.login.toLowerCase()
        if (this.editedIndex > -1) {
          Api.put("usuario/admin", this.form)
            .then(() => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false;
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          Api.post("usuario", this.form)
            .then(() => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.loading = false;
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.loading = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
