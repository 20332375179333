<template lang="pug">
  v-container(grid-list-xl, text-xs-center)
    v-layout(row, wrap)
      v-flex(xs6, offset-xs3, style='margin-top: 0px;height: auto;margin-bottom:0px')
        img(src='../assets/logo.png', style='border-radius: 10px;width: 400px;height: auto;')
      v-flex(xs6, offset-xs3)
        h1 Inicio de Sesión
        v-form(enctype="multipart/form-data")
          v-text-field(id="username"
            v-model="form.email"
            prepend-icon="chevron_right",
            required
            label="Usuario:")

          v-text-field(id="password"
            type="password"
            v-model="form.password"
            prepend-icon="chevron_right",
            required
            label="Contraseña:")

          v-spacer
          v-btn(color='orange darken-1', flat, @click.native='login') Entrar
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mLogin",
  data: () => ({
    ruta: serverImg,
    form: {
      id: 1,
      token: "a82kddn282020",
      email: null,
      password: null
    }
  }),
  created() {
    if (this.$store.state.isUserLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    login() {
      Api.post("admin/login", this.form)
        .then(res => {
          if (res.data.status == "Success") {
            this.$store.dispatch("login", res.data);
            this.$cookies.set("token", res.data.token, "5D", "");
            window.location.href = "/";
          } else {
            this.$swal.fire(
              "Oops...",
              "Error encontrado, usuario o contraseña incorrecta.",
              "error"
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
