<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Tickets
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Tickets
    v-layout(align-start='', justify-start='', row='', wrap='' fill-height='')
      v-flex(xs6 style='margin-top: 5px;display:flex;')
        v-menu(ref='menu1' v-model='modal' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
            template(v-slot:activator='{ on, attrs }')
                v-text-field(v-model='dateFormatted' label='Fecha' style='padding-left: 15px;' persistent-hint='' v-bind='attrs' @blur='date = parseDate(dateFormatted)' v-on='on')
            v-date-picker(v-model='date' no-title='' @input='modal = false' dark='')
        v-btn.mx-0(icon @click='buscar' style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
            v-icon(color='#ff6500' style='font-size: 1.70rem;') search
      v-flex(xs6 style='margin-top: 5px;display:flex;justify-content: flex-end;')
        v-progress-circular(v-if='loading' indeterminate='', color='primary' style='margin-top: 20px;')
        vue-excel-xlsx( v-if='resultados2 && resultados2.length > 0' :data='dataExcel' :columns='columnsExcel' :filename="'Fercoven-Tickets'" :sheetname="'sheetname'")
          img(src='../assets/iconos/excel.png', style='height: 60px;width: auto;')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Tickets
        v-data-table.elevation-1(:headers='headers', :items="resultados", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.fecha') {{props.item.fecha | moment('DD/MM/YYYY')}}
            td(v-else) - -
            td(v-if='props.item.nro') {{props.item.nro}}
            td(v-else) - -
            td(v-if='props.item.doc_num') {{props.item.doc_num}}
            td(v-else) - -
            td(v-if='props.item.cliente') {{props.item.cliente}}
            td(v-else) - -
            td(v-if='props.item.vendedor') {{props.item.vendedor}}
            td(v-else) - -
            td(v-if='props.item.estatus') {{props.item.estatus}}
            td(v-else) - -
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Tickets",
  data: vm => ({
    ruta: serverImg,
    resultados: [],
    resultados2: [],
    modal: false,
    dialog4: false,
    loading: true,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    dataExcel: [],
    columnsExcel: [
      {
        label: "Fecha",
        field: "fecha",
      },
      {
        label: "Nro",
        field: "nro",
      },
      {
        label: "Pedido",
        field: "doc_num",
      },
      {
        label: "Cliente",
        field: "cliente",
      },
      {
        label: "Vendedor",
        field: "vendedor",
      }
    ],
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Nro", value: "nro" },
      { text: "Pedido", value: "doc_num" },
      { text: "Cliente", value: "cliente" },
      { text: "Vendedor", value: "vendedor" },
      { text: "Estatus", value: "estatus" }
    ],
    cre: "",
    mod: "",
    elm: ""
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Tickets") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      this.date = new Date().toISOString().substr(0, 10)
      this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10))
      this.buscar()
    },
    buscar() {
      this.loading = true
      this.dialog4 = true
      Api.get("tickets?fecha="+this.date).then(res => {
        this.resultados = res.data;
        this.dialog4 = false
      }).catch(err=> {
        console.log(err)
        this.$swal.fire(
          "¡Atención!",
          "Error de conexión, intente nuevamente.",
          "error"
        );
        this.dialog4 = false
      })
      Api.get("tickets/all").then(res => {
        this.resultados2 = res.data;
        for (let i = 0; i < this.resultados2.length; i++) {
          const el = this.resultados2[i];
            this.dataExcel.push({
              fecha: this.$moment(el.fecha).format('DD-MM-YYYY H:mm A'),
              nro: el.nro,
              doc_num: el.doc_num,
              cliente: el.cliente,
              vendedor: el.vendedor
            })
        }
        this.loading = false
      }).catch(err=> {
        console.log(err)
        this.loading = false
      })
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
.cll {
  .v-messages.theme--light {
    display: none !important;
  }
}
</style>
