import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from "vue-cookies";
import * as VueGoogleMaps from "vue2-google-maps";
import * as jsPDF from "jspdf";
import VueFlashMessage from "vue-flash-message";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import VueGoogleCharts from "vue-google-charts";
import VueApexCharts from "vue-apexcharts";
import "element-ui/lib/theme-chalk/index.css";
import { computeArea } from "spherical-geometry-js";
import { DatePicker } from "element-ui";
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";
import VueSocketIO from "vue-socket.io";
import draggable from "vuedraggable";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueExcelXlsx from "vue-excel-xlsx";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.use(VueExcelXlsx);
Vue.use(CKEditor);
Vue.use(Croppa);
Vue.use(draggable);
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: "https://apitest.fercoven.com",
    // connection: "https://api.fercoven.com",
    // connection: "http://localhost:9122",
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// configure language
locale.use(lang);
Vue.use(DatePicker);
Vue.use(Loading);
Vue.use(computeArea);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const moment = require("moment");
require("moment/locale/es");
Vue.use(require("vue-moment"), {
  moment
});
Vue.use(jsPDF);
Vue.use(VueGoogleCharts);
Vue.use(VueSweetalert2);
require("vue-flash-message/dist/vue-flash-message.min.css");
Vue.use(VueFlashMessage);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDF4KqxF_YQbIgzFgfAw1ywJjnVhddt4SM",
    libraries: "places,directions"
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
