<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Vendedores
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Vendedores
    v-dialog(v-model='dialog2', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Vendedor
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md6, sm6)
                          v-form(enctype="multipart/form-data")

                            v-text-field(outline
                            v-model="form2.login"
                            prepend-inner-icon="description"
                            label="Usuario:")

                            v-text-field(outline
                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            v-model="form2.password"
                            prepend-inner-icon="lock"
                            label="Contraseña:")

                            //- v-autocomplete(outline
                            //- v-model="form2.zonas"
                            //- :items='zonas'
                            //- multiple
                            //- prepend-inner-icon="description"
                            //- label="Zonas:")

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:#e42e28;border-radius:10px;", @click.native='guardar') Guardar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close2') Salir
    v-dialog(v-model='dialog', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Vendedor
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md6, sm6)
                          v-form(enctype="multipart/form-data")

                            v-text-field(outline
                            v-model="form.co_ven"
                            readonly
                            prepend-inner-icon="description"
                            label="Codigo Vendedor:")

                            v-text-field(outline
                            v-model="form.ven_des"
                            readonly
                            prepend-inner-icon="description"
                            label="Nombre Vendedor:")

                            div
                              img(v-for='(item,index) in iconos' :id='"img"+index' :src='ruta + item.imagen' height='35' width='25' style='margin:10px;cursor: pointer;' @click='seleccionarIcon(item,index)')

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Vendedores
        v-data-table.elevation-1(:headers='headers', :items="resultados", style="margin-top: 5px;" hide-actions,)
          template(slot='items', slot-scope='props')
            td(v-if='props.item.co_ven') {{props.item.co_ven}}
            td(v-else) - -
            td(v-if='props.item.ven_des') {{props.item.ven_des}}
            td(v-else) - -
            td(v-if='props.item.version') {{props.item.version}}
            td(v-else) - -
            td(v-if='props.item.zonas && props.item.zonas.length > 0') Si
            td(v-else) No
            td(v-if='props.item.icono')
              img(:src='ruta + props.item.icono', height='35', width='25')
            td(v-else) No
            td.justify-start.layout.px-0
              v-tooltip(v-if='iconos && iconos.length > 0' bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem(props.item)' v-on="on" color='teal') edit
                | Editar icono.
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem2(props.item)' v-on="on" color='teal') add_box
                | Editar información
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(v-if='props.item.block' @click='changeStatus(props.item)' v-on="on" color='teal') block
                  v-icon.mr-2(v-else @click='changeStatus(props.item)' v-on="on" color='red') block
                | Bloquear Usuario
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(v-if='props.item.banned' @click='changeStatus2(props.item)' v-on="on" color='teal') cancel
                  v-icon.mr-2(v-else @click='changeStatus2(props.item)' v-on="on" color='red') cancel
                | Bannear Usuario
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Vendedores",
  data: () => ({
    ruta: serverImg,
    loading: false,
    resultados: [],
    show1: false,
    form: {
      co_ven: "",
      ven_des: "",
      icono: ""
    },
    form2: {
      co_ven: "",
      login: "",
      zonas: [],
      password: "",
    },
    dialog: false,
    dialog2: false,
    iconos: [],
    zonas: [],
    headers: [
      { text: "Codigo", value: "co_ven" },
      { text: "Vendedor", value: "ven_des" },
      { text: "Version", value: "version" },
      { text: "Zonas", value: "zonas" },
      { text: "Icono", value: "icono" },
      { text: "Acción" }
    ],
    defaultForm2: {
      co_ven: "",
      login: "",
      zonas: [],
      password: ""
    },
    defaultForm: {
      co_ven: "",
      ven_des: "",
      icono: ""
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    seleccionarIcon(item, index) {
      this.form.icono = item.imagen
      for (let i = 0; i < this.iconos.length; i++) {
        document.getElementById("img"+i).setAttribute("style", "width:25px;height:35px;margin:10px;cursor: pointer;");
      }
      document.getElementById("img"+index).setAttribute("style", "width:25px;height:35px;margin:10px;cursor: pointer;border: 1px solid #757575;border-radius: 10px;padding: 2px;");
    },
    initialize() {
      Api.get("iconos").then(res => {
        this.iconos = res.data;
      });
      Api.get("vendedor/icono").then(res => {
        this.resultados = res.data;
      });
      Api.get("zonas").then(res => {
        this.zonas = [];
        for (let i = 0; i < res.data.length; i++) {
          const el = res.data[i];
          this.zonas.push({value: el.co_zon, text: el.zon_des.trim()})
        }
      });
      this.$nextTick(() => {
        for (let i = 0; i < this.iconos.length; i++) {
          document.getElementById("img"+i).setAttribute("style", "width:25px;height:35px;margin:10px;cursor: pointer;");
        }
      })
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    close2() {
      this.form2 = Object.assign({}, this.defaultForm2);
      this.dialog2 = false;
    },
    editItem2(item) {
      Object.keys(this.form2).map(key => {
        if (item[key]) this.form2[key] = item[key];
      });
      this.dialog2 = true;
    },
    editItem(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (this.form.icono) {
        for (let i = 0; i < this.iconos.length; i++) {
          if (this.form.icono === this.iconos[i].imagen) {
            document.getElementById("img"+i).setAttribute("style", "width:25px;height:35px;margin:10px;cursor: pointer;border: 1px solid #757575;border-radius: 10px;padding: 2px;");
          }
        }
      }
      this.form._id = item.idIcon;
      this.dialog = true;
    },
    guardar() {
      if (this.form2.login && this.form2.password) {
        this.loading = true
        Api.put("vendedor", this.form2)
            .then(res => {
              if (res.data.status === "succes") {
                this.form2 = Object.assign({}, this.defaultForm2);
                this.dialog2 = false;
                this.$swal.fire(
                  "Felicidades.!",
                  "Modificación realizada exitosamente.",
                  "success"
                );
                this.loading = false;
                this.initialize();
              } else {
                this.loading = false;
                this.$swal.fire(
                  "¡Atención!",
                  res.data.error,
                  "error"
                );
              }
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
        });
      } else {
        this.$swal.fire(
          "¡Atención!",
          "Estimado usuario, para continuar con la operación debe ingresar todos los datos solicitados.",
          "error"
        );
      }
    },
    changeStatus(item) {
      console.log(item)
      let info = {
        co_ven: item.co_ven,
        usuario: this.$store.state.adminfercoven.nombre,
        tipo: "Web",
        block: !item.block ? true : false,
      }
      this.loading = true;
      console.log(info)
      Api.put("vendedor/app", info)
        .then(res => {
          this.$swal.fire(
            "Felicidades.!",
            "Modificación realizada exitosamente.",
            "success"
          );
          this.loading = false;
          this.initialize();
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
      });
    },
    changeStatus2(item) {
      let info = {
        co_ven: item.co_ven,
        banned: !item.banned ? true : false,
      }
      this.loading = true;
      console.log(info)
      Api.put("vendedor/app2", info)
        .then(res => {
          this.$swal.fire(
            "Felicidades.!",
            "Modificación realizada exitosamente.",
            "success"
          );
          this.loading = false;
          this.initialize();
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
      });
    },
    save() {
      if (this.form.icono) {
          this.loading = true;
          let info = {
            _id: this.form._id,
            icono: this.form.icono
          }
          if (info._id) {
            Api.put("ubicacion", info)
                .then(res => {
                  this.form = Object.assign({}, this.defaultForm);
                  this.dialog = false;
                  this.$swal.fire(
                    "Felicidades.!",
                    "Modificación realizada exitosamente.",
                    "success"
                  );
                  this.loading = false;
                  this.initialize();
                })
                .catch(err => {
                  console.log(err);
                  this.loading = false;
                  this.$swal.fire(
                    "Oops...",
                    "Error encontrado, verifique los datos o llame al administrador.",
                    "error"
                  );
            });
          } else {
            Api.post("ubicacion/admin", this.form)
                .then(res => {
                  this.form = Object.assign({}, this.defaultForm);
                  this.dialog = false;
                  this.$swal.fire(
                    "Felicidades.!",
                    "Modificación realizada exitosamente.",
                    "success"
                  );
                  this.loading = false;
                  this.initialize();
                })
                .catch(err => {
                  console.log(err);
                  this.loading = false;
                  this.$swal.fire(
                    "Oops...",
                    "Error encontrado, verifique los datos o llame al administrador.",
                    "error"
                  );
            });
          }
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, debe seleccionar un icono para continuar con la operación",
            "error"
          );
        }
    }
  }
};
</script>
