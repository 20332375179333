<template lang="pug">
  v-navigation-drawer#app-drawer(:mini-variant='mini', v-model='inputValue', app='', dark='', floating='', persistent='', permanent='', mobile-break-point='991', width='260')
    v-list
      v-list-tile(@click.stop='mini = !mini')
        v-list-tile-action
          v-icon menu
        v-list-tile-title {{ nombre }} {{ apellido }}
      v-list-tile(avatar='', tag='div')
    v-layout.fill-height(tag='v-list', column='')
      v-divider
      v-list-tile.v-list-item(active-class='secundary', to='/home' ,avatar='')
        v-list-tile-action
          v-icon.mgl home
        v-list-tile-title Inicio
      v-list-group.v-list-item(v-if='chf === true', prepend-icon='description', no-action='').mtd
        template(v-slot:activator='').brd
            v-list-tile-title.txt Web
        v-list-tile.v-list-item(v-for='(link3, i) in items2', active-class='primary', :to='link3.link', avatar='', v-if='link3.permiso === mostrar(link3.permiso)')
          v-list-tile-action
            v-icon.mgl {{ link3.icon }}
          v-list-tile-title(v-text='link3.title')
      v-list-tile.v-list-item(v-for='(link, i) in items', :key='i', active-class='secundary', :to='link.link', avatar='' , v-if='!link.child && link.permiso === mostrar(link.permiso)')
        v-list-tile-action
          v-icon.mgl {{ link.icon }}
        v-list-tile-title(v-text='link.title')
      v-divider(style='margin: -1px auto 0px !important;')
      v-list-tile.v-list-item(active-class='secundary', @click='logout' ,avatar='')
        v-list-tile-action
          v-icon.mgl lock_open
        v-list-tile-title Cerrar de sesión
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mHeader",
  data: () => ({
    ruta: serverImg,
    chf: false,
    nombre: "",
    apellido: "",
    contador: 0,
    contador2: 0,
    offer: false,
    drawer: true,
    inputValue: true,
    mini: true,
    image:
      "https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg",
    items: [
      {
        icon: "person",
        title: "Empleados",
        permiso: "Empleados",
        link: "/permisos",
        requiresAuth: true
      },
      {
        icon: "image",
        title: "Iconos",
        permiso: "Iconos",
        link: "/iconos",
        requiresAuth: true
      },
      {
        icon: "description",
        title: "Devoluciones",
        permiso: "Devoluciones",
        link: "/devoluciones",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Categoría",
        permiso: "Categoria",
        link: "/categoria",
        requiresAuth: true
      },
      {
        icon: "attach_money",
        title: "Valijas",
        permiso: "Valija",
        link: "/valija",
        requiresAuth: true
      },
      {
        icon: "attach_money",
        title: "Cobros",
        permiso: "Cobros",
        link: "/cobros",
        requiresAuth: true
      },
      {
        icon: "description",
        title: "Historial Activacion",
        permiso: "Historial Activacion",
        link: "/historialActivacion",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Línea",
        permiso: "Linea",
        link: "/linea",
        requiresAuth: true
      },
      {
        icon: "groups",
        title: "Supervisores",
        permiso: "Supervisores",
        link: "/supervisores",
        requiresAuth: true
      },
      {
        icon: "description",
        title: "Solicitudes",
        permiso: "Solicitudes",
        link: "/solicitudes",
        requiresAuth: true
      },
      {
        icon: "description",
        title: "Tickets",
        permiso: "Tickets",
        link: "/tickets",
        requiresAuth: true
      },
      {
        icon: "shopping_cart",
        title: "Productos",
        permiso: "Productos",
        link: "/productos",
        requiresAuth: true
      },
      {
        icon: "group",
        title: "Vendedores",
        link: "/vendedores",
        requiresAuth: true
      },
      {
        icon: "gps_fixed",
        title: "Seguimiento",
        permiso: "Seguimiento",
        link: "/seguimiento",
        requiresAuth: true
      },
      {
        icon: "gps_fixed",
        title: "Seguimiento C.",
        permiso: "SeguimientoCli",
        link: "/seguimientoCli",
        requiresAuth: true
      },
      {
        icon: "settings",
        title: "Configuraciones",
        permiso: "Configuraciones",
        link: "/settings",
        requiresAuth: true
      }
    ],
    items2: [
      {
        icon: "description",
        title: "Acerca de nosotros",
        permiso: "Acerca de nosotros",
        link: "/quienes",
        requiresAuth: true
      },
      {
        icon: "image",
        title: "Banners",
        permiso: "Banners",
        link: "/banners",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Categoría",
        permiso: "CategoriaWeb",
        link: "/categoriaWeb",
        requiresAuth: true
      },
      {
        icon: "image",
        title: "Carousel",
        permiso: "Carousel",
        link: "/carousel",
        requiresAuth: true
      },
      {
        icon: "contact_support",
        title: "Contacto",
        permiso: "Contacto",
        link: "/contacto",
        requiresAuth: true
      },
      {
        icon: "group",
        title: "Clientes",
        permiso: "ClientesWeb",
        link: "/clientesWeb",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Departamentos",
        permiso: "Departamentos",
        link: "/departamentos",
        requiresAuth: true
      },
      {
        icon: "groups",
        title: "Grupos",
        permiso: "Grupo",
        link: "/grupo",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Marcas",
        permiso: "Marcas",
        link: "/marcas",
        requiresAuth: true
      },
      {
        icon: "view_module",
        title: "Subcategoría",
        permiso: "Subcategoria",
        link: "/subcategoria",
        requiresAuth: true
      },
    ]
  }),
  sockets: {
  },
  created() {
    // this.initialize();
    if (this.$store.state.adminfercoven) {
      this.nombre = this.$store.state.adminfercoven.nombre;
      this.apellido = this.$store.state.adminfercoven.apellido;
    }
    if (this.$store.state.adminfercoven.permisos) {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (
          this.$store.state.adminfercoven.permisos[i].ruta === this.items2[0].permiso ||
          this.$store.state.adminfercoven.permisos[i].ruta === this.items2[1].permiso ||
          this.$store.state.adminfercoven.permisos[i].ruta === this.items2[2].permiso ||
          this.$store.state.adminfercoven.permisos[i].ruta === this.items2[3].permiso
        ) {
          this.chf = true;
        }
      }
    }

  },
  computed: {
    login() {
      return this.$store.state.isUserLoggedIn;
    }
  },
  methods: {
    // initialize() {},
    mostrar(item) {
      if (this.$store.state.adminfercoven.permisos) {
        for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
          if (this.$store.state.adminfercoven.permisos[i].ruta === item) {
            return this.$store.state.adminfercoven.permisos[i].ruta;
          }
        }
      }
    },
    showMenu(item) {
      if (this.$store.state.isUserLoggedIn && item.requiresAuth) {
        return true;
      } else if (!item.requiresAuth && !this.$store.state.isUserLoggedIn) {
        return true;
      }
      return false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$cookies.set("token", [], "5D", "");
      window.location.href = "/";
    }
  }
};
</script>
<style lang="scss">
#app-drawer {
  .v-list__tile__title {
    color: white !important;
  }
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }

  .v-image__image--contain {
    top: 9px;
    height: 60%;
  }
  .fill-height {
    height: auto !important;
  }

  .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .primary--text {
    color: white !important;
    caret-color: white !important;
  }
  .txt {
    height: 48px !important;
    font-size: 14px !important;
    font-weight: 300;
    padding: 9px;
    margin-left: -3px;
    color: #fff !important
  }
  .brd {
    border-radius: 5px;
  }
  .v-list__group__header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    border-radius: 5px;
  }
  .mtd {
    margin-left: 12px;
    margin-right: 6px;
  }
  .mgl {
    margin-left: -4px;
  }
}
.theme--dark.v-navigation-drawer {
  background-color: black !important;
}
.v-navigation-drawer .v-list {
  background: black !important;
}
.v-navigation-drawer
  .v-list
  .v-list-item
  .v-list__tile.v-list__tile--active.secundary {
  background-color: #ff6500 !important;
  -webkit-box-shadow: 0 12px 20px -10px rgba(97, 159, 122, 0.28),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(97, 159, 122, 0.2) !important;
  box-shadow: 0 12px 20px -10px rgba(97, 159, 122, 0.28),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(97, 159, 122, 0.2) !important;
}
</style>
