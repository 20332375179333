<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Settings
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Configuraciones
    v-flex(xs12 style='margin-top:20px;')
        v-layout(align-center='' justify-center='' row='' wrap='')
            v-flex(xs12 sm8 md7 style='margin-top: 5px;')
              v-text-field(outline
                v-model="form.direccion"
                prepend-inner-icon="description"
                label="Dirección:")
              v-text-field(outline
                v-model="form.whatsapp"
                prepend-inner-icon="description"
                label="Whatsapp:")
              v-text-field(outline
                v-model="form.instagram"
                prepend-inner-icon="description"
                label="Instagram:")
            v-flex(xs12 sm8 md7 style='margin-top: 5px;')
              v-checkbox(v-model='form.habilitado' label='Habilitar promoción de tickets' color='#ff6500' hide-details='' style='margin-bottom: 20px;')
            v-flex(xs12 sm8 md7 style='margin-top: 5px;' v-if='form.habilitado')
                v-text-field(outline
                    v-model="form.montoTicket"
                    type='number'
                    min='0'
                    label="Monto para asignación de tickets:"
                    style='width:100%')
            v-flex(v-if="loading === true", xs12 sm8 md7)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    v-progress-circular(indeterminate='', color='primary')
            v-flex(v-if="loading === false", xs12 sm8 md7)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='obtenerTicket') Obtener ticket
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Settings",
  data: () => ({
    ruta: serverImg,
    loading: false,
    dialog4: false,
    settings: {},
    form: {
      direccion: "",
      whatsapp: "",
      instagram: "",
      montoTicket: 0,
      habilitado: false,
    },
    defaultForm: {
      direccion: "",
      whatsapp: "",
      instagram: "",
      montoTicket: 0,
      habilitado: false
    },
    cre: "",
    mod: "",
    elm: ""
  }),
  watch: {
    "form.habilitado": function(val) {
      if (!val) {
        this.form.montoTicket = 0
      }
    }
  },
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Configuraciones") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      Api.get("settings").then(res => {
        if (res.data && res.data.length > 0) {
          this.settings = res.data[0]
          this.form.direccion = this.settings.direccion
          this.form.whatsapp = this.settings.whatsapp
          this.form.instagram = this.settings.instagram
          this.form.montoTicket = this.settings.montoTicket
          this.form.habilitado = this.settings.habilitado
        }
      });
    },
    obtenerTicket() {
      this.dialog4 = true
      Api.get("tickets/premio").then(res => {
        if (res.data && res.data.length > 0) {
          this.$swal.fire({
            title: "¡Felicidades!",
            text: "El ticket ganador es el número: " + res.data[0].nro,
            showCancelButton: true,
            cancelButtonText: `Limpiar todos los tickets`,
            icon: "success"
          }).then((result) => {
            if (result.dismiss) {
              this.dialog4 = true
              Api.get("tickets/finish")
                .then(res => {
                  this.$swal.fire(
                    "Felicidades.!",
                    "Modificación realizada exitosamente.",
                    "success"
                  );
                  this.dialog4 = false;
                  this.initialize();
                })
                .catch(err => {
                  console.log(err);
                  this.dialog4 = false;
                  this.$swal.fire(
                    "Oops...",
                    "Error encontrado, verifique los datos o llame al administrador.",
                    "error"
                  );
                });
            }
          })
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, no existen tickets guardados en este momento.",
            "error"
          );
        }
        this.dialog4 = false
      }).catch(err=> {
        console.log(err)
        this.dialog4 = false
      })
    },
    save() {
      this.loading = true
      if (this.settings && this.settings._id) {
        this.form._id = this.settings._id
        Api.put("settings", this.form)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        Api.post("settings", this.form)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
.cll {
  .v-messages.theme--light {
    display: none !important;
  }
}
</style>
