import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import LoginApi from "@/services/login";
import Productos from "./views/Productos.vue";
import Seguimiento from "./views/Seguimiento.vue";
import Iconos from "./views/Iconos.vue";
import Vendedores from "./views/Vendedores.vue";
import Permisos from "./views/Permisos.vue";
import Valija from "./views/Valija.vue";
import SeguimientoCli from "./views/SeguimientoCli.vue";
import SinPermiso from "./views/SinPermiso.vue";
import Supervisores from "./views/Supervisores.vue";
import Contacto from "./views/Contacto.vue";
import HistorialActivacion from "./views/HistorialActivacion.vue";
import Devoluciones from "./views/Devoluciones.vue";
import Quienes from "./views/Quienes.vue";
import Categoria from "./views/Categoria.vue";
import ClientesWeb from "./views/ClientesWeb.vue";
import Grupo from "./views/Grupo.vue";
import Cobros from "./views/Cobros.vue";
import Tickets from "./views/Tickets.vue";
import Banners from "./views/Banners.vue";
import Carousel from "./views/Carousel.vue";
import Solicitudes from "./views/Solicitudes.vue";
import Departamentos from "./views/Departamentos.vue";
import CategoriaWeb from "./views/CategoriaWeb.vue";
import Subcategoria from "./views/Subcategoria.vue";
import Marcas from "./views/Marcas.vue";
import Settings from "./views/Settings.vue";
import Linea from "./views/Linea.vue";
import { Loading } from "element-ui";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/banners",
      name: "banners",
      component: Banners,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/grupo",
      name: "grupo",
      component: Grupo,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/valija",
      name: "valija",
      component: Valija,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/devoluciones",
      name: "devoluciones",
      component: Devoluciones,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/seguimientoCli",
      name: "seguimientoCli",
      component: SeguimientoCli,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/historialActivacion",
      name: "historialActivacion",
      component: HistorialActivacion,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/contacto",
      name: "contacto",
      component: Contacto,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/quienes",
      name: "quienes",
      component: Quienes,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/carousel",
      name: "carousel",
      component: Carousel,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/clientesWeb",
      name: "clientesWeb",
      component: ClientesWeb,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/departamentos",
      name: "departamentos",
      component: Departamentos,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/categoriaWeb",
      name: "categoriaWeb",
      component: CategoriaWeb,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/subcategoria",
      name: "subcategoria",
      component: Subcategoria,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/marcas",
      name: "marcas",
      component: Marcas,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        isPublic: true,
        isLog: true
      }
    },
    {
      path: "/tickets",
      name: "tickets",
      component: Tickets,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/solicitudes",
      name: "solicitudes",
      component: Solicitudes,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/supervisores",
      name: "supervisores",
      component: Supervisores,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/cobros",
      name: "cobros",
      component: Cobros,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/linea",
      name: "linea",
      component: Linea,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/iconos",
      name: "iconos",
      component: Iconos,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/categoria",
      name: "categoria",
      component: Categoria,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/vendedores",
      name: "vendedores",
      component: Vendedores,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/seguimiento",
      name: "seguimiento",
      component: Seguimiento,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/productos",
      name: "productos",
      component: Productos,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/sinpermiso",
      name: "sinpermiso",
      component: SinPermiso,
      meta: {
        isPublic: false
      }
    },
    {
      path: "/permisos",
      name: "permisos",
      component: Permisos,
      meta: {
        isPublic: false
      }
    }
  ]
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.isPublic);
  if (requiresAuth === false) {
    LoginApi.refreshtoken()
      .then(res => {
        store.dispatch("setuser", res.data.usuario);
        if (to.path == "/valija") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Valija") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/seguimientoCli") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "SeguimientoCli") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/historialActivacion") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Historial Activacion") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/devoluciones") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Devoluciones") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/quienes") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Acerca de nosotros") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/contacto") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Contacto") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/grupo") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Grupo") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/clientesWeb") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "ClientesWeb") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/carousel") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Carousel") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/banners") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Banners") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/marcas") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Marcas") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/subcategoria") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Subcategoria") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/categoriaWeb") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "CategoriaWeb") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/departamentos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Departamentos") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/tickets") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Tickets") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/settings") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Configuraciones") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/productos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Productos") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/permisos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Empleados") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/seguimiento") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Seguimiento") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/iconos") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Iconos") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/supervisores") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Supervisores") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/categoria") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Categoria") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/linea") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Linea") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/cobros") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Cobros") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else if (to.path == "/solicitudes") {
          let bols = false;
          let bols2 = false;
          let loadingInstance = Loading.service({ fullscreen: true });
          if (store.state.adminfercoven.permisos) {
            for (let i = 0; i < store.state.adminfercoven.permisos.length; i++) {
              if (store.state.adminfercoven.permisos[i].ruta === "Solicitudes") {
                bols = true;
                for (
                  let j = 0;
                  j < store.state.adminfercoven.permisos[i].accion.length;
                  j++
                ) {
                  if (store.state.adminfercoven.permisos[i].accion[j] === "Ver") {
                    bols2 = true;
                    loadingInstance.close();
                    next();
                  }
                }
              }
            }
          }
          if (bols === false || bols2 === false) {
            loadingInstance.close();
            // window.location.href = "/sinpermiso";
            next("/sinpermiso");
          }
        } else {
          next();
        }
      })
      .catch(error => {
        console.log(error);
        next("/");
      });
  } else {
    if (to.path == "/") {
      LoginApi.refreshtoken()
        .then(res => {
          store.dispatch("setuser", res.data.usuario);
          next("/home");
        })
        .catch(error => {
          console.log(error);
          //next("/login")
        });
    }
    next();
  }
});

export default router;
