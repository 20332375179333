<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Seguimiento
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Seguimiento
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-flex(xs12)
              v-layout(wrap)
                v-flex(xs6 sm3 style='margin-top:10px;margin-bottom:4px;')
                  v-select(outline
                    :items="opcion1"
                    v-model="filtroVen"
                    style='padding-left: 15px;'
                    label="Vendedor:")
                v-flex(xs6 sm3 style='margin-top:10px;margin-bottom:4px;')
                  v-select(outline
                    :items="opcion2"
                    v-model="filtroPed"
                    style='padding-left: 15px;'
                    label="Información a mostrar:")
                v-flex(xs6 sm3 style='margin-top:10px;margin-bottom:4px;' v-if='filtroPed === "2"')
                  v-menu(ref='menu1' v-model='menu1' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
                    template(v-slot:activator='{ on, attrs }')
                      v-text-field(v-model='dateFormatted' label='Fecha' hint='MM/DD/YYYY format'  style='padding-left: 15px;' outline persistent-hint='' append-icon='calendar_today' v-bind='attrs' @blur='date = parseDate(dateFormatted)' v-on='on')
                    v-date-picker(v-model='date' no-title='' @input='menu1 = false' dark='')
                v-flex(xs6 sm3 style='display: flex;padding-left: 20px;align-items: center;')
                  v-btn(icon style="background:#ff6500;border-radius:10px;" @click='busqueda()').elevation-4
                    v-icon(color='white') search
        GmapMap(id='mapa' :center='centrarmapa', :zoom='zooom', style='width: 100%; height: 500px')
            GmapMarker(v-for='item in marcadores', :position='item.coordenadas', :clickable='true', :icon='item.icono' :draggable='false' @click="openInfoWindowTemplate(item)")
            gmap-info-window(:options='{maxWidth: 300}', :position='infoWindow.position', :opened='infoWindow.open', @closeclick='infoWindow.open=false')
                div(style='display:flex;flex-direction:column;')
                    span(v-if='info.doc_num') <b> Pedido: </b> {{info.doc_num}}
                    span <b> Vendedor: </b> {{info.vendedor}}
                    span(v-if='info.cliente && info.cliente') <b> Cliente: </b> {{info.cliente}}
                    span(v-if='!info.doc_num') <b> Última conexión: </b> {{info.fecha | moment('DD-MM-YYYY H:mm A')}}
                    span(v-if='info.doc_num') <b> Fecha: </b> {{info.fecha | moment('DD-MM-YYYY H:mm A')}}
                    p
                    a(v-if='info.doc_num' style='text-align: center;' @click='dialog = true') <b> Ver Pedido </b>
    v-dialog(v-model='dialog', persistent max-width='1028px')
      v-container(style='background-color:white !important;max-width: 1028px;')
        v-flex(xs12)
          v-card(flat='')
            v-flex(xs12 md12 sm12 style='margin-top:10px')
              v-layout(row, wrap)
                v-flex(xs12 style='display: flex;')
                  div(style='display: flex;align-items: center;')
                    img(src='../assets/logo.png', width='auto' ,height='60')
                v-flex(xs12 style='margin-top: 20px;background: #bfbebe;height: 108px;display: flex;align-items: flex-end;')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs6)
                      div(style='padding-left: 15px;')
                        p(style='font-weight: 500;') Nro. Pedido: {{info.doc_num}}
                        p(style='font-weight: 500;') Fecha: {{info.fecha | moment('DD-MM-YYYY H:mm A')}}
                        p(style='font-weight: 500;' v-if='info.cliente && info.cliente') Cliente: {{info.cliente}}
                        p(style='font-weight: 500;' v-if='info.pedidos && info.pedidos.co_mone') Moneda: {{info.pedidos.co_mone}}
                    v-flex(xs6 style='display: flex;justify-content: center;align-items: center;')
                      p(style='font-size: 21px !important;font-weight: 600;' v-if='info.pedidos && info.pedidos.status') {{estatusPedi(info.pedidos.status)}}
                v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;border-bottom: 1px solid;')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs4 style='padding-left: 15px;')
                      span(style='font-weight: 500;') Producto
                    v-flex(xs2)
                      span(style='font-weight: 500;display: flex;justify-content: center;') Unidad
                    v-flex(xs2)
                      span(style='font-weight: 500;display: flex;justify-content: center;') Cantidad
                    v-flex(xs4)
                      span(style='font-weight: 500;display: flex;justify-content: center;') Monto
                v-flex(v-for='(item,index) in info.articulos' xs12 v-bind:class="{ 'active': par(index), 'pasive': comparar(index) }" style='height: 45px;background: #f3f3f3;display: flex;align-items: center;')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs4 style='padding-left: 15px;')
                      span(style='font-weight: 400;font-size: 14px;') {{item.articulo.art_des}}
                    v-flex(xs2)
                      span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') {{item.co_uni}}
                    v-flex(xs2)
                      span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') {{item.total_art}}
                    v-flex(xs4)
                      span(style='font-weight: 400;display: flex;justify-content: center;font-size: 14px;') {{info.pedidos.co_mone}} {{transform(item.reng_neto)}}
                v-flex(xs12 style='height: 45px;background: #fff;display: flex;align-items: center;')
                v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;' v-if='info.pedidos && info.pedidos.monto_imp')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs6)
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 500;') SubTotal:
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 400 !important;font-style: italic;') {{info.pedidos.co_mone}} {{transform(info.pedidos.total_bruto)}}
                v-flex(xs12 style='height: 45px;background: #fff;display: flex;align-items: center;' v-if='info.pedidos && info.pedidos.monto_imp')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs6)
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 500;') Iva:
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 400 !important;font-style: italic;') {{info.pedidos.co_mone}} {{transform(info.pedidos.monto_imp)}}
                v-flex(xs12 style='height: 45px;background: #f3f3f3;display: flex;align-items: center;' v-if='info.pedidos && info.pedidos.total_neto')
                  v-layout(row, wrap style='line-height: 1;')
                    v-flex(xs6)
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 500;') Total:
                    v-flex(xs3 style='display: flex;justify-content: center;')
                      span(style='font-weight: 400 !important;font-style: italic;') {{info.pedidos.co_mone}} {{transform(info.pedidos.total_neto)}}
          v-flex(xs12 sm12 style='margin-top:20px')
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-btn(style="background:black;border-radius:10px;", @click.native='dialog=false') Salir
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mSeguimiento",
  data: vm => ({
    dialog4: false,
    ruta: serverImg,
    centrarmapa: { lat: 10.0738900, lng: -69.3227800 },
    zooom: 12,
    opcion1: [],
    opcion2: [
      {
        text: "Solo Vendedores",
        value: "1"
      }, 
      {
        text: "Pedidos y Vendedores",
        value: "2"
      }
    ],
    filtroVen: "",
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu1: false,
    filtroPed: "1",
    infoWindow: {
      position: { lat: 0, lng: 0 },
      open: false,
      template: ""
    },
    dialog: false,
    info: {},
    marcadores: [],
    vendedores: [],
    pedidos: []
  }),
  sockets: {
    act() {
      this.actSocket();
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    transform(val) {
      val = val.toString().split('.')
      if(val.length > 1) return val[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + ','+ val[1].slice(0,2);
      return val[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    estatusPedi(status) {
      status = status.replace(' ','')
      switch(status){
        case '0':return 'Creado'
        case '1':return 'Parcial'
        case '2':return 'Procesado'
        case '3':return 'Sin Enviar'
      }
    },
    comparar(data) {
      if ((data+1) === this.info.articulos.length) {
        return true
      } else {
        return false
      }
    },
    par(data) {
      if (data % 2==0) {
          return false
      } else {
          return true
      }
    },
    openInfoWindowTemplate(item) {
      this.infoWindow.position = item.coordenadas;
      this.infoWindow.open = true;
      this.info = item;
    },
    async busqueda() {
      this.infoWindow.open = false;
      this.dialog4 = true;
      let info = {
        vendedor: this.filtroVen,
        pedido: this.filtroPed,
        fecha: this.date
      }
      await Api.post("ubicacion/seguimiento", info).then(res => {
        this.marcadores = res.data
        for (let i = 0; i < this.marcadores.length; i++) {
          if (this.marcadores[i].vend) {
            this.marcadores[i].icono = (this.marcadores[i].icono) ? this.ruta + this.marcadores[i].icono : null
          }
          if (!this.marcadores[i].vend) {
            this.marcadores[i].icono = require("../assets/iconos/pedido.png")
          }
        }
        this.dialog4 = false
      }).catch(err => {
        this.dialog4 = false
      })
    },
    async actSocket() {
      let info = {
        vendedor: this.filtroVen,
        pedido: this.filtroPed,
        fecha: this.date
      }
      console.log(info)
      await Api.post("ubicacion/seguimiento", info).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          let bool = false
          let bool2 = false
          for (let j = 0; j < this.marcadores.length; j++) {
            if (this.marcadores[j].co_ven === res.data[i].co_ven && this.marcadores[j].vend) {
              bool = true
              this.marcadores[j].coordenadas = {
                lat: parseFloat(res.data[j].coordenadas.lat),
                lng: parseFloat(res.data[j].coordenadas.lng)
              }
            }
            if (res.data[i].doc_num && this.marcadores[j].doc_num && this.marcadores[j].doc_num === res.data[i].doc_num) {
              bool2 = true
            }
          }
          if (!bool) {
            this.marcadores.push(res.data[i])
            this.marcadores[this.marcadores.length - 1].icono = (this.marcadores[this.marcadores.length - 1].icono) ? this.ruta + this.marcadores[this.marcadores.length - 1].icono : null
          }
          if (!bool2 && res.data[i].doc_num) {
            this.marcadores.push(res.data[i])
            this.marcadores[this.marcadores.length - 1].icono = require("../assets/iconos/pedido.png")
          }
        }
      })
    },
    async initialize() {
      this.dialog4 = true
      this.opcion1 = []
      this.marcadores = []
      this.opcion1.push({
        text: "Todos",
        value: "1"
      })
      this.filtroVen = "1"
      let info = {
        vendedor: this.filtroVen,
        pedido: this.filtroPed,
        fecha: this.date
      }
      await Api.post("ubicacion/seguimiento", info).then(res => {
        this.marcadores = res.data
        for (let i = 0; i < this.marcadores.length; i++) {
          if (this.marcadores[i].vend) {
            this.marcadores[i].icono = (this.marcadores[i].icono) ? this.ruta + this.marcadores[i].icono : null
            this.opcion1.push({
              text: this.marcadores[i].vendedor,
              value: this.marcadores[i].co_ven
            })
          }
          if (!this.marcadores[i].vend) {
            this.marcadores[i].icono = require("../assets/iconos/pedido.png")
          }
        }
        this.dialog4 = false
      }).catch(err => {
        this.dialog4 = false
      })
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/seguimiento.scss";
</style>