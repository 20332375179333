<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Seguimiento
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Seguimiento
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-flex(xs12)
              v-layout(wrap)
                v-flex(xs6 sm3 style='margin-top:10px;margin-bottom:4px;')
                  v-select(outline
                    :items="clientes"
                    v-model="filtroCli"
                    style='padding-left: 15px;'
                    label="Cliente:")
                v-flex(xs6 sm3 style='display: flex;padding-left: 20px;align-items: center;')
                  v-btn(icon style="background:#4d9bd3;border-radius:10px;" @click='busqueda()').elevation-4
                    v-icon(color='white') search
        GmapMap(id='mapa' :center='centrarmapa', :zoom='zooom', style='width: 100%; height: 500px')
            GmapMarker(v-for='item in marcadores', :position='item.coordenadas', :clickable='true', :icon='item.icono' :draggable='false' @click="openInfoWindowTemplate(item)")
            gmap-info-window(:options='{maxWidth: 300}', :position='infoWindow.position', :opened='infoWindow.open', @closeclick='infoWindow.open=false')
                div(style='display:flex;flex-direction:column;')
                    span(v-if='info.cliente') <b> Cliente: </b> {{info.cliente}}
                    p
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mSeguimiento",
  data: vm => ({
    dialog4: false,
    ruta: serverImg,
    centrarmapa: { lat: 10.0738900, lng: -69.3227800 },
    zooom: 12,
    filtroCli: "",
    infoWindow: {
      position: { lat: 0, lng: 0 },
      open: false,
      template: ""
    },
    info: {},
    marcadores: [],
    clientes: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    openInfoWindowTemplate(item) {
      this.infoWindow.position = item.coordenadas;
      this.infoWindow.open = true;
      this.info = item;
    },
    async busqueda() {
      this.infoWindow.open = false;
      this.marcadores = [];
      this.dialog4 = true;
      await Api.get("cliente/seguimiento?filtro="+this.filtroCli).then(res => {
        res.data.forEach(el=>{
            this.marcadores.push({
                icono: null,
                cliente: el.cli_des.trim(),
                coordenadas: {
                    lat: parseFloat(el.campo7.trim()),
                    lng: parseFloat(el.campo8.trim()),
                }
            })
        })
        this.dialog4 = false
      }).catch(err => {
        this.dialog4 = false
      })
    },
    async initialize() {
      this.dialog4 = true
      this.clientes = []
      this.marcadores = []
      this.clientes.push({
        text: "Todos",
        value: "1"
      })
      this.filtroCli = "1"
      await Api.get("cliente/seguimiento?filtro="+this.filtroCli).then(res => {
        console.log(res.data)
        res.data.forEach(el=>{
            this.clientes.push({
                text: el.cli_des.trim(),
                value: el.co_cli
            })
            this.marcadores.push({
                icono: null,
                cliente: el.cli_des.trim(),
                coordenadas: {
                    lat: parseFloat(el.campo7.trim()),
                    lng: parseFloat(el.campo8.trim()),
                }
            })
        })
        console.log(this.marcadores)
        this.dialog4 = false
      }).catch(err => {
        this.dialog4 = false
      })
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/seguimiento.scss";
</style>