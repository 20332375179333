<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Categoria
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Subcategorías
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#ff6500", centered, dark)
          v-tab(:key='0', ripple='') Subcategorías
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs12)
                        v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Subcategorías
                    v-data-table.elevation-1(:headers='headers', :items="subcategoria", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td {{ props.item.co_color }}
                        td {{ props.item.des_color }}
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mSubcategorias",
  components: {},
  data: () => ({
    search2: "",
    active: null,
    form: {
      co_color: "",
      des_color: ""
    },
    subcategoria: [],
    ruta: serverImg,
    headers: [
      { text: "Codigo", value: "co_color" },
      { text: "Nombre", value: "des_color" }
    ],
    editedIndex: -1,
    defaultForm: {
      co_color: "",
      des_color: ""
    },
    cre: "",
    mod: "",
    elm: ""
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "subcategoria") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      this.editedIndex = -1;
      Api.get("subcategoria").then(res => {
        this.subcategoria = res.data;
      });
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/categoria.scss";
</style>
