<template lang="pug">
  v-app
    Menu(v-if='sesion._id')
    v-content
      .container
        router-view

</template>

<script>
import Menu from "./components/menu.vue";
import Api from "@/services/methods";
export default {
  name: "App",
  components: { Menu },
  data: () => ({
    info: {},
    token: "",
    police: false,
    sesion: []
  }),
  created() {
    // this.$store.commit("load", "");
    this.sesion = this.$store.state.adminfercoven;
    if (this.$route.meta.isPublic === false && !this.$store.state.token && !this.sesion._id) {
      window.location.href = "/";
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$cookies.set("token", [], "5D", "");
      window.location.href = "/";
    }
  }
};
</script>
<style lang="sass">
@import '@/styles/index.scss';
</style>
<style>
::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d5d5d5;
}
::-webkit-scrollbar-thumb:hover {
  transition-property: background-color;
  transition-duration: 3s;
  transition-delay: 1s;
  background-color: #24242494; 
}
</style>
