<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Categoria
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Líneas
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#ff6500", centered, dark)
          v-tab(:key='0', ripple='') Líneas
          v-tab(v-if="mod"  :key='1', ripple='') Posicionamiento
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                v-dialog(v-model='dialog', persistent max-width='700px')
                  v-container(style='background-color:white !important;max-width: 700px;')
                    v-card-title.justify-center
                        span.headline Categoria
                    div(style="margin-top: 20px;")
                        v-flex(xs12 sm12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs6)
                                  v-form(enctype="multipart/form-data")
                                    v-text-field(outline
                                    readonly
                                    v-model="form.co_lin"
                                    prepend-inner-icon="description"
                                    label="Código:")

                                    v-text-field(outline
                                    readonly
                                    v-model="form.lin_des"
                                    prepend-inner-icon="description"
                                    label="Nombre:")

                                    div
                                      v-flex(xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir

                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs12)
                        v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Líneas
                    v-data-table.elevation-1(:headers='headers', :items="linea", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td {{ props.item.co_lin }}
                        td {{ props.item.lin_des }}
                        td(v-if='props.item.orden') {{ props.item.orden }}
                        td(v-else) Sin asignar
                        td.justify-start.layout.px-0
                                v-btn.mx-0(v-if="mod" icon, @click='editItem(props.item)', style='margin-top: -8px;')
                                    v-icon(color='teal') visibility
          v-tab-item(v-if="mod" :key='1')
            v-card(flat='')
              v-flex(xs12)
                v-card(style='border-radius: 10px;')
                  v-layout(row wrap align-center='' justify-center='')
                    v-flex(md4 sm4 xs4)
                      v-card(style='border-radius: 10px;margin: 10px;')
                        v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                          h3 Líneas
                        v-flex(md12 sm12 xs12 style='display: flex;justify-content: center;')
                          draggable(:list="group"  @update="checkGroups"  style='width:95%')     
                            div.item(v-for="(item, key) in group" @mouseover="drage(item)" @mouseleave="leave(item)").divk
                              v-icon.drag(v-if="item._id == drag._id") drag_indicator
                              span.txth {{item.lin_des}}
                              div.menu(@mouseover="seleccionado(item)" @mouseleave="leave(item)")
                                v-icon.icon fas fa-caret-down
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
import draggable from "vuedraggable";
export default {
  name: "mLinea",
  components: {
    draggable
  },
  data: () => ({
    search2: "",
    drag: [],
    active: null,
    form: {
      co_lin: "",
      lin_des: "",
      orden: ""
    },
    dialog: false,
    linea: [],
    ruta: serverImg,
    headers: [
      { text: "Codigo", value: "co_lin" },
      { text: "Nombre", value: "lin_des" },
      { text: "Orden", value: "orden" },
      { text: "Acciones" }
    ],
    editedIndex: -1,
    defaultForm: {
      co_lin: "",
      lin_des: "",
      orden: ""
    },
    group: [],
    cre: "",
    mod: "",
    elm: ""
  }),
  watch: {
    active(val) {
      console.log(val);
    }
  },
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    drage(data) {
      this.drag = data;
    },
    leave() {
      this.drag = [];
      this.selected = [];
    },
    seleccionado(data) {
      this.selected = data;
    },
    checkGroups() {
      for (let i = 0; i < this.group.length; i++) {
        this.group[i].orden = i + 1;
      }
      Api.put("linea/ordenar", this.group)
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Linea") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      Api.get("linea").then(res => {
        this.linea = res.data;
        this.group = [];
        for (let i = 0; i < this.linea.length; i++) {
          this.group.push(this.linea[i]);
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.linea.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    close() {
      this.editedIndex = -1;
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/categoria.scss";
</style>
