<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Iconos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Iconos
    v-dialog(v-model='dialog', persistent max-width='700px')
          v-btn.mb-2(v-if='cre' slot='activator', style="border-radius:5px;background-color:#ff6500;") Nuevo Icono
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Icono
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md6, sm6)
                          v-form(enctype="multipart/form-data")

                            div(style='display: flex;justify-content: center;margin: 10px;')
                              img(:src='ruta + img', height='35', width='25', v-if='img && !form.imagen.imageUrl').wdd
                              img(:src='form.imagen.imageUrl', height='35', width='25', v-if='form.imagen.imageUrl').wdd
                            v-text-field(outline label='Seleccione el icono', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file')
                            input(
                              type="file"
                              style="display: none;"
                              ref="image"
                              accept="image/*"
                              @change="onFilePicked")

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Iconos
        v-data-table.elevation-1(:headers='headers', :items="resultados", style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.imagen')
              img(:src='ruta + props.item.imagen', height='35', width='25')
            td(v-else) - -
            //- td(v-if='props.item.usada') Si
            //- td(v-else) No
            td.justify-start.layout.px-0
                    v-btn.mx-0(v-if='mod' icon, @click='editItem(props.item)', style='margin-top: 2px;')
                        v-icon(color='teal') edit
                    v-btn.mx-0(v-if='elm' icon, @click='deleteItem(props.item)', style='margin-top: 2px;')
                        v-icon(color='pink') delete
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Iconos",
  data: () => ({
    ruta: serverImg,
    loading: false,
    form: {
      usada: null,
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    dialog: false,
    editedIndex: -1,
    resultados: [],
    headers: [
      { text: "Imagen", value: "imagen" },
    //   { text: "Usada", value: "usada" },
      { text: "Acción" }
    ],
    defaultForm: {
      usada: null,
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Iconos") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let peso = false;
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true;
          }
        }
        if (peso === false) {
          this.form.imagen.imageName = files[0].name;
          if (this.form.imagen.imageName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.form.imagen.imageUrl = fr.result;
            this.form.imagen.imageFile = files[0]; // this is an image file that can be sent to server...
          });
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, la imagen debe pesar menos de 4MB.",
            "error"
          );
          this.files = [];
          this.form.images = [];
        }
      } else {
        this.form.imagen.imageName = "";
        this.form.imagen.imageFile = "";
        this.form.imagen.imageUrl = "";
      }
    },
    initialize() {
      this.img = "";
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.editedIndex = -1;
      Api.get("iconos").then(res => {
        this.resultados = res.data;
      });
    },
    close() {
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("iconos/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.resultados.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.imagen) {
        this.img = item.imagen;
      }
      this.form.imagen = {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      };
      this.form._id = item._id;
      this.dialog = true;
    },
    save() {
      this.loading = true;
      if (this.form.imagen.imageFile || this.img) {
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile) {
          data.append("imagen", this.form.imagen.imageFile);
        }
        if (this.editedIndex > -1) {
          Api.put("iconos", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false;
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        } else {
          Api.post("iconos", data)
            .then(res => {
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Registro realizado exitosamente.",
                "success"
              );
              this.loading = false;
              this.initialize();
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
            });
        }
      } else {
        this.loading = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
.cll {
  .v-messages.theme--light {
    display: none !important;
  }
}
</style>
