<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Categoria
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Marcas
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#ff6500", centered, dark)
          v-tab(:key='0', ripple='') Marcas
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                v-dialog(v-model='dialog', persistent max-width='700px')
                  v-container(style='background-color:white !important;max-width: 700px;')
                    v-card-title.justify-center
                        span.headline Imagen
                    div(style="margin-top: 20px;")
                        v-flex(xs12, sm12, md12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs12, md6, sm6)
                                  v-form(enctype="multipart/form-data")

                                    div(style='display: flex;justify-content: center;margin: 10px;')
                                      img(:src='ruta + img' v-if='img && !form.imagen.imageUrl' style='height:200px;width:auto;object-fit:contain;')
                                      img(:src='form.imagen.imageUrl' v-if='form.imagen.imageUrl' style='height:200px;width:auto;object-fit:contain;')
                                    v-text-field(outline label='Seleccione la imagen', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file')
                                    input(
                                      type="file"
                                      style="display: none;"
                                      ref="image"
                                      accept="image/*"
                                      @change="onFilePicked")

                                    div
                                      v-flex(v-if="loading === true", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-progress-circular(indeterminate='', color='primary')
                                      v-flex(v-if="loading === false", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                            v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
                                            v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir

                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs12)
                        v-text-field(v-model='search2', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Marcas
                    v-data-table.elevation-1(:headers='headers', :items="marcas", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td {{ props.item.co_cat }}
                        td {{ props.item.cat_des }}
                        td(v-if='props.item.imagen')
                          img(:src='ruta + props.item.imagen' style='height:100px;width:100px;object-fit:contain;')
                        td(v-else) - -
                        td
                            v-btn.mx-0(v-if="mod" icon, @click='editItem(props.item)')
                                v-icon(color='teal') edit
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mMarcas",
  components: {},
  data: () => ({
    search2: "",
    active: null,
    form: {
      co_cat: "",
      cat_des: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    dialog: false,
    loading: false,
    marcas: [],
    ruta: serverImg,
    headers: [
      { text: "Codigo", value: "co_cat" },
      { text: "Nombre", value: "cat_des" },
      { text: "Imagen", value: "imagen" },
      { text: "Acciones" }
    ],
    editedIndex: -1,
    defaultForm: {
      co_cat: "",
      cat_des: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    cre: "",
    img: "",
    mod: "",
    elm: ""
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Marcas") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    initialize() {
      this.img = "";
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.editedIndex = -1;
      Api.get("marcas").then(res => {
        this.marcas = res.data;
      });
    },
    save() {
      this.loading = true;
      if (this.form.imagen.imageFile || this.img) {
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile) {
          data.append("imagen", this.form.imagen.imageFile);
        }
        Api.put("marcas", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        this.loading = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, debe ingresar todos los campos correspondientes.",
          "error"
        );
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let peso = false;
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true;
          }
        }
        if (peso === false) {
          this.form.imagen.imageName = files[0].name;
          if (this.form.imagen.imageName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.form.imagen.imageUrl = fr.result;
            this.form.imagen.imageFile = files[0]; // this is an image file that can be sent to server...
          });
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, la imagen debe pesar menos de 4MB.",
            "error"
          );
          this.files = [];
          this.form.images = [];
        }
      } else {
        this.form.imagen.imageName = "";
        this.form.imagen.imageFile = "";
        this.form.imagen.imageUrl = "";
      }
    },
    editItem(item) {
      this.editedIndex = this.marcas.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.imagen) {
        this.img = item.imagen;
      }
      this.form.imagen = {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      };
      this.form._id = item._id;
      this.dialog = true;
    },
    close() {
      this.img = "";
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      this.editedIndex = -1;
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/categoria.scss";
</style>
