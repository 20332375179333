<template lang="pug">
div(style="margin-top: -24px;margin-left: 0px;margin-right: 0px;")
  #Productos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Productos
        div(style="margin-top: 20px;")
            v-layout(align-center='', justify-center='', row='', wrap fill-height='')
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinEst()')
                      v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                          span(style='display:flex;justify-content:center;font-size:20px') Total de productos
                          h1(style='display:flex;justify-content:center') {{tot}}
              v-flex(xs4 sm4 style="display: flex;justify-content: center;align-items: center;")
                v-btn(style="background:#ff6500;border-radius:10px;" @click='genCatalogo()') Generar catálogo
                v-btn(style="background:#ff6500;border-radius:10px;" @click='asocImagen()') Asociar imagenes
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinImg()')
                      v-card(style='width:300px;height:120px;border-radius:10px' ).elevation-3
                        span(style='display:flex;justify-content:center;font-size:20px') Productos sin imagenes
                        h1(style='display:flex;justify-content:center') {{sinimg}}
    v-dialog(v-model='dialog2', persistent max-width='800px')
      v-container(style='background-color:white !important;max-width: 800px;')
        v-card-title.justify-center
          span.headline Imagenes
        div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(wrap='')
              v-flex(xs4 style='padding: 5px;height: 200px;' v-for='(item,i) in photos')
                a(style='width:100%;height:100%' @click='imagenselect2(i,ruta + item)')
                  img(:src='ruta + item' style='width:100%;height:100%;border-radius: 15px;' v-if='mostrar').elevation-6
              v-flex(xs12 sm12)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:black;border-radius:10px;", @click.native='close2') Salir
    v-dialog(v-model='dialog3', persistent max-width='400px')
      v-container(style='background-color:white !important;max-width: 400px;overflow:hidden;')
        div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(wrap='')
              v-flex(xs12 sm12)
                croppa(v-model='myCroppa' :width='350' :height='350' canvas-color='white').elevation-6
                  img(slot="initial" :src="phot" crossorigin="anonymous")
              v-flex(xs12 sm12)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                  v-btn(style="background:#ff6500;border-radius:10px;", @click='modific()') Modificar
                  v-btn(style="background:black;border-radius:10px;", @click.native='close3') Salir
    v-dialog(v-model='dialog', persistent max-width='900px')
          v-container(style='display: flex;flex-direction: column;background-color:white !important;width: 100% !important;padding:20px;overflow-x: hidden;')
            v-card-title.justify-center
                span.headline Producto
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                      v-flex(xs6 style='padding: 5px;')
                        v-text-field(
                          outline
                          hide-details=''
                          v-model="form.co_art"
                          readonly
                          prepend-inner-icon="description"
                          label="Codigo artículo profit:")
                      v-flex(xs6 style='padding: 5px;')
                          v-text-field(
                          outline
                          readonly
                          hide-details=''
                          v-model="form.stock"
                          prepend-inner-icon="chevron_right",
                          required
                          label="Existencia:")
                      v-flex(xs12 style='padding: 5px;')
                        v-text-field(
                          outline
                          hide-details=''
                          v-model="form.art_des"
                          readonly
                          prepend-inner-icon="description"
                          label="Nombre:")

                      v-flex(xs6 style='padding: 5px;')
                        v-autocomplete(
                          outline
                          hide-details=''
                          v-model="form.presentacion1.id"
                          :items='grupos'
                          prepend-inner-icon="description"
                          label="Presentación (1):")
                      v-flex(xs6 style='padding: 5px;')
                          v-select(
                          outline
                          hide-details=''
                          :disabled='!form.presentacion1.id'
                          v-model="form.presentacion1.valor"
                          :items='valores'
                          prepend-inner-icon="description",
                          label="Valor:")

                      v-flex(xs6 style='padding: 5px;')
                        v-autocomplete(
                          outline
                          hide-details=''
                          v-model="form.presentacion2.id"
                          :items='grupos'
                          prepend-inner-icon="description"
                          label="Presentación (2):")
                      v-flex(xs6 style='padding: 5px;')
                          v-select(
                          outline
                          hide-details=''
                          :disabled='!form.presentacion2.id'
                          v-model="form.presentacion2.valor"
                          :items='valores2'
                          prepend-inner-icon="description",
                          label="Valor:")

                      v-flex(xs12 style='padding: 5px;')
                        v-textarea(
                          outline
                          hide-details=''
                          v-model="form.descripcion"
                          prepend-inner-icon="description"
                          label="Descripción:")
                      v-flex(xs12 style='padding: 5px;')
                        div(style='border: 2px solid #757575;padding: 12px;border-radius: 3px;')
                          span(style='color: #495057;font-size: 14px !important;' v-for='item in form.precios') <b>-  Código Precio: </b> {{item.co_precio}}, <b> Precio: </b> {{item.monto}}
                      v-flex(xs12 style='padding: 5px;')
                        v-layout(wrap)
                          v-flex(xs4 v-for='(item,i) in form.imagen' style='padding: 10px;')
                            v-card(style='width: 100%;height: 190px;' v-if='item.imageName').elevation-3
                              div(style='position:absolute;width:100%;display:flex;justify-content:flex-end')
                                v-btn(@click="indice(i, item.imageName)",color='error').btnnn X
                              img(:src='item.imageUrl', v-if='item.imageUrl' style='width: 100%;height:100%')
                            v-card(style='width: 100%;height: 190px;' v-if='item.url').elevation-3
                              div(style='position:absolute;width:100%;display:flex;justify-content:flex-end')
                                v-btn(@click="indice(i, item.url)",color='error').btnnn X
                              img(:src='ruta + item.url', v-if='item.url' style='width: 100%;height:100%')
                        v-text-field(outline label='Seleccionar imagenes desde pc', @click='pickFile', prepend-inner-icon='attach_file', multiple)
                        input(
                          type="file"
                          style="display: none"
                          ref="image"
                          accept="image/*"
                          @change="onFilePicked"
                          multiple)
                        div(style='margin-top:20px;')
                          v-flex(v-if="loading === true", xs12 sm12 md12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                              v-progress-circular(indeterminate='', color='primary')
                          v-flex(v-if="loading === false", xs12 sm12 md12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                              v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save' :disable='guard') Guardar
                              v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-flex(xs12 style='margin-top: 5px;')
          v-layout(wrap)
            v-flex(xs6)
              v-text-field(v-model='search5', label='Buscar')
            v-flex(xs2 style='display: flex;align-items: center;')
              v-btn.mx-0(icon, @click='busqinit(search5)', style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
                v-icon(color='#ff6500' style='font-size: 1.70rem;') search
            v-flex(xs4 style='display: flex;align-items: center;justify-content: flex-end;' v-if='catalogo')
              a(style='width:60px;margin-top: 15px;' :href='ruta + catalogo' target="_blank")
                img(src='../assets/pdf.svg', height='auto', width='40')
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Productos
        v-data-table.elevation-1(:headers='headers', :items="productos", style="margin-top: 5px;", hide-actions, :pagination.sync="pagination")
          template(slot='items', slot-scope='props')
            td(v-if="props.item.co_art") {{ props.item.co_art }}
            td(v-else) {{ "- -" }}
            td(v-if="props.item.art_des") {{ props.item.art_des }}
            td(v-else) {{ "- -" }}
            td {{ props.item.stock }}
            td(v-if='props.item.imagen && props.item.imagen.length > 0') Si
            td(v-else) No
            td.justify-start.layout.px-0
              v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -9px;')
                v-icon(color='teal') edit
              //- v-btn.mx-0(icon, @click='offer(props.item)', style='margin-top: -9px;')
              //-   v-icon(color='teal') local_offer
              v-btn.mx-0(v-if='props.item.imagen && props.item.imagen.length > 0' icon, @click='open(props.item)', style='margin-top: -9px;')
                v-icon(color='black') crop
              v-btn.mx-0(icon, @click='deleteItem(props.item)', style='margin-top: -9px;')
                v-icon(color='pink') delete
        v-card
          v-flex(xs12)
            v-layout(wrap='')
              v-flex(xs6)
              v-flex(xs5 style='display: flex;justify-content: flex-end;align-items: center;')
                div(style='margin: 10px;')
                  span {{min}} a {{max}} elementos
                div(style='margin: 2px;')
                  v-btn.mx-0(id='prev' icon, @click='prev()', style='background: gainsboro;border-radius:50%;' hidden)
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_left
                div(style='margin: 2px;' v-for='(item,index) in listado')
                  v-btn.mx-0(:id='"num"+item', @click='seleccion(item)' icon, style='background: gainsboro;border-radius:50%;')
                    span(style='font-weight: bold;') {{item}}
                div(style='margin: 2px;')
                  v-btn.mx-0(id='next' icon, @click='next()', style='background: gainsboro;border-radius:50%;')
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_right
              v-flex(xs1)
              v-flex(xs11 style='display: flex;justify-content: flex-end;')
                v-flex#sll(xs2 style='display: flex;justify-content: flex-end;align-items: center;margin-right: 17px;')
                  v-select(id="paginas"
                  outline
                  @change='seleccion(selpag)'
                  :items="paginas"
                  v-model="selpag"
                  dense
                  required
                  label="Página:")
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { serverImg } from "@/services/environment";
export default {
  name: "mProductos",
  data: () => ({
    loading: false,
    formOffer: {
      rebaja: "",
      _id: ""
    },
    defaultFormOffer: {
      rebaja: "",
      _id: ""
    },
    catalogo: "",
    imgsel: false,
    editor: ClassicEditor,
    editorConfig: {},
    grupos: [],
    seleccionado: null,
    listado: [],
    guard: false,
    pagination: {
      rowsPerPage: 20
    },
    dialog4: false,
    mostrar: true,
    paginas: [],
    selpag: "",
    dialog2: false,
    dialog3: false,
    myCroppa: {},
    phot: "",
    multiple: true,
    index: null,
    search2: "",
    search3: "",
    search5: "",
    colecciones: [],
    photos: [],
    actual: 0,
    form: {
      descripcion: "",
      co_art: "",
      art_des: "",
      stock: "",
      presentacion1: {
        id: null,
        valor: ""
      },
      presentacion2: {
        id: null,
        valor: ""
      },
      precios: [],
      imagen: [],
    },
    dialog: false,
    editedIndex: -1,
    categoria: [],
    ruta: serverImg,
    productos: [],
    headers: [
      { text: "Código", value: "co_art" },
      { text: "Articulo", value: "art_des" },
      { text: "Stock", value: "stock" },
      { text: "Imagen", value: "imagen" },
      { text: "Acción" }
    ],
    hideSelected: true,
    chips: true,
    gruposAll: [],
    grupos: [],
    valores: [],
    valores2: [],
    filtro: false,
    clearable: false,
    defaultForm: {
      descripcion: "",
      co_art: "",
      art_des: "",
      stock: "",
      presentacion1: {
        id: null,
        valor: ""
      },
      presentacion2: {
        id: null,
        valor: ""
      },
      precios: [],
      imagen: [],
    },
    files: [],
    files2: [],
    idimg: "",
    sw1: "Si",
    sw2: "Disponible",
    min: 0,
    max: 0,
    tot: 0,
    sinimg: 0
  }),
  watch: {
    "form.presentacion1.id": function(val) {
      if (val) {
        this.valores = []
        this.gruposAll.forEach(el=> {
          if (el._id.toString() == val.toString()) this.valores = el.valores
        })
      }
    },
    "form.presentacion2.id": function(val) {
      if (val) {
        this.valores2 = []
        this.gruposAll.forEach(el=> {
          if (el._id.toString() == val.toString()) this.valores2 = el.valores
        })
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    asocImagen() {
      this.dialog4 = true;
      Api.get("productos/asignarImg").then(res => {
        this.dialog4 = false;
        this.initialize()
        this.$swal.fire(
          "Felicidades.!",
          "imagenes asignadas exitosamente.",
          "success"
        );
      }).catch(err => {
        this.dialog4 = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, verifique los datos o llame al administrador.",
          "error"
        );
      })
    },
    genCatalogo() {
      this.dialog4 = true;
      Api.get("productosCom/generarCatalogo").then(res => {
        this.dialog4 = false;
        this.$swal.fire(
          "Felicidades.!",
          "Catalogo generado exitosamente.",
          "success"
        );
      }).catch(err => {
        this.dialog4 = false;
        this.$swal.fire(
          "Oops...",
          "Error encontrado, verifique los datos o llame al administrador.",
          "error"
        );
      })
    },
    sinImg() {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      this.imgsel = true;
      Api.get(
        "productosCom/sinseleccion?img=" + true
      )
        .then(res => {
          this.productos = [];
          this.listado = [];
          this.paginas = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    sinEst() {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      this.imgsel = false;
      Api.get("productosCom/sinseleccion?img=" + this.imgsel)
        .then(res => {
          this.productos = [];
          this.listado = [];
          this.paginas = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    seleccion(item) {
      this.dialog4 = true;
      Api.get(
        "productosCom/pag?search=" +
          this.search5 +
          "&id=" +
          item +
          "&img=" +
          this.imgsel
      )
        .then(res => {
          this.dialog4 = false;
          this.max = res.data.max;
          this.min = this.max - res.data.resultado.length;
          this.productos = res.data.resultado;
          for (let i = 1; i <= res.data.paginas; i++) {
            this.paginas.push(i);
          }
          document
            .getElementById("num" + this.actual)
            .setAttribute(
              "style",
              "background-color:gainsboro;border-radius:50%;"
            );
          document.getElementById("next").hidden = false;
          document.getElementById("prev").hidden = false;
          if (this.min === 0) {
            document.getElementById("prev").hidden = true;
          }
          if (res.data.tope === true) {
            document.getElementById("next").hidden = true;
          }
          let a = item;
          this.listado = [];
          for (let i = 0; i < 4; i++) {
            if (a <= res.data.pag) {
              this.listado.push(a);
            }
            a++;
          }
          this.$nextTick(() => {
            this.actual = item;
            this.selpag = item;
            if (
              this.listado.length > 0 &&
              document.getElementById("num" + this.actual)
            ) {
              document
                .getElementById("num" + this.actual)
                .setAttribute(
                  "style",
                  "background-color:#adadad;border-radius:50%;"
                );
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    prev() {
      let info = {
        estatus: this.seleccionado,
        max: this.min,
        ult: false,
        num: null
      };
      let dat = this.actual - 1;
      if (dat < this.listado[0]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "productosCom/prev?search=" +
          this.search5 +
          "&img=" +
          this.imgsel,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max + 20;
            this.min = res.data.max;
            this.productos = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("next").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("prev").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[0] - 5;
            this.listado = [];
            if (a < 0) {
              a = 0;
              document.getElementById("prev").hidden = true;
            }
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual - 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual-1))) {
                  document.getElementById("num"+(this.actual-1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-2))) {
                  document.getElementById("num"+(this.actual-2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-3))) {
                  document.getElementById("num"+(this.actual-3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual - 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    next() {
      let info = {
        estatus: this.seleccionado,
        max: this.max,
        ult: false,
        num: null
      };
      let dat = this.actual + 1;
      if (dat > this.listado[this.listado.length - 1]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "productosCom/next?search=" +
          this.search5 +
          "&img=" +
          this.imgsel,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max;
            this.min = this.max - res.data.resultado.length;
            this.productos = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("prev").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("next").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[this.listado.length - 1];
            this.listado = [];
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual + 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual+3))) {
                  document.getElementById("num"+(this.actual+3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+2))) {
                  document.getElementById("num"+(this.actual+2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+1))) {
                  document.getElementById("num"+(this.actual+1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual + 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    actualizartasa() {
      let formt = {
        tasa: this.tasa
      };
      Api.post("productos/tasa", formt).then(res => {
        console.log(res);
      });
    },
    force() {
      this.mostrar = false;
      this.$nextTick().then(() => {
        this.mostrar = true;
      });
    },
    modific() {
      this.myCroppa.generateBlob(
        blob => {
          const data = new FormData();
          data.append("imagen", blob, this.phot);
          data.append("id", this.idimg);
          this.dialog4 = true
          Api.put("productos/recortar", data)
            .then(response => {
              this.dialog4 = false
              this.$swal
                .fire({
                  title: "Felicidades.!",
                  text: "Su modificación se ha realizado exitosamente.",
                  type: "success"
                })
                .then(result => {
                  if (result.value) {
                    this.close3();
                    this.photos = [];
                    for (let i = 0; i < response.data.imagen.length; i++) {
                      this.photos.push(response.data.imagen[i].url);
                    }
                    this.force();
                  }
                });
            })
            .catch(error => {
              this.dialog4 = false
              console.log(error);
              this.$swal.fire({
                title: "Error inesperado.!",
                text:
                  "Ha ocurrido un error, por favor verifique la información.",
                icon: "error"
              });
            });
        },
        "image/jpeg",
        0.8
      );
    },
    open(item) {
      this.idimg = item.co_art;
      this.dialog2 = true;
      this.photos = [];
      for (let i = 0; i < item.imagen.length; i++) {
        this.photos.push(item.imagen[i].url);
      }
    },
    close2() {
      this.dialog2 = false;
      this.photos = [];
      this.idimg = "";
    },
    close3() {
      this.myCroppa.remove();
      this.dialog3 = false;
      this.phot = "";
    },
    imagenselect2(i, item) {
      this.index = i;
      this.phot = item;
      this.dialog3 = true;
    },
    indice(indice, name) {
      this.form.imagen.splice(indice, 1);
      for (let i = 0; i < this.files.length; i++) {
        if (name === this.files[i].name) {
          this.files.splice(i, 1);
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      // this.files = [];
      // this.form.imagen = [];
      Array.prototype.push.apply(this.files, e.target.files);
      const files = e.target.files;
      if (files !== undefined) {
        for (let i = 0; i < files.length; i++) {
          const fr = new FileReader();
          fr.readAsDataURL(files[i]);
          fr.addEventListener("load", () => {
            this.form.imagen.push({
              imageName: this.files[i].name,
              imageUrl: fr.result
            });
          });
        }
      } else {
        this.form.imagen = [];
      }
    },
    busqinit(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      Api.get(
        "productosCom/sinseleccion?search=" +
          item +
          "&img=" +
          this.imgsel
      )
        .then(res => {
          this.productos = [];
          this.listado = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    cargarproductos() {
      this.imgsel = false;
      Api.get("productosCom/sinseleccion?img=" + this.imgsel)
        .then(res => {
          this.productos = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
            });
            this.selpag = 1;
            this.actual = 1;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    initialize() {
      this.imgsel = false;
      this.editedIndex = -1;
      this.form.imagen = [];
      this.dialog4 = true;
      Api.get('grupo').then(res => {
        this.gruposAll = res.data
        this.grupos = []
        for (let i = 0; i < res.data.length; i++) {
          const el = res.data[i];
          this.grupos.push({
            text: el.descripcion,
            value: el._id
          })
        }
      })
      Api.get('productos/catalogo').then(res => {
        this.catalogo = res.data
      })
      Api.get("productosCom/sinseleccion?img=" + this.imgsel)
        .then(res => {
          this.productos = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.productos.push(res.data.resultado[i]);
          }
          this.tot = res.data.cantidad;
          this.sinimg = res.data.cantimg;
          this.max = this.productos.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
            });
            this.selpag = 1;
            this.actual = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    abrir() {
      this.guard = false;
      this.dialog = true;
      this.search2 = "";
      this.search3 = "";
    },
    close() {
      this.search2 = "";
      this.search3 = "";
      this.files = [];
      this.files2 = [];
      this.form.imagen = [];
      this.form.presentacion1 = {
        id: null,
        valor: ""
      }
      this.form.presentacion2 = {
        id: null,
        valor: ""
      }
      this.$refs.image.type = "text";
      this.$refs.image.type = "file";
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.editedIndex = -1;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("productos/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    },
    editItem(item) {
      this.editedIndex = this.productos.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.imagen && item.imagen.length > 0) {
        this.files2 = item.imagen;
      }
      console.log(this.form)
      this.form._id = item._id;
      this.dialog = true;
    },
    save() {
      this.loading = true;
      this.guard = true;
      if (this.files.length === 0) {
        this.form.imagen = this.files2;
      }
      const data = new FormData();
      Object.keys(this.form).map(key => {
        if (Array.isArray(this.form[key])) {
          this.form[key].forEach(val => {
            if (typeof val === "object" && val !== null) {
              return data.append(`${key}[]`, JSON.stringify(val));
            }
            return data.append(`${key}[]`, val);
          });
        } else if (
          typeof this.form[key] === "object" &&
          this.form[key] !== null
        ) {
          return data.append(key, JSON.stringify(this.form[key]));
        } else {
          return data.append(key, this.form[key]);
        }
      });
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        data.append("imagen", file);
      }
      Api.put("productosCom/imagen", data)
        .then(res => {
          this.form = Object.assign({}, this.defaultForm);
          this.dialog = false;
          this.$swal.fire(
            "Felicidades.!",
            "Modificación realizada exitosamente.",
            "success"
          );
          this.loading = false;
          this.close();
          for (let i = 0; i < this.productos.length; i++) {
            if (this.productos[i].co_art.trim() === res.data.co_art.trim() ) {
              this.productos[i].imagen = res.data.imagen
              this.productos[i].descripcion = res.data.descripcion
              this.productos[i].presentacion1 = res.data.presentacion1
              this.productos[i].presentacion2 = res.data.presentacion2
              // this.$set(this.productos, i, res.data);
            }
          }
          this.guard = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
          this.guard = false;
        });
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/productos.scss";
</style>
