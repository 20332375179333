// const server = "http://localhost";
// const domain = "localhost";
// const port = 9122;
const serverImg = 'https://cdn.fercoven.com';
const server = "https://api.fercoven.com";
const port = 443;
const domain = "admin.fercoven.com";
// const server = "https://apitest.fercoven.com";
// const port = 443;
// const domain = "admintest.fercoven.com";
export { server, port, domain, serverImg };
