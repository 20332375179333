<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Settings
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Acerca de nosotros
    v-flex(xs12 style='margin-top:20px;')
        v-layout(align-center='' justify-center='' row='' wrap='')
            v-flex(xs12 sm8 md7 style='margin-top: 5px;')

              v-textarea(outline
                v-model="form.mision"
                prepend-inner-icon="description"
                label="Misión:")

              v-textarea(outline
                v-model="form.vision"
                prepend-inner-icon="description"
                label="Visión:")

              v-textarea(outline
                v-model="form.valores"
                prepend-inner-icon="description"
                label="Valores:")

              div(style='display: flex;justify-content: center;margin: 10px;')
                img(:src='ruta + img' v-if='img && !form.imagen.imageUrl' style='height:200px;width:auto;object-fit:contain;')
                img(:src='form.imagen.imageUrl' v-if='form.imagen.imageUrl' style='height:200px;width:auto;object-fit:contain;')
              v-text-field(outline label='Seleccione la imagen', @click='pickFile', v-model='form.imagen.imageName', prepend-inner-icon='attach_file')
              input(
                type="file"
                style="display: none;"
                ref="image"
                accept="image/*"
                @change="onFilePicked")

            v-flex(v-if="loading === true", xs12 sm8 md7)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    v-progress-circular(indeterminate='', color='primary')
            v-flex(v-if="loading === false", xs12 sm8 md7)
                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    v-btn(style="background:#ff6500;border-radius:10px;", @click.native='save') Guardar
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Acerca",
  data: () => ({
    ruta: serverImg,
    loading: false,
    dialog4: false,
    acerca: {},
    form: {
      mision: "",
      vision: "",
      valores: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    defaultForm: {
      mision: "",
      vision: "",
      valores: "",
      imagen: {
        imageName: "",
        imageUrl: "",
        imageFile: ""
      }
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Acerca de nosotros") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        let peso = false;
        for (let j = 0; j < files.length; j++) {
          if (files[j].size > 4194304) {
            peso = true;
          }
        }
        if (peso === false) {
          this.form.imagen.imageName = files[0].name;
          if (this.form.imagen.imageName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.form.imagen.imageUrl = fr.result;
            this.form.imagen.imageFile = files[0]; // this is an image file that can be sent to server...
          });
        } else {
          this.$swal.fire(
            "Oops...",
            "Error encontrado, la imagen debe pesar menos de 4MB.",
            "error"
          );
          this.files = [];
          this.form.images = [];
        }
      } else {
        this.form.imagen.imageName = "";
        this.form.imagen.imageFile = "";
        this.form.imagen.imageUrl = "";
      }
    },
    initialize() {
      this.img = "";
      this.form.imagen.imageName = "";
      this.form.imagen.imageFile = "";
      this.form.imagen.imageUrl = "";
      Api.get("quienes").then(res => {
        if (res.data && res.data.length > 0) {
          this.acerca = res.data[0]
          this.form.mision = this.acerca.mision
          this.form.vision = this.acerca.vision
          this.form.valores = this.acerca.valores
          if (this.acerca.imagen) {
            this.img = this.acerca.imagen;
          }
        }
      });
    },
    save() {
      this.loading = true
      if (this.acerca && this.acerca._id) {
        this.form._id = this.acerca._id
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile) {
          data.append("imagen", this.form.imagen.imageFile);
        }
        Api.put("quienes", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        if (!this.form.imagen.imageFile) {
          this.form.imagen = this.img;
        }
        const data = new FormData();
        Object.keys(this.form).map(key => {
          if (Array.isArray(this.form[key])) {
            this.form[key].forEach(val => {
              if (typeof val === "object" && val !== null) {
                return data.append(`${key}[]`, JSON.stringify(val));
              }
              return data.append(`${key}[]`, val);
            });
          } else if (
            typeof this.form[key] === "object" &&
            this.form[key] !== null
          ) {
            return data.append(key, JSON.stringify(this.form[key]));
          } else {
            return data.append(key, this.form[key]);
          }
        });
        if (this.form.imagen.imageFile) {
          data.append("imagen", this.form.imagen.imageFile);
        }
        Api.post("quienes", data)
          .then(res => {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      }
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
.cll {
  .v-messages.theme--light {
    display: none !important;
  }
}
</style>
