<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Iconos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de solicitudes de codigo
    v-dialog(v-model='dialog', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Solicitud
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md8)
                          v-form(enctype="multipart/form-data")

                            v-text-field(outline
                            readonly
                            v-model="form.razon"
                            prepend-inner-icon="description"
                            label="Razon social:")

                            v-text-field(outline
                            readonly
                            v-model="form.rif"
                            prepend-inner-icon="description"
                            label="Rif:")

                            v-text-field(outline
                            readonly
                            v-model="form.tipoContrib"
                            prepend-inner-icon="description"
                            label="Tipo de contribuyente:")

                            v-text-field(outline
                            readonly
                            v-model="form.ciudad"
                            prepend-inner-icon="description"
                            label="Ciudad:")

                            v-text-field(outline
                            readonly
                            v-model="form.estado"
                            prepend-inner-icon="description"
                            label="Estado:")

                            v-text-field(outline
                            readonly
                            v-model="form.direccionFiscal"
                            prepend-inner-icon="description"
                            label="Dirección fiscal:")

                            v-text-field(outline
                            readonly
                            v-model="form.direccionEntrega"
                            prepend-inner-icon="description"
                            label="Dirección de entrega:")

                            v-text-field(outline
                            readonly
                            v-model="form.telefonoLocal"
                            prepend-inner-icon="description"
                            label="Teléfono local:")

                            v-text-field(outline
                            readonly
                            v-model="form.telefonoMovil"
                            prepend-inner-icon="description"
                            label="Teléfono móvil:")

                            v-text-field(outline
                            readonly
                            v-model="form.email"
                            prepend-inner-icon="description"
                            label="E-mail:")

                            v-text-field(outline
                            readonly
                            v-model="form.instagram"
                            prepend-inner-icon="description"
                            label="Instagram:")

                            v-text-field(outline
                            readonly
                            v-model="form.facebook"
                            prepend-inner-icon="description"
                            label="Facebook:")

                            v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span REPRESENTANTES DE LA COMPAÑIA

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[0]'
                            v-model="form.representCompania[0].representante"
                            prepend-inner-icon="description"
                            label="Representante:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[0]'
                            v-model="form.representCompania[0].cedula"
                            prepend-inner-icon="description"
                            label="Cédula:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[0]'
                            v-model="form.representCompania[0].cargo"
                            prepend-inner-icon="description"
                            label="Cargo:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[0]'
                            v-model="form.representCompania[0].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[1]'
                            v-model="form.representCompania[1].representante"
                            prepend-inner-icon="description"
                            label="Representante:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[1]'
                            v-model="form.representCompania[1].cedula"
                            prepend-inner-icon="description"
                            label="Cédula:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[1]'
                            v-model="form.representCompania[1].cargo"
                            prepend-inner-icon="description"
                            label="Cargo:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[1]'
                            v-model="form.representCompania[1].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[2]'
                            v-model="form.representCompania[2].representante"
                            prepend-inner-icon="description"
                            label="Representante:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[2]'
                            v-model="form.representCompania[2].cedula"
                            prepend-inner-icon="description"
                            label="Cédula:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[2]'
                            v-model="form.representCompania[2].cargo"
                            prepend-inner-icon="description"
                            label="Cargo:")

                            v-text-field(outline
                            readonly
                            v-if='form.representCompania && form.representCompania[2]'
                            v-model="form.representCompania[2].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-model="form.representCompra"
                            prepend-inner-icon="description"
                            label="Representante compra:")

                            v-text-field(outline
                            readonly
                            v-model="form.representCtaPagar"
                            prepend-inner-icon="description"
                            label="Representante cta. pagar:")

                            v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span REFERENCIAS BANCARIAS

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[0]'
                            v-model="form.referenciasBanc[0].banco"
                            prepend-inner-icon="description"
                            label="Banco:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[0]'
                            v-model="form.referenciasBanc[0].nro"
                            prepend-inner-icon="description"
                            label="N° de cuenta:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[0]'
                            v-model="form.referenciasBanc[0].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")


                            a(v-if='form.referenciasBanc && form.referenciasBanc[0] && form.referenciasBanc[0].referencia' :href='ruta + form.referenciasBanc[0].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasBanc[0].referencia)'
                                :src='ruta + form.referenciasBanc[0].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasBanc[0].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[1]'
                            v-model="form.referenciasBanc[1].banco"
                            prepend-inner-icon="description"
                            label="Banco:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[1]'
                            v-model="form.referenciasBanc[1].nro"
                            prepend-inner-icon="description"
                            label="N° de cuenta:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[1]'
                            v-model="form.referenciasBanc[1].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")


                            a(v-if='form.referenciasBanc && form.referenciasBanc[1] && form.referenciasBanc[1].referencia' :href='ruta + form.referenciasBanc[1].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasBanc[1].referencia)'
                                :src='ruta + form.referenciasBanc[1].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasBanc[1].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[2]'
                            v-model="form.referenciasBanc[2].banco"
                            prepend-inner-icon="description"
                            label="Banco:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[2]'
                            v-model="form.referenciasBanc[2].nro"
                            prepend-inner-icon="description"
                            label="N° de cuenta:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasBanc && form.referenciasBanc[2]'
                            v-model="form.referenciasBanc[2].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")

                            a(v-if='form.referenciasBanc && form.referenciasBanc[2] && form.referenciasBanc[2].referencia' :href='ruta + form.referenciasBanc[2].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasBanc[2].referencia)'
                                :src='ruta + form.referenciasBanc[2].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasBanc[2].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span REFERENCIAS COMERCIALES

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[0]'
                            v-model="form.referenciasComer[0].empresa"
                            prepend-inner-icon="description"
                            label="Empresa:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[0]'
                            v-model="form.referenciasComer[0].direccion"
                            prepend-inner-icon="description"
                            label="Dirección:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[0]'
                            v-model="form.referenciasComer[0].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[0]'
                            v-model="form.referenciasComer[0].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")

                            a(v-if='form.referenciasComer && form.referenciasComer[0] && form.referenciasComer[0].referencia' :href='ruta + form.referenciasComer[0].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasComer[0].referencia)'
                                :src='ruta + form.referenciasComer[0].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasComer[0].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[1]'
                            v-model="form.referenciasComer[1].empresa"
                            prepend-inner-icon="description"
                            label="Empresa:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[1]'
                            v-model="form.referenciasComer[1].direccion"
                            prepend-inner-icon="description"
                            label="Dirección:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[1]'
                            v-model="form.referenciasComer[1].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[1]'
                            v-model="form.referenciasComer[1].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")

                            a(v-if='form.referenciasComer && form.referenciasComer[1] && form.referenciasComer[1].referencia' :href='ruta + form.referenciasComer[1].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasComer[1].referencia)'
                                :src='ruta + form.referenciasComer[1].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasComer[1].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )


                            div(style='background:grey;height: 2px;width:100%;margin-top:10px;margin-bottom:35px;')

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[2]'
                            v-model="form.referenciasComer[2].empresa"
                            prepend-inner-icon="description"
                            label="Empresa:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[2]'
                            v-model="form.referenciasComer[2].direccion"
                            prepend-inner-icon="description"
                            label="Dirección:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[2]'
                            v-model="form.referenciasComer[2].telefono"
                            prepend-inner-icon="description"
                            label="Teléfono:")

                            v-text-field(outline
                            readonly
                            v-if='form.referenciasComer && form.referenciasComer[2]'
                            v-model="form.referenciasComer[2].fecha"
                            prepend-inner-icon="description"
                            label="Fecha de la carta:")

                            a(v-if='form.referenciasComer && form.referenciasComer[2] && form.referenciasComer[2].referencia' :href='ruta + form.referenciasComer[2].referencia' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.referenciasComer[2].referencia)'
                                :src='ruta + form.referenciasComer[2].referencia'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.referenciasComer[2].referencia)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span USO INTERNO

                            v-text-field(outline
                            readonly
                            v-model="form.codigoFercove"
                            prepend-inner-icon="description"
                            label="Código Fercoven:")

                            v-text-field(outline
                            readonly
                            v-model="form.asesorVenta"
                            prepend-inner-icon="description"
                            label="Asesor de venta:")

                            v-card(v-if='form.imgRegistroM' style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span REGISTRO MERCANTIL

                            a(v-if='form.imgRegistroM' :href='ruta + form.imgRegistroM' target='_blank' style='display:flex;justify-content:center;')
                              img(
                                v-if='!verificarPdf(form.imgRegistroM)'
                                :src='ruta + form.imgRegistroM'
                                style='width: 100%;height:auto;object-fit: contain;'
                              )
                              img(
                                v-if='verificarPdf(form.imgRegistroM)'
                                src='../assets/pdf.svg'
                                style='width: 100px;height:auto;object-fit: contain;'
                              )

                            v-card(v-if='form.imgRifEmp' style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span RIF DE LA EMPRESA

                            a(v-if='form.imgRifEmp' :href='ruta + form.imgRifEmp' target='_blank' style='display:flex;justify-content:center;')
                                img(
                                  v-if='!verificarPdf(form.imgRifEmp)'
                                  :src='ruta + form.imgRifEmp'
                                  style='width: 100%;height:auto;object-fit: contain;'
                                )
                                img(
                                  v-if='verificarPdf(form.imgRifEmp)'
                                  src='../assets/pdf.svg'
                                  style='width: 100px;height:auto;object-fit: contain;'
                                )

                            v-card(v-if='form.imgRifRepreL' style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span RIF DEL REPRESENTANTE LEGAL

                            a(v-if='form.imgRifRepreL' :href='ruta + form.imgRifRepreL' target='_blank' style='display:flex;justify-content:center;')
                                img(
                                  v-if='!verificarPdf(form.imgRifRepreL)'
                                  :src='ruta + form.imgRifRepreL'
                                  style='width: 100%;height:auto;object-fit: contain;'
                                )
                                img(
                                  v-if='verificarPdf(form.imgRifRepreL)'
                                  src='../assets/pdf.svg'
                                  style='width: 100px;height:auto;object-fit: contain;'
                                )

                            v-card(v-if='form.imgCIRepreL' style="margin-right: 0px;margin-top: 10px;margin-bottom: 35px;")
                              v-card-title.justify-center
                                span C.I. DEL REPRESENTANTE LEGAL

                            a(v-if='form.imgCIRepreL' :href='ruta + form.imgCIRepreL' target='_blank' style='display:flex;justify-content:center;')
                                img(
                                  v-if='!verificarPdf(form.imgCIRepreL)'
                                  :src='ruta + form.imgCIRepreL'
                                  style='width: 100%;height:auto;object-fit: contain;'
                                )
                                img(
                                  v-if='verificarPdf(form.imgCIRepreL)'
                                  src='../assets/pdf.svg'
                                  style='width: 100px;height:auto;object-fit: contain;'
                                )
                            div
                              v-flex(xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(v-if='form.estatus === "Pendiente"' style="background:green;border-radius:10px;", @click.native='save("Re")') Registrar
                                    v-btn(v-if='form.estatus === "Pendiente"' style="background:red;border-radius:10px;", @click.native='save("R")') Rechazar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-flex(xs12 style='margin-top: 5px;')
          v-layout(wrap)
            v-flex(xs6 style='padding-right: 80px;')
              v-text-field(v-model='search', label='Buscar')
            v-flex(xs6 style='padding-left: 80px;display: flex;align-items: center;')
              v-select(v-model='seleccionEst' :items='estat' label='Estatus')
              //- v-menu(ref='menu1' v-model='modal' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
              //-   template(v-slot:activator='{ on, attrs }')
              //-     v-text-field(v-model='dateFormatted' label='Fecha' style='padding-left: 15px;' persistent-hint='' v-bind='attrs' v-on='on')
              //-   v-date-picker(v-model='date' no-title='' dark='')
              //-     v-spacer
              //-     v-btn(text='' color='primary' @click='$refs.menu1.save(date)')
              //-       | OK
              v-btn.mx-0(icon, @click='buscar()', style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
                v-icon(color='#ff6500' style='font-size: 1.70rem;') search
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de solicitudes
        v-data-table.elevation-1(:headers='headers', :items="resultados" :search='search' style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.fecha') {{ props.item.fecha | moment('DD/MM/YYYY') }}
            td(v-if='props.item.vendedor && props.item.vendedor.length > 0') {{ props.item.vendedor[0].ven_des }}
            td(v-else) Supervisor
            td(v-if='props.item.razon') {{ props.item.razon }}
            td(v-else) - -
            td(v-if='props.item.rif') {{ props.item.rif }}
            td(v-else) - -
            td(v-if='props.item.ciudad') {{ props.item.ciudad }}
            td(v-else) - -
            td(v-if='props.item.estado') {{ props.item.estado }}
            td(v-else) - -
            td(v-if='props.item.telefonoLocal') {{ props.item.telefonoLocal }}
            td(v-else) - -
            td(v-if='props.item.telefonoMovil') {{ props.item.telefonoMovil }}
            td(v-else) - -
            td(v-if='props.item.estatus') {{ props.item.estatus }}
            td(v-else) - -
            td.justify-start.layout.px-0
                v-btn.mx-0(v-if='mod' icon, @click='editItem(props.item)', style='margin-top: -8px;')
                    v-icon(color='teal') visibility
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Solicitudes",
  data: (vm) => ({
    ruta: serverImg,
    search: "",
    modal: false,
    dialog4: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    form: {
        co_ven: "",
        usuario: "",
        razon: "",
        rif: "",
        tipoContrib: "",
        ciudad: "",
        estado: "",
        direccionFiscal: "",
        direccionEntrega: "",
        telefonoLocal: "",
        telefonoMovil: "",
        email: "",
        instagram: "",
        facebook: "",
        representCompania: [],
        representCompra: "",
        representCtaPagar: "",
        referenciasBanc: [],
        referenciasComer: [],
        codigoFercove: "",
        asesorVenta: "",
        imgRegistroM: "",
        imgRifEmp: "",
        imgRifRepreL: "",
        imgCIRepreL: "",
        estatus: "",
        fecha: ""
    },
    dialog: false,
    resultados: [],
    estat: ["Pendiente", "Rechazado", "Registrado"],
    seleccionEst: "Pendiente",
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Emisor", value: "vendedor" },
      { text: "Razon Social", value: "razon" },
      { text: "Rif", value: "rif" },
      { text: "Ciudad", value: "ciudad" },
      { text: "Estado", value: "estado" },
      { text: "Teléfono local", value: "telefonoLocal" },
      { text: "Teléfono móvil", value: "telefonoMovil" },
      { text: "Estatus", value: "estatus" },
      { text: "Acción" }
    ],
    defaultForm: {
        co_ven: "",
        usuario: "",
        razon: "",
        rif: "",
        tipoContrib: "",
        ciudad: "",
        estado: "",
        direccionFiscal: "",
        direccionEntrega: "",
        telefonoLocal: "",
        telefonoMovil: "",
        email: "",
        instagram: "",
        facebook: "",
        representCompania: [],
        representCompra: "",
        representCtaPagar: "",
        referenciasBanc: [],
        referenciasComer: [],
        codigoFercove: "",
        asesorVenta: "",
        imgRegistroM: "",
        imgRifEmp: "",
        imgRifRepreL: "",
        imgCIRepreL: "",
        estatus: "",
        fecha: ""
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
    this.verificacion();
    this.initialize();
  },
  methods: {
    verificarPdf(item) {
      if (item.includes(".pdf")) {
        return true
      } else return false
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Solicitudes") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    initialize() {
      Api.get("solicitudes/estatus?estatus=" +this.seleccionEst).then(res => {
        this.resultados = res.data;
      });
    },
    buscar() {
      this.dialog4 = true
      Api.get("solicitudes/estatus?estatus=" +this.seleccionEst).then(res => {
        this.resultados = res.data;
        this.dialog4 = false
      }).catch(err=>{
        this.dialog4 = false
        console.log(err)
      })
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.initialize();
    },
    editItem(item) {
      this.editedIndex = this.resultados.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    save(item) {
      let info = {
        _id: this.form._id,
        estatus: (item === "Re") ? "Registrado" : "Rechazado"
      }
      this.dialog4 = true
      Api.put("solicitudes", info)
        .then(() => {
          this.form = Object.assign({}, this.defaultForm);
          this.dialog = false;
          this.$swal.fire(
            "Felicidades.!",
            "Modificación realizada exitosamente.",
            "success"
          );
          this.dialog4 = false
          this.initialize();
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
      });
    }
  }
};
</script>
<style lang="scss">
@media only screen and (max-width: 600px) {
  .wdd {
    width: 200px !important;
  }
}
.cll {
  .v-messages.theme--light {
    display: none !important;
  }
}
</style>
