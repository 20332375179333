<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Contacto
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Contacto
    v-flex(xs12)
      template
        v-tabs(v-model='active', color="#ff6500", centered, dark)
          v-tab(:key='0', ripple='') Información
          v-tab(:key='1', ripple='') Mensajes
          v-tab-item(:key='0')
            v-card(flat='')
              v-flex(xs12)
                v-container(style='background-color:white !important;')
                    div(style="margin-top: 20px;")
                        v-flex(xs12 sm12)
                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                v-flex(xs6)
                                    v-form(enctype="multipart/form-data")

                                        v-combobox(prepend-inner-icon="description" outline v-model='form_contacto.telefonos' :items='items2' :hide-selected='true' label='Teléfonos' :multiple='true' persistent-hint='' :small-chips='true' :clearable='true')
                                          template(v-if='noData' v-slot:no-data='')
                                            v-list-tile
                                              v-list-tile-content
                                                v-list-tile-title
                                                  span(style='padding-left: 5px;') Escriba el teléfono y presione 
                                                  kbd enter
                                                  span(style='padding-left: 5px;') para agregar uno nuevo.

                                        v-text-field(outline
                                        v-model="form_contacto.direccion"
                                        prepend-inner-icon="description"
                                        label="Dirección:")
                                        
                                        v-combobox(prepend-inner-icon="description" outline v-model='form_contacto.correo' :items='items3' :hide-selected='true' label='Correo electrónico' :multiple='true' persistent-hint='' :small-chips='true' :clearable='true')
                                          template(v-if='noData2' v-slot:no-data='')
                                            v-list-tile
                                              v-list-tile-content
                                                v-list-tile-title
                                                  span(style='padding-left: 5px;') Escriba el correo y presione 
                                                  kbd enter
                                                  span(style='padding-left: 5px;') para agregar uno nuevo.

                                        GmapMap(id='mapa' :center='centrarmapa', :zoom='zooom', style='width: 100%; height: 500px' @click='eventomap($event.latLng)')
                                          GmapMarker(:position='marker[0]', :clickable='false', :draggable='false')

                                    div
                                      v-flex(v-if="loading === true", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-progress-circular(indeterminate='', color='primary')
                                      v-flex(v-if="loading === false", xs12 sm12 md12)
                                        v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                          v-btn(style="background:black;border-radius:10px;", @click.native='save') Guardar
                                           
          v-tab-item(:key='1')
            v-card(flat='')
              v-flex(xs12)
                v-dialog(v-model='dialog', persistent max-width='700px')
                      v-container(style='background-color:white !important;max-width: 700px;')
                        v-card-title.justify-center
                            span.headline Mensaje
                        div(style="margin-top: 20px;")
                            v-flex(xs12 sm12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-flex(xs8)
                                      v-form(enctype="multipart/form-data")
                                        
                                        v-text-field(outline
                                        v-model="form.nombre"
                                        readonly
                                        prepend-inner-icon="description"
                                        label="Cliente:")
                                        
                                        v-text-field(outline
                                        v-model="form.correo"
                                        readonly
                                        prepend-inner-icon="description"
                                        label="Correo:")

                                        v-text-field(outline
                                        v-model="form.telefono"
                                        readonly
                                        prepend-inner-icon="description"
                                        label="Teléfono:")

                                        v-textarea(outline
                                        v-model="form.mensaje"
                                        readonly
                                        prepend-inner-icon="description"
                                        label="Mensaje:")

                                        div
                                          v-flex(v-if="loading2 === true", xs12 sm12 md12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                              v-progress-circular(indeterminate='', color='primary')
                                          v-flex(v-if="loading2 === false", xs12 sm12 md12)
                                            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                              //- v-btn(style="background:#ff5700;border-radius:10px;", @click.native='save') Guardar
                                              v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir

                div(style="margin-top:20px")
                  v-flex(xs12)
                    v-layout(wrap)
                      v-flex(xs5)
                        v-text-field(v-model='search', append-icon='search', label='Buscar' style='width:100%;')
                v-layout(align-start='', justify-start='', row='', fill-height='')
                  v-flex(xs12 style='margin-top: 5px;')
                    v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
                        v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                            span.white--text Lista de Mensajes
                    v-data-table.elevation-1(:headers='headers', :items="mensajes", :search='search2', style="margin-top: 5px;")
                      template(slot='items', slot-scope='props')
                        td(v-if='props.item.nombre') {{ props.item.nombre }}
                        td(v-else) - -
                        td(v-if='props.item.correo') {{ props.item.correo }}
                        td(v-else) - -
                        td(v-if='props.item.telefono') {{ props.item.telefono }}
                        td(v-else) - -
                        td(v-if='props.item.estatus') {{ props.item.estatus }}
                        td(v-else) - -
                        td.justify-start.layout.px-0
                          v-btn.mx-0(icon, @click='editItem(props.item)', style='margin-top: -8px;')
                              v-icon(color='teal') visibility

</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "Contacto",
  data: () => ({
    centrarmapa: { lat: 10.0738900, lng: -69.3227800 },
    zooom: 10,
    marker: [],
    active: null,
    ruta: serverImg,
    search2: "",
    search: "",
    loading: false,
    loading2: false,
    dialog: false,
    noData: true,
    noData3: true,
    noData2: true,
    items2: [],
    items3: [],
    items4: [],
    headers: [
      { text: "Cliente", value: "nombre" },
      { text: "Correo", value: "correo" },
      { text: "Teléfono", value: "telefono" },
      { text: "Acciones" }
    ],
    mensajes: [],
    form_contacto: {
      telefonos: [],
      direccion: "",
      coordenadas: {},
      correo: []
    },
    defaultContacto: {
      telefonos: [],
      direccion: "",
      coordenadas: {},
      correo: []
    },
    form: {
      nombre: "",
      correo: "",
      telefono: "",
      mensaje: "",
      estatus: ""
    },
    editedIndex: -1,
    contacto: {},
    defaultForm: {
      nombre: "",
      correo: "",
      telefono: "",
      mensaje: "",
      estatus: ""
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    eventomap(item) {
      this.marker = [];
      this.marker.push({
        lat: item.lat(),
        lng: item.lng()
      });
      this.form_contacto.coordenadas = this.marker[0];
    },
    initialize() {
      this.editedIndex = -1;
      this.marker = [];
      Api.get("contacto").then(res => {
        this.contacto = res.data;
        if (this.contacto.length > 0) {
          this.form_contacto.telefonos = this.contacto[0].telefonos;
          this.form_contacto.direccion = this.contacto[0].direccion;
          if (
            this.contacto[0].coordenadas &&
            this.contacto[0].coordenadas.lat
          ) {
            this.form_contacto.coordenadas = {
              lat: this.contacto[0].coordenadas.lat,
              lng: this.contacto[0].coordenadas.lng
            };
            this.marker.push(this.contacto[0].coordenadas);
          }
          this.form_contacto.correo = this.contacto[0].correo;
        }
      });
      Api.get("mensaje").then(res => {
        this.mensajes = res.data;
      });
    },
    close() {
      this.form_contacto = Object.assign({}, this.defaultContacto);
      this.dialog = false;
    },
    editItem(item) {
      this.editedIndex = this.mensajes.indexOf(item);
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog = true;
    },
    save() {
      this.loading = true;
      if (this.contacto.length > 0) {
        this.form_contacto._id = this.contacto[0]._id;
        Api.put("contacto", this.form_contacto)
          .then(res => {
            this.form_contacto = Object.assign({}, this.defaultContacto);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.initialize();
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      } else {
        Api.post("contacto", this.form_contacto)
          .then(res => {
            this.form_contacto = Object.assign({}, this.defaultContacto);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Registro realizado exitosamente.",
              "success"
            );
            this.loading = false;
            this.initialize();
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
          });
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/scss/contacto.scss";
</style>
