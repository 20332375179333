import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    tokenfercoven: "",
    adminfercoven: []
  },
  mutations: {
    login(state, data) {
      state.adminfercoven = data.user;
      state.tokenfercoven = data.token;
    },
    logout(state) {
      state.sesion = [];
      state.adminfercoven = [];
      state.tokenfercoven = "";
    },
    adminfercoven(state, data) {
      state.adminfercoven = data;
    }
  },
  actions: {
    login({ commit }, data) {
      commit("login", data);
    },
    logout({ commit }) {
      commit("logout");
    },
    setuser({ commit }, data) {
      commit("adminfercoven", data);
    }
  }
});
