<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Iconos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Valijas
    v-dialog(v-model='dialog3', persistent max-width='85%')
      v-card
        v-card-text
          v-container(id="printJS-form")
            div(align='center')
              h5(style='font-weight: 400 !important;text-decoration: underline;') RELACIÓN DE COBRO EN EFECTIVO
            br
            div(style='display: flex;justify-content: space-between;align-items: center;')
              table(border="0" align="center")
                tr
                  td(align='end').withTd
                    span(style="font-weight: 500").paddText VENDEDOR:
                  td.borderTb
                    b {{ form.vendedor }}
                tr
                  td(align='end').withTd
                    span(style="font-weight: 500").paddText FECHA:
                  td.borderTb
                    b {{form.fecha | moment('DD/MM/YYYY H:mm A') }}
              table(border="0" align="center")
                tr
                  td(align='end').withTd
                    span(style="font-weight: 500").paddText APP N° VALIJA:
                  td.borderTb
                    b {{form.nro }}
              table(v-if="form.nroDeposito" border="0" align="center")
                tr
                  td(align='end').withTd
                    span(style="font-weight: 500").paddText N° DEPOSITO:
                  td.borderTb
                    b {{form.nroDeposito }}
            table(border="0" align="center" style='width: 100%;margin-top:20px;')
              tr
                td(align='center').withTd2
                  b N° COBRO
                td(align='center').withTd2
                  b CLIENTE
                td(align='center').withTd2
                  b R.I.F.
                td(align='center').withTd2
                  b N° FACTURAS
                td(align='center').withTd2
                  b MONTO
                td(align='center').withTd2
                  b OBSERVACIÓN
                td(align='center').withTd2
                  b CRUCE
              tr(v-for='item in form.cobros')
                td(align='center').withTd2
                  b {{item.nro}}
                td(align='center').withTd2
                  b {{item.cliente.cli_des.trim()}}
                td(align='center').withTd2
                  b {{item.cliente.rif}}
                td(align='center').withTd2
                  div(style="display:flex;flex-direction:column;")
                    b(v-for='item2 in item.facturas') {{item2.nro_doc}}
                td(align='center').withTd2
                  b(v-if='item.moneda == "BS"') ${{ transformMonto(item.monto, item.tasa) }}
                  b(v-else) ${{item.monto}}
                td(align='center' style="line-height: 16px;").withTd2
                  b(v-if='item.descripcion') {{item.descripcion}}
                td(align='center' style="line-height: 16px;").withTd2
                  
            table(border="0" align="center" style='width: 100%;')
              tr
                td(align='center' style="border-top: 0px;").withTd2
                  span(style="font-weight: 900;font-size:12px;").paddText TOTAL DE COBROS: ${{calcularTotalCobros(form.cobros)}}
            table(border="0" align="center" style='width: 100%;')
              tr
                td(align='left' style="border-top: 0px;").withTd2
                  span(style="font-weight: 900;font-size:12px;").paddText OBSERVACIÓN GENERAL: {{form.comentario}}
            table(border="0" align="center" style='width: 100%;')
              tr
                td(align='left' style="border-top: 0px;").withTd2
                  span(style="font-weight: 900;font-size:12px;").paddText NOTA: TODOS LOS PAGOS RELACIONADOS EN ESTA HOJA DEBEN ESTAR CARGADOS EN LA APP NO DEBE EXCEDER LOS ESPACIOS DESTINADOS EN CADA COLUMNA
            table(border="0" align="center" style='width: 100%;')
              tr
                td(align='left' style="border-top: 0px;").withTd2
                  span(style="font-weight: 900;font-size:12px;").paddText SE ENVIA UN TOTAL DE ${{form.montoD}}

        v-card-actions
          v-spacer
          v-btn(color='error' small dark @click='close3()')
            v-icon mdi-close
            | Cancelar
          v-btn(color='success' small dark @click="imprimirPdf()")
            v-icon mdi-content-save
            | PDF  
    v-dialog(v-model='dialog', persistent max-width='1000px')
          v-container(style='background-color:white !important;max-width: 1000px;')
            v-card-title.justify-center
                span.headline Valija
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md10)
                          v-form(enctype="multipart/form-data")

                            
                            v-text-field(outline
                            disabled
                            v-model="form.nro"
                            prepend-inner-icon="description"
                            label="N° valija:")
                            
                            v-text-field(outline
                            disabled
                            v-model="form.vendedor"
                            prepend-inner-icon="description"
                            label="Vendedor:")

                            v-text-field(outline
                              disabled
                              v-model="form.referencia"
                              prepend-inner-icon="description"
                              label="Referencia:")

                            v-textarea(outline
                              v-if='form.comentario'
                              disabled
                              v-model="form.comentario"
                              prepend-inner-icon="description"
                              label="comentario:")

                            div(style="margin-bottom:20px;").divProd
                              span COBROS
                            table(border="0" align="center" style='width: 100%;')
                              tr(style="font-weight: 500;")
                                td(align='center').withTd3
                                  span NRO
                                td(align='center').withTd3
                                  span FECHA
                                td(align='center').withTd3
                                  span FECHA PAGO
                                td(align='center').withTd3
                                  span CLIENTE
                                td(align='center').withTd3
                                  span METÓDO
                                td(align='center').withTd3
                                  span MONTO
                                td(align='center').withTd3
                                  span REFERENCIA
                                td(align='center').withTd3
                                  span ESTATUS
                              tr(v-for='item in form.cobros')
                                td(align='center').withTd2
                                  span {{item.nro}}
                                td(align='center').withTd2
                                  span {{transform2(item.fechaC)}}
                                td(align='center').withTd2
                                  span(v-if="item.fechaP") ${{transform(item.fechaP)}}
                                  span(v-else) N/A
                                td(align='center').withTd2
                                  span {{ item.cliente.cli_des }}
                                td(align='center').withTd2
                                  span {{ item.metodo }}
                                td(align='center').withTd2
                                  span(v-if='item.moneda == "BS"') ${{ transformMonto(item.monto, item.tasa) }} ({{item.monto}}BS)
                                  span(v-else) {{item.monto}}
                                td(align='center').withTd2
                                  span(v-if='item.referencia') {{ item.referencia }}
                                  span(v-else) N/A
                                td(align='center').withTd2
                                  span {{ item.estatus }}
                              tr(style="font-weight: 500;")
                                td(align='center').withTd3
                                td(align='center').withTd3
                                td(align='center').withTd3
                                td(align='center').withTd3
                                td(align='center').withTd3
                                td(align='center').withTd3
                                td(align='center').withTd3
                                  span TOTAL
                                td(align='center').withTd3
                                  span ${{calcularTotal(form)}}

                            v-text-field(outline
                              v-model="form.montoD"
                              disabled
                              prepend-inner-icon="description"
                              label="Monto depositado:")

                            v-text-field(outline
                              v-model="form.estatus"
                              disabled
                              prepend-inner-icon="description"
                              label="Estatus:")

                            v-text-field(outline
                              v-if='form.observacion'
                              v-model="form.observacion"
                              disabled
                              prepend-inner-icon="description"
                              label="Observación:")

                            v-select(outline
                              v-if='form.estatus == "Aprobado"'
                              :items='bancos'
                              v-model="form.banco"
                              label="Banco depositado"
                              prepend-inner-icon="description")

                            v-select(outline
                              v-if='form.estatus == "Aprobado"'
                              :items='cajas'
                              v-model="form.caja"
                              label="Caja a depositar"
                              prepend-inner-icon="description")

                            v-text-field(outline
                              v-if='form.estatus === "Finalizado"'
                              disabled
                              v-model="form.nroDeposito"
                              prepend-inner-icon="description"
                              label="Nro. de depositvo:")

                            div(style='display: flex;justify-content: center;margin: 10px;' v-if='form.bauche')
                              a(:href='ruta + form.bauche' target='_blank')
                                img(:src='ruta + form.bauche' style='width: 425px;height: auto;')

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(v-if='form.estatus === "Aprobado" && !form.iva' style="background:green;border-radius:10px;", @click.native='save("P")') Procesado
                                    v-btn(v-if='form.estatus === "Depositado"' style="background:green;border-radius:10px;", @click.native='save("A")') Aprobar
                                    v-btn(v-if='form.estatus === "Depositado"' style="background:red;border-radius:10px;", @click.native='save("R")') Rechazar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-flex(xs12 style='margin-top: 5px;')
          v-layout(wrap style='justify-content: space-between;')
            v-flex(xs4)
              v-text-field(v-model='search', label='Buscar')
            v-flex(xs4 style='display: flex;justify-content: center;padding-left:20px;')
              v-select(:items='filtros' v-model="seleccionFiltro" label="Estatus" @change='cambiarFiltro()')
            v-flex(xs4 style='display: flex;align-items: center;justify-content: flex-end;')
              v-menu(ref='menu1' v-model='modal' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(v-model='dateRangeText' label='Fecha' style='padding-left: 15px;' persistent-hint='' v-bind='attrs' v-on='on')
                v-date-picker(multiple v-model='dates' no-title='' dark='')
                  v-spacer
                  v-btn(text='' color='primary' @click='$refs.menu1.save(dates)')
                    | OK
              v-btn.mx-0(icon @click='buscarFecha' style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
                v-icon(color='#ff6500' style='font-size: 1.70rem;') search
              vue-excel-xlsx( v-if='resultados && resultados.length > 0' :data='dataExcel' :columns='columnsExcel' :filename="'Fercoven-Valija'" :sheetname="'sheetname'")
                div(style='display: flex;flex-direction: column;font-size: 12px;font-weight: bold;text-align: center;')
                  img(src='../assets/iconos/excel.png', style='height: 60px;width: auto;')
                  span Valija
              a(style='color: black;')
                download-excel(:fetch="buscarValija" :header="headerEx" :footer="footerEx" :fields="json_fields" name="Fercoven-Valija-Cobro.xls")
                  div(style='display: flex;flex-direction: column;font-size: 12px;font-weight: bold;text-align: center;')
                    img(src='../assets/iconos/excel.png', style='height: 60px;width: auto;')
                    span Valija / Cobro
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
          v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
            span.white--text Lista de Valijas
        v-data-table.elevation-1(ref='tablet1' :headers='headers' :pagination.sync="pagination" :search='search' :items="resultados" :custom-filter="filterPerfectMatch" style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td {{ props.item.nro}}
            td(v-if='props.item.nroDeposito') {{ props.item.nroDeposito}}
            td(v-else) N/A
            td {{ transform2(props.item.fecha)}}
            td(v-if='props.item.fechaP') {{ transform(props.item.fechaP)}}
            td(v-else) N/A
            td {{ props.item.vendedor }}
            td ${{props.item.monto}}
            td(v-if='props.item.montoD') ${{props.item.montoD}}
            td(v-else) N/A
            td(v-if='props.item.banco') {{ props.item.banco }}
            td(v-else) N/A
            td(v-if='props.item.comentario') {{ props.item.comentario}}
            td(v-else) N/A
            td(v-if='props.item.referencia') {{ props.item.referencia}}
            td(v-else) N/A
            td {{ props.item.estatus}}
            td.justify-start.layout.px-0
              v-tooltip(bottom v-if='mod')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem(props.item)' v-on="on" color='teal') edit
                | Editar.
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem2(props.item)' v-on="on" color='teal') description
                | Exportar pdf.
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
import printJS from 'print-js'
export default {
  name: "Valijas",
  data: vm => ({
    dialog3: false,
    headerEx: [
      "RELACIÓN DE COBRO EN EFECTIVO",
      "<div style='text-align:left;'><strong>VENDEDOR:</strong></div>",
      "<div style='text-align:left;'><strong>FECHA:</strong></div>",
      "<div style='text-align:left;'><strong>APP N° VALIJA:</strong></div>",
    ],
    footerEx: [
      "<div style='text-align:center;'><strong>SE ENVIA UN MONTO TOTAL DE: 2300$</strong></div>",
      "OBSERVACIÓN GENERAL:"
    ],
    json_fields: {
      "N°": "nro",
      "NOMBRE DEL CLIENTE": "nombre",
      "R.I.F CLIENTE": "rif",
      "N° DE FACTURAS A CANCELAR": "nroFact",
      "SI": "si",
      "NO": "no",
      "MONTO ENVIADO": "monto",
      "OBSERVACION": "observacion",
      "ADELANTO": "adelanto",
      "CRUCE": "cruce",
    },
    json_data: [],
    cajasres: [
      {
        text: "CAJA PRINCIPAL",
        value: "01"
      },
      {
        text: "CAJA DEPOSITOS BANCARIOS S.I.",
        value: "03"
      },
      {
        text: "CAJA DEPOSITOS Y TRANSFERENCIAS",
        value: "09"
      }
    ],
    bancos: [
      {
        text: "Deposito Cta. Custodia Banesco",
        value: "Deposito Cta. Custodia Banesco"
      },
      {
        text: "Deposito Cta. Custodia BNC",
        value: "Deposito Cta. Custodia BNC"
      },
      {
        text: "Deposito Cta. Custodia BANPLUS",
        value: "Deposito Cta. Custodia BANPLUS"
      },
      {
        text: "Deposito Cta. Custodia BANCARIBE",
        value: "Deposito Cta. Custodia BANCARIBE"
      },
      {
        text: "Deposito Cta. Custodia Mercantil",
        value: "Deposito Cta. Custodia Mercantil"
      },
      {
        text: "Deposito Cta. Custodia GT Bancaribe",
        value: "Deposito Cta. Custodia GT Bancaribe"
      },
      {
        text: "Oficina Central",
        value: "Oficina Central"
      },
      {
        text: "Oficina Caracas",
        value: "Oficina Caracas"
      },
    ],
    dataExcel: [],
    columnsExcel: [
      {
        label: "Nro.",
        field: "nro",
      },
      {
        label: "Nro. Deposito",
        field: "nroDeposito",
      },
      {
        label: "Fecha",
        field: "fecha",
      },
      {
        label: "Fecha de pago",
        field: "fechaP",
      },
      {
        label: "Vendedor",
        field: "vendedor",
      },
      {
        label: "Monto",
        field: "monto",
      },
      {
        label: "Monto Depositado",
        field: "montoD",
      },
      {
        label: "Banco",
        field: "banco",
      },
      {
        label: "Comentario",
        field: "comentario",
      },
      {
        label: "Referencia",
        field: "referencia",
      },
      {
        label: "Estatus",
        field: "estatus",
      }
    ],
    ruta: serverImg,
    dates: [],
    loading: false,
    dialog4: false,
    seleccionFiltro: 'todos',
    filtros: [
      {
        text: "Todos",
        value: "todos"
      },
      {
        text: "Depositados",
        value: "Depositado"
      },
      {
        text: "Pendientes",
        value: "Pendiente"
      },
      {
        text: "Aprobados",
        value: "Aprobado"
      },
      {
        text: "Rechazados",
        value: "Rechazado"
      },
      {
        text: "Procesados",
        value: "Procesado"
      },
      {
        text: "Finalizados",
        value: "Finalizado"
      },
    ],
    itemsF: [],
    pagination: {
      sortBy: 'fechaC', // The field that you're sorting by
      descending: true
    },
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    modal: false,
    search: "",
    form: {
      _id: "",
      cobros: [],
      vendedor: "",
      co_ven: "",
      co_zon: "",
      monto: 0,
      montoD: 0,
      observacion: "",
      bauche: "",
      comentario: "",
      estatus: "",
      referencia: "",
      banco: "",
      nroDeposito: "",
      caja: "",
      nro: "",
      fechaP: "",
      fechaA: "",
      fechaD: "",
      fecha: ""
    },
    dialog: false,
    resultadosres: [],
    resultados: [],
    headers: [
      { text: "Nro.", value: "nro" },
      { text: "Nro. Deposito", value: "nroDeposito" },
      { text: "Fecha", value: "fecha" },
      { text: "Fecha de pago", value: "fechaP" },
      { text: "Vendedor", value: "vendedor" },
      { text: "Monto", value: "monto" },
      { text: "Monto Depositado", value: "montoD" },
      { text: "Banco", value: "banco" },
      { text: "Comentario", value: "comentario" },
      { text: "Referencia", value: "referencia" },
      { text: "Estatus", value: "estatus" },
      { text: "Acción" }
    ],
    defaultForm: {
      _id: "",
      cobros: [],
      vendedor: "",
      co_ven: "",
      co_zon: "",
      comentario: "",
      bauche: "",
      monto: 0,
      montoD: 0,
      referencia: "",
      observacion: "",
      estatus: "",
      banco: "",
      nroDeposito: "",
      caja: "",
      nro: "",
      fechaP: "",
      fechaA: "",
      fechaD: "",
      fecha: ""
    },
    cre: "",
    mod: "",
    elm: "",
    img: "",
    cajas: [],
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    dateRangeText () {
      let todos = []
      let desde
      let hasta
      if (this.dates && this.dates[0])  {
        desde = this.formatDate(this.dates[0])
        todos.push(desde)
      }
      if (this.dates && this.dates[1]) {
        hasta = this.formatDate(this.dates[1])
        todos.push(hasta)
      }
      return todos.join(' ~ ')
    },
  },
  watch: {
    "form.banco": function(val) {
      if (!val.includes("Oficina")) {
        this.cajas = this.cajasres.slice()
      } else {
        this.form.caja = "";
        this.cajas = this.cajasres.filter(el=> !el.value.includes("09"))
      }
    },
    dates (val) {
      if (val && val.length > 2) {
        let res = []
        res.push(val[0])
        res.push(val[2])
        this.$nextTick(() => {
          this.dates = res
        })
      }
      if (val && val.length == 2 && val[0] > val[1]) {
        this.$swal.fire(
          "¡Atención!",
          "La fecha final debe ser mayor a la fecha inicial.",
          "error"
        );
        let res = []
        res.push(val[0])
        this.$nextTick(() => {
          this.dates = res
        })
      }
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
    let desde = this.$moment().subtract(1, "d").format('YYYY-MM-DD')
    let hasta = this.$moment().format('YYYY-MM-DD')
    this.dates.push(desde)
    this.dates.push(hasta)
    this.verificacion();
    this.initialize();
  },
  methods: {
    imprimirPdf () {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        targetStyles: ['*'],
        maxWidth: '100%',
        style: '@page { size: Letter landscape; }',
        css: 'printPdf'
      })
    },
    calcularTotal(item) {
      let monto = parseFloat(item.monto)
      for (let i = 0; i < item.cobros.length; i++) {
        const el = item.cobros[i];
        if (el.moneda == "BS" && el.tasa) {
          monto += parseFloat((parseFloat(el.monto) / parseFloat(el.tasa)).toFixed(2))
        } 
      }
      return monto
    },
    calcularTotalCobros(item) {
      let monto = 0
      for (let i = 0; i < item.length; i++) {
        const el = item[i];
        if (el.moneda == "BS" && el.tasa) {
          monto += parseFloat((parseFloat(el.monto) / parseFloat(el.tasa)).toFixed(2))
        } else monto += parseFloat(el.monto)
      }
      return monto
    },
    transformMonto(monto, tasa) {
      if (tasa) {
        return parseFloat((parseFloat(monto) / parseFloat(tasa)).toFixed(2))
      } else return parseFloat(monto)
    },
    async buscarValija() {
      let result = await this.$swal.fire({
        title: 'Ingrese el nro de valija',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
      })
      if (result.isConfirmed) {
        if (result.value) {
          this.dialog4 = true;
          let url = `valijas/nro?nro=${result.value.toUpperCase()}`
          let res = await Api.get(url)
          this.json_data = [];
          if (res.data && res.data.cobros && res.data.cobros.length > 0) {
            let acumBS = 0;
            let acumUS = 0;
            for (let i = 0; i < res.data.cobros.length; i++) {
              const el = res.data.cobros[i];
              let fcct = ""
              if (el.moneda == "US$") acumUS += parseFloat(el.monto)
              if (el.moneda == "BS") acumBS += parseFloat(el.monto)
              for (let e = 0; e < el.facturas.length; e++) {
                const el2 = el.facturas[e];
                if (e == (el.facturas.length-1)) {
                  fcct += el2.nro_doc.trim()
                } else fcct += el2.nro_doc.trim() + ", "
              }
              this.json_data.push({
                nro: el.nro,
                nombre: el.cliente.cli_des.trim(),
                rif: el.cliente.rif.trim(),
                nroFact: JSON.stringify(fcct),
                si: "",
                no: "",
                monto: el.monto + el.moneda,
                observacion: el.descripcion,
                adelanto: "",
                cruce: "",
              })
            }
            this.headerEx = [
              "RELACIÓN DE COBRO EN EFECTIVO",
              `<div style='text-align:left;'><strong>VENDEDOR: ${res.data.vendedor}</strong></div>`,
              `<div style='text-align:left;'><strong>FECHA: ${this.$moment(res.data.fecha).format('DD-MM-YYYY')}</strong></div>`,
              `<div style='text-align:left;'><strong>APP N° VALIJA: ${res.data.nro}</strong></div>`,
            ]
            if (acumUS > 0 && acumBS > 0) {
              this.footerEx = [
                `<div style='text-align:center;'><strong>SE ENVIA UN MONTO TOTAL DE: ${acumUS}$ y ${acumBS}BS</strong></div>`,
                "OBSERVACIÓN GENERAL:"
              ]
            } else if (acumUS > 0) {
              this.footerEx = [
                `<div style='text-align:center;'><strong>SE ENVIA UN MONTO TOTAL DE: ${acumUS}$</strong></div>`,
                "OBSERVACIÓN GENERAL:"
              ]
            } else if (acumBS > 0) {
              this.footerEx = [
                `<div style='text-align:center;'><strong>SE ENVIA UN MONTO TOTAL DE: ${acumBS}BS</strong></div>`,
                "OBSERVACIÓN GENERAL:"
              ]
            }
          }
          this.dialog4 = false;
          return this.json_data
        } else {
          this.$swal.fire(
            "¡Atención!",
            "Estimado usuario, debe ingresar un nro para continuar con la operación.",
            "error"
          );
          return []
        }
      } else return []
    },
    filterPerfectMatch(items, search, filter) {
      let inf = items
      search = search.toString().toLowerCase();
      if (search.trim() === '') {
        return items;
      }
      inf = items.filter(function (i) {
          return Object.keys(i).some(function (j) {
              return filter(i[j], search);
          });
      });
      return inf
    },
    transform2(date) {
      return this.$moment(date).format("DD/MM/YYYY H:mm A")
    },
    transform(date) {
      return this.$moment(date).format("DD/MM/YYYY")
    },
    cambiarFiltro() {
      let data = this.seleccionFiltro
      if (data) {
        this.resultados = []
        let res = []
        for (let i = 0; i < this.resultadosres.length; i++) {
          const el = this.resultadosres[i];
          if (data === "todos") {
            res.push(el)
          } else if (el.estatus === data) {
            res.push(el)
          }
        }
        this.dataExcel = []
        for (let i = 0; i < res.length; i++) {
          const el = res[i];
          this.dataExcel.push({
            nro: el.nro,
            nroDeposito: (el.nroDeposito) ? el.nroDeposito : "N/A",
            fecha: this.$moment(el.fecha).format('DD-MM-YYYY H:mm A'),
            fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
            vendedor: (el.vendedor) ? el.vendedor : "N/A",
            monto: (el.monto) ? el.monto : "N/A",
            montoD: (el.montoD) ? el.montoD : "N/A",
            banco: (el.banco) ? el.banco : "N/A",
            comentario: (el.comentario) ? el.comentario : "N/A",
            referencia: (el.referencia) ? el.referencia : "N/A",
            estatus: el.estatus
          })
        }
        this.$nextTick(() => {
          this.resultados = res
        })
      }
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Valija") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    buscarFecha() {
      this.dialog4 = true
      let hasta = (this.dates && this.dates[1]) ? this.dates[1] : null
      if (this.dates && this.dates[0]) {
        Api.get("valijas/admin?desde="+this.dates[0]+'&hasta='+hasta)
        .then(res => {
          this.dialog4 = false
          this.resultados = [];
          this.resultadosres = [];
          if (res.data && res.data.length > 0) {
            this.resultados = res.data.slice();
            this.resultadosres = res.data.slice();
            this.dataExcel = []
            for (let i = 0; i < res.data.length; i++) {
              const el = res.data[i];
              this.dataExcel.push({
                nro: el.nro,
                nroDeposito: (el.nroDeposito) ? el.nroDeposito : "N/A",
                fecha: this.$moment(el.fecha).format('DD-MM-YYYY H:mm A'),
                fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
                vendedor: (el.vendedor) ? el.vendedor : "N/A",
                monto: (el.monto) ? el.monto : "N/A",
                montoD: (el.montoD) ? el.montoD : "N/A",
                banco: (el.banco) ? el.banco : "N/A",
                comentario: (el.comentario) ? el.comentario : "N/A",
                referencia: (el.referencia) ? el.referencia : "N/A",
                estatus: el.estatus
              })
            }
          }
        }).catch(err=>{
          console.log(err)
          this.dialog4 = false
        })
      } else {
        this.$swal.fire(
          "¡Atención!",
          "Debes seleccionar al menos una fecha para realizar la operación",
          "error"
        );
      }
    },
    initialize() {
      this.date = new Date().toISOString().substr(0, 10)
      this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10))
      this.dates = []
      let desde = this.$moment().subtract(1, "d").format('YYYY-MM-DD')
      let hasta = this.$moment().format('YYYY-MM-DD')
      this.dates.push(desde)
      this.dates.push(hasta)
      this.dialog4 = true;
      Api.get("valijas/admin?desde="+this.dates[0]+'&hasta='+this.dates[1]).then(res => {
        this.resultados = []
        this.resultadosres = []
        if (res.data && res.data.length > 0) {
          this.resultados = res.data.slice();
          this.resultadosres = res.data.slice();
          this.dataExcel = []
          for (let i = 0; i < res.data.length; i++) {
            const el = res.data[i];
            this.dataExcel.push({
              nro: el.nro,
              nroDeposito: (el.nroDeposito) ? el.nroDeposito : "N/A",
              fecha: this.$moment(el.fecha).format('DD-MM-YYYY H:mm A'),
              fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
              vendedor: (el.vendedor) ? el.vendedor : "N/A",
              monto: (el.monto) ? el.monto : "N/A",
              montoD: (el.montoD) ? el.montoD : "N/A",
              banco: (el.banco) ? el.banco : "N/A",
              comentario: (el.comentario) ? el.comentario : "N/A",
              referencia: (el.referencia) ? el.referencia : "N/A",
              estatus: el.estatus
            })
          }
        }
        this.dialog4 = false;
      });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
    },
    editItem(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      console.log(this.form)
      this.dialog = true;
    },
    close3() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog3 = false;
    },
    editItem2(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog3 = true;
    },
    save(item) {
      let info
      if (item === "P") {
        info = {
          _id: this.form._id,
          caja: this.form.caja,
          banco: this.form.banco,
          profit: true,
          fechaP: new Date(),
          estatus: "Procesado"
        }
      } else {
        info = {
          _id: this.form._id,
          estatus: (item === "A") ? "Aprobado" : "Rechazado"
        }
        if (item === "A") info.fechaA = new Date()
        if (item === "R") info.fechaR = new Date()
      }
      if (info.estatus === "Rechazado") {
        this.$swal.fire({
          title: 'Motivo del rechazo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.dismiss) {
            console.log("paso cancel")
          } else if (result.value) {
            info.cobros = this.form.cobros
            info.observacion = result.value
            this.loading = true;
            Api.put("valijas", info)
              .then(() => {
                this.resultados.forEach(el=>{
                  if (el._id.toString() == this.form._id.toString()) {
                    el.estatus = info.estatus
                    el.observacion = info.observacion
                  }
                })
                this.resultadosres.forEach(el=>{
                  if (el._id.toString() == this.form._id.toString()) {
                    el.estatus = info.estatus
                    el.observacion = info.observacion
                  }
                })
                this.form = Object.assign({}, this.defaultForm);
                this.dialog = false;
                this.$swal.fire(
                  "Felicidades.!",
                  "Modificación realizada exitosamente.",
                  "success"
                );
                this.loading = false;
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
                this.$swal.fire(
                  "Oops...",
                  "Error encontrado, verifique los datos o llame al administrador.",
                  "error"
                );
            });
          } else {
            this.$swal.fire(
              "¡Atención!",
              "Debe ingresar el motivo del rechazo.",
              "error"
            );
          }
        })
      } else {
        if (info.estatus == "Procesado") {
          if (info.caja && info.banco) {
            this.loading = true;
            Api.put("valijas", info)
              .then((resp) => {
                if (resp.data.success) {
                  this.resultados.forEach(el=>{
                    if (el._id.toString() == this.form._id.toString()) {
                      el.estatus = info.estatus
                      if (info.banco) el.banco = info.banco
                      if (info.caja) el.caja = info.caja
                    }
                  })
                  this.resultadosres.forEach(el=>{
                    if (el._id.toString() == this.form._id.toString()) {
                      el.estatus = info.estatus
                      if (info.banco) el.banco = info.banco
                      if (info.caja) el.caja = info.caja
                    }
                  })
                  this.form = Object.assign({}, this.defaultForm);
                  this.dialog = false;
                  this.$swal.fire(
                    "Felicidades.!",
                    "Modificación realizada exitosamente.",
                    "success"
                  );
                } else {
                  this.$swal.fire(
                    "¡Atención!",
                    resp.data.error,
                    "error"
                  );
                }
                this.loading = false;
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
                this.$swal.fire(
                  "Oops...",
                  "Error encontrado, verifique los datos o llame al administrador.",
                  "error"
                );
            });
          } else {
            this.$swal.fire(
              "¡Atención!",
              "Para procesar la valija debes proporcionar los datos solicitados.",
              "error"
            );
          }
        } else {
          this.loading = true;
          Api.put("valijas", info)
            .then(() => {
              this.resultados.forEach(el=>{
                if (el._id.toString() == this.form._id.toString()) {
                  el.estatus = info.estatus
                  if (info.banco) el.banco = info.banco
                  if (info.caja) el.caja = info.caja
                }
              })
              this.resultadosres.forEach(el=>{
                if (el._id.toString() == this.form._id.toString()) {
                  el.estatus = info.estatus
                  if (info.banco) el.banco = info.banco
                  if (info.caja) el.caja = info.caja
                }
              })
              this.form = Object.assign({}, this.defaultForm);
              this.dialog = false;
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.loading = false;
            })
            .catch(err => {
              console.log(err);
              this.loading = false;
              this.$swal.fire(
                "Oops...",
                "Error encontrado, verifique los datos o llame al administrador.",
                "error"
              );
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
</style>
