<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Iconos
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12, md12, sm12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Cobros
    v-dialog(v-model='dialog6', persistent max-width='1000px')
      v-card
        v-card-text
          v-container(id="printJS-form2")
            div(align='center')
              h5(style='font-weight: 400 !important;') COBROS
            div(align='center')
              img(src='../assets/logo.png', style='width: 200px;margin-top: -20px;margin-bottom: -15px;')
            table(align="center" style='width: 100%;border-collapse: collapse;')
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Nro
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.nro}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Fecha
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.fechaC | moment('DD/MM/YYYY H:mm A')}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Método de pago
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.metodo}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Vendedor
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.vendedor}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Cliente
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.cliente.cli_des}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Titular
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.titular') {{form.titular}}
                  b(v-else) N/A
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Titular rif
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.rifTitular') {{form.rifTitular}}
                  b(v-else) N/A
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Facturas
                td.withTd2(align='center' style="padding:0px;")
                  div(v-for='item in form.facturas')
                    b {{item}}
              tr(v-if="form.tasa")
                td.withTd2(align='center' style="padding:0px;")
                  b Tasa
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.tasa}}BS
              tr(v-if="form.tasaIva")
                td.withTd2(align='center' style="padding:0px;")
                  b Tasa I.V.A.
                td.withTd2(align='center' style="padding:0px;")
                  b {{form.tasaIva}}BS
              tr(v-if="form.base")
                td.withTd2(align='center' style="padding:0px;")
                  b Base
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.moneda == "BS" && form.tasa') ${{transformMonto(form.base, form.tasa)}} ({{form.base}}BS)
                  b(v-if='form.moneda == "BS" && !form.tasa') {{form.base}}BS
                  b(v-if='form.moneda == "US$"') ${{form.base}}
              tr(v-if="form.iva")
                td.withTd2(align='center' style="padding:0px;")
                  b Iva
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.moneda == "BS" && form.tasaIva') ${{transformMonto(form.iva, form.tasaIva)}} ({{form.iva}}BS)
                  b(v-if='form.moneda == "BS" && !form.tasaIva') {{form.iva}}BS
                  b(v-if='form.moneda == "US$"') ${{form.iva}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Total
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.moneda == "BS" && form.tasa') ${{transformMontoG(form)}} ({{form.monto}}BS)
                  b(v-if='form.moneda == "BS" && !form.tasa') {{form.monto}}BS
                  b(v-if='form.moneda == "US$"') ${{form.monto}}
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Referencia
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.referencia') {{form.referencia}}
                  b(v-else) N/A
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Observación
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.observacion') {{form.observacion}}
                  b(v-else) N/A
              tr
                td.withTd2(align='center' style="padding:0px;")
                  b Descripción
                td.withTd2(align='center' style="padding:0px;")
                  b(v-if='form.descripcion') {{form.descripcion}}
                  b(v-else) N/A
            div(v-if='form.bauche' style="display: flex;justify-content: center;margin-top:20px;")
              img(:src='ruta + form.bauche' style='width: auto;height: 600px;object-fit:contain;')
        v-card-actions
          v-spacer
          v-btn(color='error' small dark @click='close4()')
            v-icon mdi-close
            | Cancelar
          v-btn(color='success' small dark @click="imprimirPdf2()")
            v-icon mdi-content-save
            | PDF  
    v-dialog(v-model='dialog3', persistent max-width='85%')
      v-card
        v-card-text
          v-container(id="printJS-form")
            div(align='center')
              h5(style='font-weight: 400 !important;text-decoration: underline;' v-if='dates && dates.length > 0') COBROS {{dates[0]}} - {{dates[1]}}
            br
            table(border="0" align="center" style='width: 100%;')
              tr
                td(align='center').withTd2
                  b N°
                td(align='center').withTd2
                  b FECHA
                td(align='center' style="line-height: 16px;").withTd2
                  b FECHA PAGO
                td(align='center').withTd2
                  b VENDEDOR
                td(align='center').withTd2
                  b CLIENTE
                td(align='center').withTd2
                  b METODO
                td(align='center').withTd2
                  b TIPO
                td(align='center').withTd2
                  b TASA
                td(align='center').withTd2
                  b TASA I.V.A.
                td(align='center').withTd2
                  b BASE
                td(align='center').withTd2
                  b IVA
                td(align='center').withTd2
                  b TOTAL
                td(align='center').withTd2
                  b REFERENCIA
                td(align='center').withTd2
                  b DESCRIPCIÓN
                td(align='center').withTd2
                  b ESTATUS
              tr(v-for='item in resultados')
                td(align='center').withTd2
                  b {{item.nro}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.fechaC | moment('DD/MM/YYYY H:mm A')}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.fechaP | moment('DD/MM/YYYY H:mm A')}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.vendedor}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.cliente.cli_des}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.metodo}}
                td(align='center' style="line-height: 16px;").withTd2
                  b(v-if="item.tipo") {{item.tipo}}
                  b(v-else) N/A
                td(align='center' style="line-height: 16px;").withTd2
                  b(v-if="item.tasa") {{item.tasa}}
                  b(v-else) N/A
                td(align='center' style="line-height: 16px;").withTd2
                  b(v-if="item.tasaIva") {{item.tasaIva}}
                  b(v-else) N/A
                td(align='center').withTd2
                  b(v-if='item.base && item.moneda == "BS" && item.tasa') ${{transformMonto(item, item.base, item.tasa)}} ({{item.base}}BS)
                  b(v-if='item.base && item.moneda == "BS" && !item.tasa') {{item.base}}BS
                  b(v-if='item.base && item.moneda == "US$"') ${{item.base}}
                  b(v-if='!item.base') N/A
                td(align='center').withTd2
                  b(v-if='item.iva && item.moneda == "BS" && item.tasaIva') ${{transformMonto(item.iva, item.tasaIva)}} ({{item.iva}}BS)
                  b(v-if='item.iva && item.moneda == "BS" && !item.tasaIva') {{item.iva}}BS
                  b(v-if='item.iva && item.moneda == "US$"') ${{item.iva}}
                  b(v-if='!item.iva') N/A
                td(align='center').withTd2
                  b(v-if='item.moneda == "BS" && item.tasa') ${{transformMontoG(item)}} ({{item.monto}}BS)
                  b(v-if='item.moneda == "BS" && !item.tasa') {{item.monto}}BS
                  b(v-if='item.moneda == "US$"') ${{item.monto}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.referencia}}
                td(align='center' style="line-height: 16px;").withTd2
                  b {{item.descripcion}}
                td(align='center').withTd2
                  b {{item.estatus}}

        v-card-actions
          v-spacer
          v-btn(color='error' small dark @click='close3()')
            v-icon mdi-close
            | Cancelar
          v-btn(color='success' small dark @click="imprimirPdf()")
            v-icon mdi-content-save
            | PDF  
    v-dialog(v-model='dialog5', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Cobro
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md8)
                          v-form(enctype="multipart/form-data")
                            
                            v-text-field(outline
                            v-if='form.base'
                            v-model="form.base"
                            type='number'
                            prepend-inner-icon="description"
                            label="Base:")
                            
                            v-text-field(outline
                            v-if='form.iva'
                            v-model="form.iva"
                            type='number'
                            prepend-inner-icon="description"
                            label="IVA:")
                            
                            v-text-field(outline
                            v-if='form.tasa'
                            type='number'
                            v-model="form.tasa"
                            prepend-inner-icon="description"
                            label="Tasa Base:")
                            
                            v-text-field(outline
                            v-if='form.tasaIva'
                            type='number'
                            v-model="form.tasaIva"
                            prepend-inner-icon="description"
                            label="Tasa Iva:")

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(style="background:green;border-radius:10px;", @click.native='saveMonto') Guardar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='closeMonto') Salir
    v-dialog(v-model='dialog', persistent max-width='700px')
          v-container(style='background-color:white !important;max-width: 700px;')
            v-card-title.justify-center
                span.headline Cobro
            div(style="margin-top: 20px;")
                v-flex(xs12, sm12, md12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs12, md8)
                          v-form(enctype="multipart/form-data")

                            
                            v-text-field(outline
                            readonly
                            v-model="form.metodo"
                            prepend-inner-icon="description"
                            label="Método de pago:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.vendedor"
                            prepend-inner-icon="description"
                            label="Vendedor:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.cliente.cli_des"
                            prepend-inner-icon="description"
                            label="Cliente:")
                            
                            v-text-field(outline
                            v-if='form.titular'
                            readonly
                            v-model="form.titular"
                            prepend-inner-icon="description"
                            label="Titular:")
                            
                            v-text-field(outline
                            v-if='form.rifTitular'
                            readonly
                            v-model="form.rifTitular"
                            prepend-inner-icon="description"
                            label="Titular rif:")
                            
                            v-select(outline
                            readonly
                            :items='itemsF'
                            multiple
                            v-model="form.facturas"
                            prepend-inner-icon="description"
                            label="Facturas:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.tasa"
                            v-if='form.tasa'
                            prepend-inner-icon="description"
                            label="Tasa:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.tasaIva"
                            v-if='form.tasaIva'
                            prepend-inner-icon="description"
                            label="Tasa I.V.A.:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.base"
                            v-if='form.base'
                            prepend-inner-icon="description"
                            label="Base:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.iva"
                            v-if='form.iva'
                            prepend-inner-icon="description"
                            label="Iva:")
                            
                            v-text-field(outline
                            readonly
                            v-model="form.monto"
                            prepend-inner-icon="description"
                            label="Total:")
                            
                            v-textarea(outline
                            readonly
                            v-model="form.descripcion"
                            prepend-inner-icon="description"
                            label="Descripción:")

                            div(style='display: flex;justify-content: center;margin: 10px;' v-if='form.bauche')
                              a(:href='ruta + form.bauche' target='_blank')
                                img(:src='ruta + form.bauche' style='width: 425px;height: auto;')

                            v-text-field(outline
                              v-if='form.nroCobro'
                              v-model="form.nroCobro"
                              readonly
                              prepend-inner-icon="description"
                              label="Nro. de cobro:")

                            v-text-field(outline
                              v-if='form.nroAJNM'
                              v-model="form.nroAJNM"
                              readonly
                              prepend-inner-icon="description"
                              label="Nro. de AJNM:")

                            v-text-field(outline
                              v-if='form.nroADEL'
                              v-model="form.nroADEL"
                              readonly
                              prepend-inner-icon="description"
                              label="Nro. de ADEL:")

                            v-text-field(outline
                              v-if='form.nroNI'
                              v-model="form.nroNI"
                              readonly
                              prepend-inner-icon="description"
                              label="Nro. de NI:")

                            v-text-field(outline
                              v-if='form.referencia'
                              v-model="form.referencia"
                              prepend-inner-icon="description"
                              label="Referencia:")

                            v-textarea(outline
                              v-if='form.observacion'
                              readonly
                              v-model="form.observacion"
                              prepend-inner-icon="description"
                              label="Observación:")

                            div
                              v-flex(v-if="loading === true", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                  v-progress-circular(indeterminate='', color='primary')
                              v-flex(v-if="loading === false", xs12 sm12 md12)
                                v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                                    v-btn(v-if='form.estatus === "Aprobado" && form.tipo !== "IVA"' style="background:green;border-radius:10px;", @click.native='save("P")') Procesado
                                    v-btn(v-if='form.estatus === "Pendiente"' style="background:green;border-radius:10px;", @click.native='save("A")') Aprobar
                                    v-btn(v-if='form.estatus === "Pendiente"' style="background:red;border-radius:10px;", @click.native='save("R")') Rechazar
                                    v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-flex(xs12 style='margin-top: 5px;')
          v-layout(wrap style='justify-content: space-between;')
            v-flex(xs4)
              v-text-field(v-model='search', label='Buscar')
            v-flex(xs4 style='display: flex;justify-content: center;padding-left:20px;')
              v-select(:items='filtros' v-model="seleccionFiltro" label="Estatus" @change='cambiarFiltro()')
            v-flex(xs4 style='display: flex;align-items: center;justify-content: flex-end;')
              //- v-menu(ref='menu1' v-model='modal' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
              //-   template(v-slot:activator='{ on, attrs }')
              //-     v-text-field(v-model='dateFormatted' label='Fecha' style='padding-left: 15px;' persistent-hint='' v-bind='attrs' @blur='date = parseDate(dateFormatted)' v-on='on')
              //-   v-date-picker(v-model='date' no-title='' @input='modal = false' dark='')
              v-menu(ref='menu1' v-model='modal' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='450px' min-width='450px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(v-model='dateRangeText' label='Fecha' style='padding-left: 15px;' persistent-hint='' v-bind='attrs' v-on='on')
                v-date-picker(multiple v-model='dates' no-title='' dark='')
                  v-spacer
                  v-btn(text='' color='primary' @click='$refs.menu1.save(dates)')
                    | OK
              v-btn.mx-0(icon @click='buscarFecha' style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
                v-icon(color='#ff6500' style='font-size: 1.70rem;') search
              vue-excel-xlsx( v-if='resultados && resultados.length > 0' :data='dataExcel' :columns='columnsExcel' :filename="'Fercoven-Cobros'" :sheetname="'sheetname'")
                div(style='display: flex;flex-direction: column;font-size: 12px;font-weight: bold;text-align: center;')
                  img(src='../assets/iconos/excel.png', style='height: 60px;width: auto;')
                  span Cobros
              vue-excel-xlsx( v-if='resultados2 && resultados2.length > 0' :data='dataExcel2' :columns='columnsExcel2' :filename="'Fercoven-Cobros-Vendedores'" :sheetname="'sheetname'")
                div(style='display: flex;flex-direction: column;font-size: 12px;font-weight: bold;text-align: center;')
                  img(src='../assets/iconos/excel.png', style='height: 60px;width: auto;')
                  span T.C.V
              a(style='margin-right: 12px;' @click="dialog3 = true")
                img(src='../assets/pdf.svg', style="height: 60px;width: auto;object-fit: contain;")
            v-flex(xs12 v-if='selected.length > 0')
              v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                v-btn(v-if='selected[0].estatus === "Aprobado"' style="background:green;border-radius:10px;", @click.native='saveAll("P")') Procesar
                v-btn(v-if='selected[0].estatus === "Pendiente"' style="background:green;border-radius:10px;", @click.native='saveAll("A")') Aprobar
                v-btn(v-if='selected[0].estatus === "Pendiente"' style="background:red;border-radius:10px;", @click.native='saveAll("R")') Rechazar
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
          v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
            span.white--text Lista de Cobros
        v-data-table.elevation-1(v-model="selected" :single-select="false" item-key="nro" ref='tablet1' :headers='headers' :pagination.sync="pagination" :search='search' :items="resultados" :custom-filter="filterPerfectMatch" style="margin-top: 5px;")
          template(slot='items', slot-scope='props')
            td 
              v-checkbox(
                :value='props.item'
                v-if="props.item.estatus == 'Pendiente' || (props.item.estatus == 'Aprobado' && props.item.tipo !== 'IVA')"
                style='margin: 0px;padding: 0px;'
                hide-details=''
                v-model="selected"
              )
            td {{ props.item.nro}}
            td {{ transform2(props.item.fechaC)}}
            td {{ transform(props.item.fechaP)}}
            td(v-if='!props.item.moneda') N/A
            td(v-else) {{props.item.moneda}}
            td(v-if='props.item.tasa') {{ props.item.tasa }}
            td(v-else) N/A
            td(v-if='props.item.tasaIva') {{ props.item.tasaIva }}
            td(v-else) N/A
            td {{ props.item.nroValija }}
            td {{ props.item.vendedor }}
            td(v-if='props.item.clienteN') {{ props.item.clienteN }}
            td(v-else) N/A
            td {{ props.item.metodo }}
            td(v-if='props.item.moneda == "BS" && props.item.tasa') ${{ transformMontoG(props.item) }} ({{props.item.monto}}BS)
            td(v-if='props.item.moneda == "BS" && !props.item.tasa') {{props.item.monto}}BS
            td(v-if='props.item.moneda == "US$"') ${{props.item.monto}}
            td(v-if='props.item.tipo') {{props.item.tipo}}
            td(v-else) N/A
            td(v-if='props.item.referencia') {{ props.item.referencia}}
            td(v-else) N/A
            td(v-if='props.item.nroCobro') {{ props.item.nroCobro}}
            td(v-else) N/A
            td {{ props.item.estatus}}
            td.justify-center.layout.px-0
              v-tooltip(bottom v-if='mod')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem(props.item)' v-on="on" color='teal') edit
                | Editar.
              v-tooltip(bottom v-if='mod && props.item.estatus === "Pendiente"')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItemMonto(props.item)' v-on="on" color='teal') description
                | Editar Monto.
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItemPdf(props.item)' v-on="on" color='red') description
                | PDF
              v-tooltip(bottom v-if='mod && props.item.estatus === "Pendiente"')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItemApro(props.item, "A")' v-on="on" color='teal') check_circle
                | Aprobar.
              v-tooltip(bottom v-if='mod && props.item.estatus === "Pendiente"')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItemApro(props.item, "R")' v-on="on" color='red') cancel
                | Rechazar.
              v-tooltip(bottom v-if='mod && props.item.estatus == "Aprobado" && props.item.tipo !== "IVA"')
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItemApro(props.item, "P")' v-on="on" color='teal') cloud
                | Procesar.
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
import printJS from 'print-js'
export default {
  name: "Cobros",
  data: vm => ({
    selected: [],
    json_data: [],
    dialog3: false,
    dialog5: false,
    dialog6: false,
    ruta: serverImg,
    dates: [],
    loading: false,
    dialog4: false,
    seleccionFiltro: 'todos',
    filtros: [
      {
        text: "Todos",
        value: "todos"
      },
      {
        text: "Pendientes",
        value: "Pendiente"
      },
      {
        text: "Aprobados",
        value: "Aprobado"
      },
      {
        text: "Rechazados",
        value: "Rechazado"
      },
      {
        text: "Procesados",
        value: "Procesado"
      },
      {
        text: "Finalizados",
        value: "Finalizado"
      },
    ],
    itemsF: [],
    dataExcel: [],
    dataExcel2: [],
    resultados2: [],
    columnsExcel2: [
      {
        label: "Fecha inicial",
        field: "fecha",
      },
      {
        label: "Código",
        field: "co_ven",
      },
      {
        label: "Vendedor",
        field: "ven_des",
      },
      {
        label: "Total efectivo",
        field: "total",
      },
    ],
    columnsExcel: [
      {
        label: "N° Web",
        field: "nro",
      },
      {
        label: "Fecha",
        field: "fechaC",
      },
      {
        label: "Fecha de pago",
        field: "fechaP",
      },
      {
        label: "Moneda",
        field: "moneda",
      },
      {
        label: "Tasa Base",
        field: "tasa",
      },
      {
        label: "Tasa IVA",
        field: "tasaIva",
      },
      {
        label: "N° Valija",
        field: "nroValija",
      },
      {
        label: "Vendedor",
        field: "vendedor",
      },
      {
        label: "Cliente",
        field: "cliente",
      },
      {
        label: "Método",
        field: "metodo",
      },
      {
        label: "Tipo",
        field: "tipo",
      },
      {
        label: "Base $",
        field: "base",
      },
      {
        label: "Base BS",
        field: "baseBS",
      },
      {
        label: "IVA $",
        field: "iva",
      },
      {
        label: "IVA BS",
        field: "ivaBS",
      },
      {
        label: "Total $",
        field: "monto",
      },
      {
        label: "Total BS",
        field: "montoBS",
      },
      {
        label: "Referencia",
        field: "referencia",
      },
      {
        label: "Titular",
        field: "titular",
      },
      {
        label: "Titular rif",
        field: "rifTitular",
      },
      {
        label: "Nro. Cobro",
        field: "nroCobro",
      },
      {
        label: "Facturas",
        field: "facturas",
      },
      {
        label: "Descripción",
        field: "descripcion",
      },
      {
        label: "Comentario Valija",
        field: "comentValija",
      },
      {
        label: "Estatus",
        field: "estatus",
      },
    ],
    pagination: {
      sortBy: 'fechaC', // The field that you're sorting by
      descending: true
    },
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    modal: false,
    search: "",
    form: {
      _id: "",
      nro: "",
      nroCobro: "",
      titular: "",
      moneda: "",
      tasa: "",
      tasaIva: "",
      rifTitular: "",
      nroAJNM: "",
      nroADEL: "",
      nroNI: "",
      vendedor: "",
      cliente: {},
      facturas: [],
      monto: 0,
      iva: 0,
      base: 0,
      tipo: "",
      referencia: "",
      descripcion: "",
      bauche: "",
      metodo: "",
      observacion: "",
      estatus: "",
      fechaP: "",
      fechaC: ""
    },
    dialog: false,
    resultadosres: [],
    resultados: [],
    headers: [
      { text: "Check", value: "check" },
      { text: "Nro", value: "nro" },
      { text: "Fecha", value: "fechaC" },
      { text: "Fecha de pago", value: "fechaP" },
      { text: "Moneda", value: "moneda" },
      { text: "Tasa B.", value: "tasa" },
      { text: "Tasa I.", value: "tasaIva" },
      { text: "N° Valija", value: "nroValija" },
      { text: "Vendedor", value: "vendedor" },
      { text: "Cliente", value: "clienteN" },
      { text: "Método", value: "metodo" },
      { text: "Monto", value: "monto" },
      { text: "Tipo", value: "iva" },
      { text: "Referencia", value: "referencia" },
      { text: "Nro. Cobro", value: "nroCobro" },
      { text: "Estatus", value: "estatus" },
      { text: "Acción", value: "accion" }
    ],
    defaultForm: {
      _id: "",
      nro: "",
      nroCobro: "",
      titular: "",
      rifTitular: "",
      vendedor: "",
      nroADEL: "",
      nroNI: "",
      moneda: "",
      tasa: "",
      tasaIva: "",
      nroAJNM: "",
      cliente: {},
      facturas: [],
      monto: 0,
      iva: 0,
      base: 0,
      tipo: "",
      descripcion: "",
      referencia: "",
      bauche: "",
      metodo: "",
      observacion: "",
      estatus: "",
      fechaP: "",
      fechaC: ""
    },
    cre: "",
    mod: "",
    elm: "",
    img: ""
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    dateRangeText () {
      let todos = []
      let desde
      let hasta
      if (this.dates && this.dates[0])  {
        desde = this.formatDate(this.dates[0])
        todos.push(desde)
      }
      if (this.dates && this.dates[1]) {
        hasta = this.formatDate(this.dates[1])
        todos.push(hasta)
      }
      return todos.join(' ~ ')
    },
  },
  watch: {
    selected(val) {
      if (val.length > 1) {
        const primerEstatus = val[0].estatus; // Obtener el estatus del primer registro
        for (let i = val.length - 1; i >= 0; i--) {
          if (val[i].estatus !== primerEstatus) {
            this.$swal.fire({
              position: "top-end",
              icon: "error",
              html: "<span style='font-size:20px;'>El registro con estatus "+this.selected[i].estatus+" no coincide los anteriores</span>",
              showConfirmButton: false,
              timer: 1500
            });
            this.selected.splice(i, 1);
          }
        }
      }
    },
    dates (val) {
      if (val && val.length > 2) {
        let res = []
        res.push(val[0])
        res.push(val[2])
        this.$nextTick(() => {
          this.dates = res
        })
      }
      if (val && val.length == 2 && val[0] > val[1]) {
        this.$swal.fire(
          "¡Atención!",
          "La fecha final debe ser mayor a la fecha inicial.",
          "error"
        );
        let res = []
        res.push(val[0])
        this.$nextTick(() => {
          this.dates = res
        })
      }
    },
    date () {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  created() {
    let desde = this.$moment().subtract(1, "d").format('YYYY-MM-DD')
    let hasta = this.$moment().format('YYYY-MM-DD')
    this.dates.push(desde)
    this.dates.push(hasta)
    this.verificacion();
    this.initialize();
  },
  methods: {
    close4() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog6 = false;
    },
    close3() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog3 = false;
    },
    imprimirPdf () {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        targetStyles: ['*'],
        maxWidth: '100%',
        style: '@page { size: Letter landscape; }',
        css: 'printPdf'
      })
    },
    imprimirPdf2 () {
      printJS({
        printable: 'printJS-form2',
        type: 'html',
        targetStyles: ['*'],
        maxWidth: '100%',
        style: '@page { size: Letter portrait; }',
        css: 'printPdf'
      })
    },
    transformMontoG(item) {
      if (item.tasa && item.tasaIva) {
        return ((parseFloat(item.base) / parseFloat(item.tasa))+(parseFloat(item.iva) / parseFloat(item.tasaIva))).toFixed(2)
      } else if (item.tasa && item.base) {
        return parseFloat((parseFloat(item.base) / parseFloat(item.tasa)).toFixed(2))
      } else return parseFloat(item.monto)
    },
    transformMonto(monto, tasa) {
      if (tasa) {
        return parseFloat((parseFloat(monto) / parseFloat(tasa)).toFixed(2))
      } else return parseFloat(monto)
    },
    filterPerfectMatch(items, search, filter) {
      let inf = items
      search = search.toString().toLowerCase();
      if (search.trim() === '') {
        if (inf && inf.length > 0) {
          this.dataExcel = []
          for (let i = 0; i < inf.length; i++) {
            const el = inf[i];
            let fcct = ""
            for (let j = 0; j < el.facturas.length; j++) {
              const el2 = el.facturas[j];
              if (i == (el.facturas.length-1)) {
                fcct += el2.nro_doc.trim() + "."
              } else fcct += el2.nro_doc.trim() + ", "
            }
            this.dataExcel.push({
              _id: el._id,
              nro: el.nro,
              fechaC: this.$moment(el.fechaC).format('DD-MM-YYYY H:mm A'),
              fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
              moneda: (el.moneda) ? el.moneda : "N/A",
              tasa: (el.tasa) ? el.tasa : "N/A",
              tasaIva: (el.tasaIva) ? el.tasaIva : "N/A",
              nroValija: (el.nroValija) ? el.nroValija : "N/A",
              vendedor: el.vendedor,
              cliente: el.cliente.cli_des,
              metodo: el.metodo,
              tipo: el.tipo ? el.tipo : "N/A",
              base: (el.base && el.moneda == "BS" && el.tasa) ? this.transformMonto(el.base, el.tasa) : (el.base && el.moneda == "US$") ? el.base : "N/A",
              baseBS: (el.base && el.moneda == "BS") ? el.base : 0,
              iva: (typeof el.iva == "number" && el.moneda == "BS" && el.tasaIva) ? this.transformMonto(el.iva, el.tasaIva) : (typeof el.iva == "number" && el.moneda == "US$") ? el.iva : "N/A",
              ivaBS: (typeof el.iva == "number" && el.moneda == "BS") ? el.iva : 0,
              monto: (el.moneda == "BS" && el.tasa) ? this.transformMontoG(el) : (el.moneda == "US$") ? el.monto : "N/A",
              montoBS: (el.moneda == "BS") ? el.monto : 0,
              referencia: (el.referencia) ? el.referencia : "N/A",
              titular: (el.titular) ? el.titular : "N/A",
              rifTitular: (el.rifTitular) ? el.rifTitular : "N/A",
              nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
              facturas: fcct,
              descripcion: el.descripcion,
              comentValija: (el.comentValija) ? el.comentValija : "N/A",
              estatus: el.estatus
            })
          }
        }
        return items;
      }
      inf = items.filter(function (i) {
          return Object.keys(i).some(function (j) {
              return filter(i[j], search);
          });
      });
      if (inf && inf.length > 0) {
        this.dataExcel = []
        for (let i = 0; i < inf.length; i++) {
          const el = inf[i];
          let fcct = ""
          for (let j = 0; j < el.facturas.length; j++) {
            const el2 = el.facturas[j];
            if (i == (el.facturas.length-1)) {
              fcct += el2.nro_doc.trim() + "."
            } else fcct += el2.nro_doc.trim() + ", "
          }
          this.dataExcel.push({
            _id: el._id,
            nro: el.nro,
            fechaC: this.$moment(el.fechaC).format('DD-MM-YYYY H:mm A'),
            fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
            moneda: (el.moneda) ? el.moneda : "N/A",
            tasa: (el.tasa) ? el.tasa : "N/A",
            tasaIva: (el.tasaIva) ? el.tasaIva : "N/A",
            nroValija: (el.nroValija) ? el.nroValija : "N/A",
            vendedor: el.vendedor,
            cliente: el.cliente.cli_des,
            metodo: el.metodo,
            tipo: el.tipo ? el.tipo : "N/A",
            base: (el.moneda == "BS" && el.tasa) ? this.transformMonto(el.base, el.tasa) : (el.moneda == "US$") ? el.base : "N/A",
            baseBS: (el.moneda == "BS") ? el.base : 0,
            iva: (el.moneda == "BS" && el.tasaIva) ? this.transformMonto(el.iva, el.tasaIva) : (el.moneda == "US$") ? el.iva : "N/A",
            ivaBS: (el.moneda == "BS") ? el.iva : 0,
            monto: (el.moneda == "BS" && el.tasa) ? this.transformMontoG(el) : (el.moneda == "US$") ? el.monto : "N/A",
            montoBS: (el.moneda == "BS") ? el.monto : 0,
            referencia: (el.referencia) ? el.referencia : "N/A",
            titular: (el.titular) ? el.titular : "N/A",
            rifTitular: (el.rifTitular) ? el.rifTitular : "N/A",
            nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
            facturas: fcct,
            descripcion: el.descripcion,
            comentValija: (el.comentValija) ? el.comentValija : "N/A",
            estatus: el.estatus
          })
        }
      }
      return inf
    },
    transform2(date) {
      return this.$moment(date).format("DD/MM/YYYY H:mm A")
    },
    transform(date) {
      return this.$moment(date).format("DD/MM/YYYY")
    },
    cambiarFiltro() {
      let data = this.seleccionFiltro
      if (data) {
        this.resultados = []
        this.dataExcel = []
        let res = []
        for (let i = 0; i < this.resultadosres.length; i++) {
          const el = this.resultadosres[i];
          // let fcct = ""
          // for (let j = 0; j < el.facturas.length; j++) {
          //   const el2 = el.facturas[j];
          //   if (i == (el.facturas.length-1)) {
          //     fcct += el2.nro_doc.trim() + "."
          //   } else fcct += el2.nro_doc.trim() + ", "
          // }
          if (data === "todos") {
            res.push(el)
            // this.dataExcel.push({
            //   _id: el._id,
            //   fechaC: this.$moment(el.fechaC).format('DD-MM-YYYY H:mm A'),
            //   fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
            //   moneda: (el.moneda) ? el.moneda : "N/A",
            //   nroValija: (el.nroValija) ? el.nroValija : "N/A",
            //   vendedor: el.vendedor,
            //   cliente: el.cliente.cli_des,
            //   metodo: el.metodo,
            //   monto: el.monto,
            //   referencia: (el.referencia) ? el.referencia : "N/A",
            //   nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
            //   facturas: fcct,
            //   descripcion: el.descripcion,
            //   comentValija: (el.comentValija) ? el.comentValija : "N/A",
            //   estatus: el.estatus
            // })
          } else if (el.estatus === data) {
            res.push(el)
            // this.dataExcel.push({
            //   _id: el._id,
            //   fechaC: this.$moment(el.fechaC).format('DD-MM-YYYY H:mm A'),
            //   fechaP: this.$moment(el.fechaP).format('DD-MM-YYYY H:mm A'),
            //   moneda: (el.moneda) ? el.moneda : "N/A",
            //   nroValija: (el.nroValija) ? el.nroValija : "N/A",
            //   vendedor: el.vendedor,
            //   cliente: el.cliente.cli_des,
            //   metodo: el.metodo,
            //   monto: el.monto,
            //   referencia: (el.referencia) ? el.referencia : "N/A",
            //   nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
            //   facturas: fcct,
            //   descripcion: el.descripcion,
            //   comentValija: (el.comentValija) ? el.comentValija : "N/A",
            //   estatus: el.estatus
            // })
          }
        }
        this.$nextTick(() => {
          this.resultados = res
          for (let i = 0; i < this.resultados.length; i++) {
            let el = this.resultados[i];
            el.clienteN = el.cliente && el.cliente.cli_des ? el.cliente.cli_des.trim() : 'N/A'
          }
        })
      }
    },
    verificacion() {
      for (let i = 0; i < this.$store.state.adminfercoven.permisos.length; i++) {
        if (this.$store.state.adminfercoven.permisos[i].ruta === "Cobros") {
          for (
            let j = 0;
            j < this.$store.state.adminfercoven.permisos[i].accion.length;
            j++
          ) {
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Crear"
            ) {
              this.cre = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] ===
              "Modificar"
            ) {
              this.mod = "yes";
            }
            if (
              this.$store.state.adminfercoven.permisos[i].accion[j] === "Eliminar"
            ) {
              this.elm = "yes";
            }
          }
        }
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    buscarFecha() {
      this.dialog4 = true
      let hasta = (this.dates && this.dates[1]) ? this.dates[1] : null
      if (this.dates && this.dates[0]) {
        this.selected = [];
        this.resultados = [];
        this.resultadosres = [];
        this.dataExcel = []
        Api.get("cobros/admin?desde="+this.dates[0]+'&hasta='+hasta)
        .then(res => {
          this.$nextTick(() => {
            if (res.data && res.data.length > 0) {
              for (let i = 0; i < res.data.length; i++) {
                const el = res.data[i];
                if (typeof el.cliente === "string") el.cliente = JSON.parse(el.cliente)
                let fact = []
                for (let j = 0; j < el.facturas.length; j++) {
                  const el2 = el.facturas[j];
                  if (typeof el2 === "string") {
                    fact = JSON.parse(el2)
                  } else fact.push(el2)
                }
                el.facturas = fact
                // let fcct = ""
                // for (let j = 0; j < el.facturas.length; j++) {
                //   const el2 = el.facturas[j];
                //   if (i == (el.facturas.length-1)) {
                //     fcct += el2.nro_doc.trim() + "."
                //   } else fcct += el2.nro_doc.trim() + ", "
                // }
                this.resultados.push(el)
                this.resultadosres.push(el)
                // this.dataExcel.push({
                //   _id: el._id,
                //   fechaC: this.$moment(el.fechaC).utc().format('DD-MM-YYYY H:mm A'),
                //   fechaP: this.$moment(el.fechaP).utc().format('DD-MM-YYYY H:mm A'),
                //   moneda: (el.moneda) ? el.moneda : "N/A",
                //   nroValija: (el.nroValija) ? el.nroValija : "N/A",
                //   vendedor: el.vendedor,
                //   cliente: el.cliente.cli_des,
                //   metodo: el.metodo,
                //   monto: el.monto,
                //   referencia: (el.referencia) ? el.referencia : "N/A",
                //   nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
                //   facturas: fcct,
                //   descripcion: el.descripcion,
                //   comentValija: (el.comentValija) ? el.comentValija : "N/A",
                //   estatus: el.estatus
                // })
              }
            }
            for (let i = 0; i < this.resultados.length; i++) {
              let el = this.resultados[i];
              el.clienteN = el.cliente && el.cliente.cli_des ? el.cliente.cli_des.trim() : 'N/A'
            }
            this.dialog4 = false
          })
        }).catch(err=>{
          console.log(err)
          this.dialog4 = false
        })
      } else {
        this.$swal.fire(
          "¡Atención!",
          "Debes seleccionar al menos una fecha para realizar la operación",
          "error"
        );
      }
    },
    initialize() {
      this.date = new Date().toISOString().substr(0, 10)
      this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10))
      this.dates = []
      let desde = this.$moment().subtract(1, "d").format('YYYY-MM-DD')
      let hasta = this.$moment().format('YYYY-MM-DD')
      this.dates.push(desde)
      this.dates.push(hasta)
      this.dataExcel = []
      this.dataExcel2 = []
      this.dialog4 = true;
      Api.get("cobros/adminReporte").then(res => {
        this.resultados2 = []
        if (res.data && res.data.length > 0) {
          this.resultados2 = res.data
          for (let i = 0; i < res.data.length; i++) {
            const el = res.data[i];
            this.dataExcel2.push({
              fecha: this.$moment(el.fecha).utc().format('DD-MM-YYYY H:mm A'),
              co_ven: el.co_ven,
              ven_des: el.ven_des,
              total: el.total
            })
          }
        }
      }).catch(err=>{
        console.log(err)
      });
      Api.get("cobros/admin?desde="+this.dates[0]+'&hasta='+this.dates[1]).then(res => {
        this.resultados = []
        this.resultadosres = []
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            const el = res.data[i];
            if (typeof el.cliente === "string") el.cliente = JSON.parse(el.cliente)
            let fact = []
            for (let j = 0; j < el.facturas.length; j++) {
              const el2 = el.facturas[j];
              if (typeof el2 === "string") {
                fact = JSON.parse(el2)
              } else fact.push(el2)
            }
            el.facturas = fact
            // let fcct = ""
            // for (let j = 0; j < el.facturas.length; j++) {
            //   const el2 = el.facturas[j];
            //   if (i == (el.facturas.length-1)) {
            //     fcct += el2.nro_doc.trim() + "."
            //   } else fcct += el2.nro_doc.trim() + ", "
            // }
            this.resultados.push(el)
            this.resultadosres.push(el)
            // this.dataExcel.push({
            //   _id: el._id,
            //   fechaC: this.$moment(el.fechaC).utc().format('DD-MM-YYYY H:mm A'),
            //   fechaP: this.$moment(el.fechaP).utc().format('DD-MM-YYYY H:mm A'),
            //   moneda: (el.moneda) ? el.moneda : "N/A",
            //   nroValija: (el.nroValija) ? el.nroValija : "N/A",
            //   vendedor: el.vendedor,
            //   cliente: el.cliente.cli_des,
            //   metodo: el.metodo,
            //   monto: el.monto,
            //   referencia: (el.referencia) ? el.referencia : "N/A",
            //   nroCobro: (el.nroCobro) ? el.nroCobro : "N/A",
            //   facturas: fcct,
            //   descripcion: el.descripcion,
            //   comentValija: (el.comentValija) ? el.comentValija : "N/A",
            //   estatus: el.estatus
            // })
          }
        }
        for (let i = 0; i < this.resultados.length; i++) {
          let el = this.resultados[i];
          el.clienteN = el.cliente && el.cliente.cli_des ? el.cliente.cli_des.trim() : 'N/A'
        }
        this.dialog4 = false;
      }).catch(err=>{
        this.dialog4 = false;
        console.log(err)
      });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
    },
    editItem(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.itemsF = []
      this.form.facturas = []
      for (let i = 0; i < item.facturas.length; i++) {
        const el = item.facturas[i];
        this.itemsF.push({
          value: el.nro_doc,
          text: el.nro_doc
        })
        this.form.facturas.push(el.nro_doc)
      }
      this.form._id = item._id;
      this.dialog = true;
    },
    editItemPdf(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.itemsF = []
      this.form.facturas = []
      for (let i = 0; i < item.facturas.length; i++) {
        const el = item.facturas[i];
        this.itemsF.push({
          value: el.nro_doc,
          text: el.nro_doc
        })
        this.form.facturas.push(el.nro_doc)
      }
      this.form._id = item._id;
      console.log(item)
      console.log(this.form)
      this.dialog6 = true;
    },
    editItemMonto(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form._id = item._id;
      this.dialog5 = true;
    },
    saveMonto() {
      let info = {
        _id: this.form._id,
        monto: 0
      }
      if (this.form.base) {
        info.base = parseFloat(this.form.base)
        info.monto += parseFloat(this.form.base)
      }
      if (this.form.iva) {
        info.iva = parseFloat(this.form.iva)
        info.monto += parseFloat(this.form.iva)
      }
      if (this.form.tasa) info.tasa = parseFloat(this.form.tasa)
      if (this.form.tasaIva) info.tasaIva = parseFloat(this.form.tasaIva)
      this.loading = true;
      Api.put("cobros", info)
        .then(() => {
          this.resultados.forEach(el=>{
            if (el._id.toString() == this.form._id.toString()) {
              el.monto = info.monto
              if (info.iva) el.iva = info.iva
              if (info.tasa) el.tasa = info.tasa
              if (info.tasaIva) el.tasaIva = info.tasaIva
            }
          })
          this.resultadosres.forEach(el=>{
            if (el._id.toString() == this.form._id.toString()) {
              el.monto = info.monto
              if (info.iva) el.iva = info.iva
              if (info.tasa) el.tasa = info.tasa
              if (info.tasaIva) el.tasaIva = info.tasaIva
            }
          })
          this.dataExcel.forEach(el=>{
            if (el._id.toString() == this.form._id.toString()) {
              el.monto = info.monto
              if (info.iva) el.iva = info.iva
              if (info.tasa) el.tasa = info.tasa
              if (info.tasaIva) el.tasaIva = info.tasaIva
            }
          })
          this.form = Object.assign({}, this.defaultForm);
          this.dialog5 = false;
          this.$swal.fire(
            "Felicidades.!",
            "Modificación realizada exitosamente.",
            "success"
          );
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$swal.fire(
            "Oops...",
            "Error encontrado, verifique los datos o llame al administrador.",
            "error"
          );
      });
    },
    closeMonto() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog5 = false;
    },
    editItemApro(item, type) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.itemsF = []
      this.form.facturas = []
      for (let i = 0; i < item.facturas.length; i++) {
        const el = item.facturas[i];
        this.itemsF.push({
          value: el.nro_doc,
          text: el.nro_doc
        })
        this.form.facturas.push(el.nro_doc)
      }
      this.form._id = item._id;
      this.save(type)
    },
    actualizarEstatusYObservacion(array, selected, info) {
      selected.forEach(el2 => {
        const match = array.find(el => el._id.toString() === el2._id.toString());
        if (match) {
          match.estatus = info.estatus;
          if(info.observacion) match.observacion = info.observacion;
        }
      });
    },
    saveAll(item) {
      let newArry = [];
      for (let i = 0; i < this.selected.length; i++) {
        let el = this.selected[i];
        if (item === "P") {
          el.profit = true
          el.estatus = "Procesado"
        } else el.estatus = (item === "A") ? "Aprobado" : "Rechazado"
        newArry.push({
          _id: el._id,
          profit: el.profit,
          estatus: el.estatus
        })
      }
      if (newArry[0].estatus === "Rechazado") {
        this.$swal.fire({
          title: 'Motivo del rechazo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.dismiss) {
            console.log("paso cancel")
          } else if (result.value) {
            for (let i = 0; i < newArry.length; i++) {
              let el = newArry[i];
              el.observacion = result.value
            }
            this.dialog4 = true;
            Api.put("cobros/all", newArry)
              .then(() => {
                this.actualizarEstatusYObservacion(this.resultados, newArry, newArry[0]);
                this.actualizarEstatusYObservacion(this.resultadosres, newArry, newArry[0]);
                this.actualizarEstatusYObservacion(this.dataExcel, newArry, newArry[0]);
                this.$nextTick(() => {
                  this.selected = [];
                  this.$swal.fire(
                    "Felicidades.!",
                    "Modificación realizada exitosamente.",
                    "success"
                  );
                  this.dialog4 = false;
                })
              })
              .catch(err => {
                console.log(err);
                this.dialog4 = false;
                this.$swal.fire(
                  "Oops...",
                  "Error encontrado, verifique los datos o llame al administrador.",
                  "error"
                );
            });
          } else {
            this.$swal.fire(
              "¡Atención!",
              "Debe ingresar el motivo del rechazo.",
              "error"
            );
          }
        })
      } else {
        this.dialog4 = true;
        Api.put("cobros/all", newArry)
          .then(() => {
            this.actualizarEstatusYObservacion(this.resultados, newArry, newArry[0]);
            this.actualizarEstatusYObservacion(this.resultadosres, newArry, newArry[0]);
            this.actualizarEstatusYObservacion(this.dataExcel, newArry, newArry[0]);
            this.$nextTick(() => {
              this.selected = [];
              this.$swal.fire(
                "Felicidades.!",
                "Modificación realizada exitosamente.",
                "success"
              );
              this.dialog4 = false;
            })
          })
          .catch(err => {
            console.log(err);
            this.dialog4 = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
        });
      }
    },
    save(item) {
      let info
      if (item === "P") {
        info = {
          _id: this.form._id,
          profit: true,
          referencia: this.form.referencia,
          estatus: "Procesado"
        }
      } else {
        info = {
          _id: this.form._id,
          referencia: this.form.referencia,
          estatus: (item === "A") ? "Aprobado" : "Rechazado"
        }
      }
      if (info.estatus === "Rechazado") {
        this.$swal.fire({
          title: 'Motivo del rechazo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.dismiss) {
            console.log("paso cancel")
          } else if (result.value) {
            info.observacion = result.value
            this.loading = true;
            Api.put("cobros", info)
              .then(() => {
                this.resultados.forEach(el=>{
                  if (el._id.toString() == this.form._id.toString()) el.estatus = info.estatus
                })
                this.resultadosres.forEach(el=>{
                  if (el._id.toString() == this.form._id.toString()) el.estatus = info.estatus
                })
                this.dataExcel.forEach(el=>{
                  if (el._id.toString() == this.form._id.toString()) el.estatus = info.estatus
                })
                this.form = Object.assign({}, this.defaultForm);
                this.dialog = false;
                this.$swal.fire(
                  "Felicidades.!",
                  "Modificación realizada exitosamente.",
                  "success"
                );
                this.loading = false;
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
                this.$swal.fire(
                  "Oops...",
                  "Error encontrado, verifique los datos o llame al administrador.",
                  "error"
                );
            });
          } else {
            this.$swal.fire(
              "¡Atención!",
              "Debe ingresar el motivo del rechazo.",
              "error"
            );
          }
        })
      } else {
        this.loading = true;
        Api.put("cobros", info)
          .then(() => {
            this.resultados.forEach(el=>{
              if (el._id.toString() == this.form._id.toString()) {
                el.estatus = info.estatus
              }
            })
            this.resultadosres.forEach(el=>{
              if (el._id.toString() == this.form._id.toString()) {
                el.estatus = info.estatus
              }
            })
            this.dataExcel.forEach(el=>{
              if (el._id.toString() == this.form._id.toString()) {
                el.estatus = info.estatus
              }
            })
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.$swal.fire(
              "Felicidades.!",
              "Modificación realizada exitosamente.",
              "success"
            );
            this.loading = false;
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
            this.$swal.fire(
              "Oops...",
              "Error encontrado, verifique los datos o llame al administrador.",
              "error"
            );
        });
      }
    }
  }
};
</script>
<style lang="scss">
</style>
