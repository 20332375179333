<template lang="pug">
v-container(style='height: 95vh;')
  v-layout(align-center='', justify-center='', text-xs-center='', wrap='' style='height: 90vh;')
    v-flex(xs12='' style='margin-top:0px;')
      img(src='../assets/logo.png', style='border-radius:10px;width:800px;height:auto;')
    //- v-flex(mb-4='')
    //-   h1.display-2.font-weight-bold.mb-3
    //-     | Bienvenido
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  data: () => ({
    ruta: serverImg,
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader"
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify"
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify"
      }
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com"
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com"
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuetifyjs.com"
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs"
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify"
      }
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer"
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/layout/pre-defined"
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions"
      }
    ]
  })
};
</script>

<style></style>
