<template lang="pug">
div(style="margin-top: -24px;margin-left: -15px;margin-right: -15px;")
  #Clientes
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12)
        v-card(color='', style="border-radius:5px;background-color: white;")
            v-card-title(style='padding: 8px !important;').justify-center
              img(src='../assets/logo.png', style='height: auto;width: 140px;')
        v-card(color='', style="border-radius:5px;background-color: black")
            v-card-title(style="padding: 7px;").justify-center
                span.white--text  Administración de Clientes
        div(style="margin-top: 20px;")
            v-layout(align-center='', justify-center='', row='', wrap fill-height='')
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinEst(false)')
                      v-card(style='width:300px;height:120px;border-radius:10px').elevation-3
                          span(style='display:flex;justify-content:center;font-size:20px') Clientes Profit
                          h1(style='display:flex;justify-content:center') {{profit}}
              v-flex(xs4 sm4 style="display: flex;justify-content: center;align-items: center;")
                v-btn(style="background:#ff6500;border-radius:10px;" @click='enviarEmailAll()') Enviar credenciales
              v-flex(xs4 sm4)
                  v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                    a(@click='sinEst(true)')
                      v-card(style='width:300px;height:120px;border-radius:10px' ).elevation-3
                        span(style='display:flex;justify-content:center;font-size:20px') Clientes Web
                        h1(style='display:flex;justify-content:center') {{web}}
    v-dialog(v-model='dialog2', persistent max-width='600px')
          v-container(style='background-color:white !important;max-width: 600px;')
            v-card-title.justify-center
                span.headline Cliente
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs10)
                          v-form(enctype="multipart/form-data")
                            v-text-field(outline
                            v-model="form.email"
                            prepend-inner-icon="description"
                            label="Usuario:")

                            v-text-field(outline
                            v-model="form.password"
                            prepend-inner-icon="description",
                            required
                            label="Contraseña:")

                            div
                              v-flex(xs12 sm12)
                                v-layout(wrap style='display:flex;justify-content:center;')
                                  v-btn(style="background:green;border-radius:10px;", @click.native='editCli') Asignar
                                  v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
    v-dialog(v-model='dialog', persistent max-width='900px')
          v-container(style='background-color:white !important;max-width: 900px;')
            v-card-title.justify-center
                span.headline Cliente
            div(style="margin-top: 20px;")
                v-flex(xs12 sm12)
                    v-layout(align-start='', justify-center='', row='', wrap fill-height='')
                        v-flex(xs10)
                          v-form(enctype="multipart/form-data")
                            v-text-field(outline
                            disabled
                            v-model="form.nombre"
                            prepend-inner-icon="description"
                            label="Nombre:")

                            v-textarea(outline
                            disabled
                            v-model="form.direccion"
                            prepend-inner-icon="description",
                            required
                            label="Dirección:")

                            v-text-field(outline
                            disabled
                            v-model="form.email"
                            prepend-inner-icon="email",
                            required
                            label="Correo:")

                            v-text-field(outline
                            disabled
                            v-model="form.estatus"
                            prepend-inner-icon="description",
                            required
                            label="Estatus:")

                            div(v-if='form.docRif')
                              v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom:10px;background: rgb(51, 0, 99);color: white;font-weight: 500;")
                                v-card-title.justify-center
                                  span Copia del rif

                              a(:href='ruta + form.docRif' target="_blank" v-if='!rif')
                                img(:src='ruta + form.docRif' style='width:100%;height:auto')
                              div(style='display:flex;justify-content:center')
                                a(style='width:60px;' :href='ruta + rif' target="_blank" v-if='rif')
                                  img(src='../assets/pdf.png', height='60', width='80')

                            div(v-if='form.docMercan')
                              v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom:10px;background: rgb(51, 0, 99);color: white;font-weight: 500;")
                                v-card-title.justify-center
                                  span Copia del registro mercantil
                              a(:href='ruta + form.docMercan' target="_blank" v-if='!mer')
                                img(:src='ruta + form.docMercan' style='width:100%;height:auto')
                              div(style='display:flex;justify-content:center')
                                a(style='width:60px;' :href='ruta + mer' target="_blank" v-if='mer')
                                  img(src='../assets/pdf.png', height='60', width='80')

                            div(v-if='form.docCI')
                              v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom:10px;background: rgb(51, 0, 99);color: white;font-weight: 500;")
                                v-card-title.justify-center
                                  span Copia de cédula de representante legal
                              a(:href='ruta + form.docCI' target="_blank" v-if='!ci')
                                img(:src='ruta + form.docCI' style='width:100%;height:auto')
                              div(style='display:flex;justify-content:center')
                                a(style='width:60px;' :href='ruta + ci' target="_blank" v-if='ci')
                                  img(src='../assets/pdf.png', height='60', width='80')

                            div(v-if='form.docRef')
                              v-card(style="margin-right: 0px;margin-top: 10px;margin-bottom:10px;background: rgb(51, 0, 99);color: white;font-weight: 500;")
                                v-card-title.justify-center
                                  span Referencia bancaria
                              a(:href='ruta + form.docRef' target="_blank" v-if='!ref')
                                img(:src='ruta + form.docRef' style='width:100%;height:auto')
                              div(style='display:flex;justify-content:center')
                                a(style='width:60px;' :href='ruta + ref' target="_blank" v-if='ref')
                                  img(src='../assets/pdf.png', height='60', width='80')

                            div
                              v-flex(xs12 sm12)
                                v-layout(wrap style='display:flex;justify-content:center;')
                                  v-btn(v-if='form.estatus === "Pendiente" && form.email && form.password' style="background:green;border-radius:10px;", @click.native='save') Aprobar
                                  v-btn(style="background:black;border-radius:10px;", @click.native='close') Salir
                                  v-btn(v-if='form.estatus === "Pendiente" && form.email && form.password' style="background:red;border-radius:10px;", @click.native='rechazo') Rechazar
    v-layout(align-start='', justify-start='', row='', fill-height='')
      v-flex(xs12 style='margin-top: 5px;')
        v-layout(wrap)
          v-flex(xs6)
            v-text-field(v-model='search', label='Buscar')
          v-flex(xs2 style='display: flex;align-items: center;')
            v-btn.mx-0(icon, @click='busqinit(search)', style='background: white;border-radius: 100%;margin-left:10px !important;').elevation-4
              v-icon(color='#ff6500' style='font-size: 1.70rem;') search
        v-card(color='', style="border-radius: 0px 0px 20px 20px;background-color:black;")
            v-card-title(style="padding: 7px;margin-top: 0px;").justify-center
                span.white--text Lista de Clientes
        v-data-table.elevation-1(:headers='headers' :items="clientes", style="margin-top: 5px;" hide-actions, :pagination.sync="pagination")
          template(slot='items', slot-scope='props')
            td(v-if='props.item.nombre') {{ props.item.nombre }}
            td(v-if='!props.item.nombre') {{ 'N/A' }}
            td(v-if='props.item.rif') {{ props.item.rif }}
            td(v-if='!props.item.rif') {{ 'N/A' }}
            td(v-if='props.item.email') {{ props.item.email }}
            td(v-if='!props.item.email') {{ 'N/A' }}
            //- td(v-if='props.item.direccion') {{ props.item.direccion }}
            //- td(v-if='!props.item.direccion') {{ 'N/A' }}
            td(v-if='props.item.estatus === "Aprobado"' style='display: flex;')
              v-switch(v-model='props.item.stock' @change='actu(props.item)')
            td(v-else) Se espera aprobación
            td {{ props.item.estatus }}
            td.justify-start.layout.px-0
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='enviarEmail(props.item)' v-on="on" color='black') mail
                | Enviar correo de credenciales.
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem(props.item)' v-on="on" color='teal') visibility
                | Ver información
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon.mr-2(@click='editItem2(props.item)' v-on="on" color='teal') edit
                | Editar credenciales
        v-card
          v-flex(xs12)
            v-layout(wrap='')
              v-flex(xs6)
              v-flex(xs5 style='display: flex;justify-content: flex-end;align-items: center;')
                div(style='margin: 10px;')
                  span {{min}} a {{max}} elementos
                div(style='margin: 2px;')
                  v-btn.mx-0(id='prev' icon, @click='prev()', style='background: gainsboro;border-radius:50%;' hidden)
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_left
                div(style='margin: 2px;' v-for='(item,index) in listado')
                  v-btn.mx-0(:id='"num"+item', @click='seleccion(item)' icon, style='background: gainsboro;border-radius:50%;')
                    span(style='font-weight: bold;') {{item}}
                div(style='margin: 2px;')
                  v-btn.mx-0(id='next' icon, @click='next()', style='background: gainsboro;border-radius:50%;')
                    v-icon(color='black' style='font-size: 1.8rem;') arrow_right
              v-flex(xs1)
              v-flex(xs11 style='display: flex;justify-content: flex-end;')
                v-flex#sll(xs2 style='display: flex;justify-content: flex-end;align-items: center;margin-right: 17px;')
                  v-select(id="paginas"
                  outline
                  @change='seleccion(selpag)'
                  :items="paginas"
                  v-model="selpag"
                  dense
                  required
                  label="Página:")
  //===========================================================Modal Espera==============================================================================================
  v-dialog(v-model='dialog4', persistent max-width='400px' style='background:#f1f2f1')
    v-container(style='max-width: 400px;background:#f1f2f1')
      div(style="margin-top: 20px;")
          v-flex(xs12 sm12)
            v-layout(align-start='', justify-center='', row='', wrap fill-height='')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h4.black--text  Esperando Respuesta del Servidor.!!
              v-flex(xs12)
                  div(style='display: flex;justify-content: center;').text-xs-center
                    img(src='../assets/waiting.gif', with='200' ,height='150')
              v-flex(xs12)
                v-card-title(style="padding: 7px;").justify-center
                  h5.black--text  Por favor espere...
</template>

<script>
import Api from "@/services/methods";
import { serverImg } from "@/services/environment";
export default {
  name: "mClientes",
  data: () => ({
    rif: "",
    mer: "",
    search: "",
    dialog4: false,
    dialog2: false,
    pagination: {
      rowsPerPage: 20
    },
    ci: "",
    ref: "",
    form: {
      nombre: "",
      direccion: "",
      email: "",
      imagen: "",
      password: "",
      docRif: "",
      docMercan: "",
      stock: false,
      docCI: "",
      docRef: "",
      estatus: ""
    },
    profit: 0,
    web: 0,
    listado: [],
    filtro: false,
    dialog: false,
    ruta: serverImg,
    clientes: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Rif", value: "rif" },
      { text: "Correo", value: "email" },
      // { text: "Dirección", value: "direccion" },
      { text: "Mostrar Stock", value: "stock" },
      { text: "Estatus", value: "estatus" },
      { text: "Acción" }
    ],
    min: 0,
    paginas: [],
    max: 0,
    selpag: "",
    actual: 0,
    defaultForm: {
      nombre: "",
      direccion: "",
      email: "",
      imagen: "",
      docRif: "",
      password: "",
      stock: false,
      docMercan: "",
      docCI: "",
      docRef: "",
      estatus: ""
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    seleccion(item) {
      this.dialog4 = true;
      Api.get(
        "clientesWeb/pag?search=" +
          this.search +
          "&filtro" +
          this.filtro +
          "&id=" +
          item
      )
        .then(res => {
          this.dialog4 = false;
          this.max = res.data.max;
          this.min = this.max - res.data.resultado.length;
          this.clientes = res.data.resultado;
          for (let i = 1; i <= res.data.paginas; i++) {
            this.paginas.push(i);
          }
          document
            .getElementById("num" + this.actual)
            .setAttribute(
              "style",
              "background-color:gainsboro;border-radius:50%;"
            );
          document.getElementById("next").hidden = false;
          document.getElementById("prev").hidden = false;
          if (this.min === 0) {
            document.getElementById("prev").hidden = true;
          }
          if (res.data.tope === true) {
            document.getElementById("next").hidden = true;
          }
          let a = item;
          this.listado = [];
          for (let i = 0; i < 4; i++) {
            if (a <= res.data.pag) {
              this.listado.push(a);
            }
            a++;
          }
          this.$nextTick(() => {
            this.actual = item;
            this.selpag = item;
            if (
              this.listado.length > 0 &&
              document.getElementById("num" + this.actual)
            ) {
              document
                .getElementById("num" + this.actual)
                .setAttribute(
                  "style",
                  "background-color:#adadad;border-radius:50%;"
                );
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    enviarEmailAll() {
      this.dialog4 = true;
      Api.get("clientesWeb/credencialesEmailAll")
        .then(res => {
          this.$swal.fire(
            "Felicidades.!",
            "Corres enviados satisfactoriamente.",
            "success"
          );
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.error(err);
          this.$swal.fire({
            title: "Error de encontrado",
            text:
              "Error al conectar con el servidor, por favor intente nuevamente.",
            icon: "error"
          });
        });
    },
    enviarEmail(item) {
      this.dialog4 = true;
      Api.post("clientesWeb/credencialesEmail", item)
        .then(res => {
          this.$swal.fire(
            "Felicidades.!",
            "Correo electrónico enviado satisfactoriamente.",
            "success"
          );
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.error(err);
          this.$swal.fire({
            title: "Error de encontrado",
            text:
              "Error al conectar con el servidor, por favor intente nuevamente.",
            icon: "error"
          });
        });
    },
    actu(item) {
      let info = {
        _id: item._id,
        stock: item.stock
      }
      Api.put("clientesWeb/web", info).then(res => {
        this.$swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Modificación realizada exitosamente.',
          showConfirmButton: false,
          timer: 1500
        })
      });
    },
    prev() {
      let info = {
        estatus: this.seleccionado,
        max: this.min,
        ult: false,
        num: null
      };
      let dat = this.actual - 1;
      if (dat < this.listado[0]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "clientesWeb/prev?search=" +
          this.search +
          "&filtro" +
          this.filtro,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max + 20;
            this.min = res.data.max;
            this.clientes = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("next").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("prev").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[0] - 5;
            this.listado = [];
            if (a < 0) {
              a = 0;
              document.getElementById("prev").hidden = true;
            }
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual - 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual-1))) {
                  document.getElementById("num"+(this.actual-1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-2))) {
                  document.getElementById("num"+(this.actual-2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual-3))) {
                  document.getElementById("num"+(this.actual-3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual - 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    next() {
      let info = {
        estatus: this.seleccionado,
        max: this.max,
        ult: false,
        num: null
      };
      let dat = this.actual + 1;
      if (dat > this.listado[this.listado.length - 1]) {
        info.ult = true;
        info.num = dat;
      }
      this.dialog4 = true;
      Api.post(
        "clientesWeb/next?search=" +
          this.search +
          "&filtro" +
          this.filtro,
        info
      )
        .then(res => {
          if (res.data.resultado.length > 0) {
            this.max = res.data.max;
            this.min = this.max - res.data.resultado.length;
            this.clientes = res.data.resultado;
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            document.getElementById("prev").hidden = false;
            if (res.data.tope === true) {
              document.getElementById("next").hidden = true;
            }
          }
          if (info.ult) {
            let a = this.listado[this.listado.length - 1];
            this.listado = [];
            for (let i = 0; i < 4; i++) {
              a++;
              if (a <= res.data.pag) {
                this.listado.push(a);
              }
            }
            this.$nextTick(() => {
              this.actual = this.actual + 1;
              this.selpag = this.actual;
              if (this.listado.length > 0 && document.getElementById("num"+this.actual)) {
                document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
                if (document.getElementById("num"+(this.actual+3))) {
                  document.getElementById("num"+(this.actual+3)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+2))) {
                  document.getElementById("num"+(this.actual+2)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
                if (document.getElementById("num"+(this.actual+1))) {
                  document.getElementById("num"+(this.actual+1)).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
                }
              }
            });
          } else {
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:gainsboro;border-radius:50%;");
            this.actual = this.actual + 1;
            this.selpag = this.actual;
            document.getElementById("num"+this.actual).setAttribute("style", "background-color:#adadad;border-radius:50%;");
          }
          this.dialog4 = false;
        })
        .catch(err => {
          this.dialog4 = false;
          console.log(err);
        });
    },
    busqinit(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      Api.get(
        "clientesWeb/sinseleccion?search=" +
          item +
          "&filtro=" +
          this.filtro
      )
        .then(res => {
          this.clientes = [];
          this.listado = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.clientes.push(res.data.resultado[i]);
          }
          this.max = this.clientes.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    sinEst(item) {
      document.getElementById("prev").hidden = true;
      document.getElementById("next").hidden = false;
      this.dialog4 = true;
      this.filtro = item;
      Api.get("clientesWeb/sinseleccion?filtro=" + this.filtro)
        .then(res => {
          this.clientes = [];
          this.listado = [];
          this.paginas = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.clientes.push(res.data.resultado[i]);
          }
          this.profit = res.data.profit
          this.web = res.data.web
          this.max = this.clientes.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
              if (document.getElementById("num2")) {
                document.getElementById("num2").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num3")) {
                document.getElementById("num3").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
              if (document.getElementById("num4")) {
                document.getElementById("num4").setAttribute("style", "background-color:gainsboro;border-radius:50%;");
              }
            });
            this.actual = 1;
            this.selpag = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    initialize() {
      this.dialog4 = true
      Api.get("clientesWeb/sinseleccion?filtro=" + this.filtro)
        .then(res => {
          this.clientes = [];
          for (let i = 0; i < res.data.resultado.length; i++) {
            this.clientes.push(res.data.resultado[i]);
          }
          this.profit = res.data.profit
          this.web = res.data.web
          this.max = this.clientes.length;
          if (this.max === 0) {
            this.min = 0;
            document.getElementById("next").hidden = true;
          } else {
            if (this.max < 20) {
              this.min = 0;
              document.getElementById("next").hidden = true;
            } else {
              this.min = this.max - 20;
            }
            for (let i = 0; i < res.data.pag; i++) {
              this.listado.push(i + 1);
            }
            for (let i = 1; i <= res.data.paginas; i++) {
              this.paginas.push(i);
            }
            this.$nextTick(() => {
              if (this.listado.length > 0 && document.getElementById("num1")) {
                document
                  .getElementById("num1")
                  .setAttribute("style", "background-color:#adadad;border-radius:50%;");
              }
            });
            this.selpag = 1;
            this.actual = 1;
          }
          this.dialog4 = false;
        })
        .catch(err => {
          console.log(err);
          this.dialog4 = false;
        });
    },
    close() {
      this.form = Object.assign({}, this.defaultForm);
      this.dialog = false;
      this.dialog2 = false;
      // this.initialize();
    },
    editItem2(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      this.form.co_cli = item.co_cli
      this.form._id = item._id;
      this.dialog2 = true;
    },
    editItem(item) {
      Object.keys(this.form).map(key => {
        if (item[key]) this.form[key] = item[key];
      });
      if (item.docRif) {
        let res = item.docRif.split(".");
        if (res[res.length - 1] === "pdf") {
          this.rif = item.docRif;
        }
      }
      if (item.docMercan) {
        let res = item.docMercan.split(".");
        if (res[res.length - 1] === "pdf") {
          this.mer = item.docMercan;
        }
      }
      if (item.docCI) {
        let res = item.docCI.split(".");
        if (res[res.length - 1] === "pdf") {
          this.ci = item.docCI;
        }
      }
      if (item.docRef) {
        let res = item.docRef.split(".");
        if (res[res.length - 1] === "pdf") {
          this.ref = item.docRef;
        }
      }
      this.form._id = item._id;
      this.dialog = true;
    },
    rechazo() {
      this.$swal
        .fire({
          title: "Ingrese el motivo de rechazo",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          showLoaderOnConfirm: false
        })
        .then(result => {
          if (result.value) {
            let info = {
              _id: this.form._id,
              estatus: "Rechazado",
              nota: result.value
            };
            Api.put("clientesWeb/admin", info)
              .then(res => {
                this.form = Object.assign({}, this.defaultForm);
                this.dialog = false;
                this.dialog2 = false;
                for (let i = 0; i < this.clientes.length; i++) {
                  if (this.clientes[i]._id.toString() === res.data.data._id.toString() ) {
                    this.$set(this.clientes, i, res.data.data);
                  }
                }
                this.$swal.fire(
                  "Felicidades.!",
                  "Rechazo realizado exitosamente.",
                  "success"
                );
                // this.close();
              })
              .catch(err => {
                console.error(err);
                this.$swal.fire({
                  title: "Error de encontrado",
                  text:
                    "Error al conectar con el servidor, por favor intente nuevamente.",
                  icon: "error"
                });
              });
          } else {
            this.$swal.fire(
              "Error encontrado",
              "Estimado usuario, debe ingresar el motivo del rechazo para continuar con la operación",
              "error"
            );
          }
        });
    },
    editCli() {
      if (this.form.email && this.form.password) {
        this.dialog4 = true
        this.form.email = this.form.email.toLowerCase()
        this.form.estatus = "Aprobado"
        Api.put("clientesWeb/editarCre", this.form)
        .then(res => {
          if (res.data.error) {
            this.$swal.fire(
              "¡Atención!",
              res.data.error,
              "error"
            );
          } else {
            this.form = Object.assign({}, this.defaultForm);
            this.dialog = false;
            this.dialog2 = false;
            for (let i = 0; i < this.clientes.length; i++) {
              if (this.clientes[i]._id.toString() === res.data.data._id.toString() ) {
                this.$set(this.clientes, i, res.data.data);
              }
            }
            this.$swal.fire(
              "Felicidades.!",
              "Cliente modificado exitosamente.",
              "success"
            );
            // this.close();
          }
          this.dialog4 = false
        })
        .catch(err => {
          this.dialog4 = false
          console.error(err);
          this.$swal.fire({
            title: "Error de encontrado",
            text:
              "Error al conectar con el servidor, por favor intente nuevamente.",
            icon: "error"
          });
        });
      } else {
        this.$swal.fire(
          "¡Atención!",
          "Estimado usuario, debe ingresar todos los datos solicitados para continuar.",
          "error"
        );
      }
    },
    save() {
      this.$swal
        .fire({
          title: "Ingrese el codigo del cliente de Profit",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          showLoaderOnConfirm: false
        })
        .then(result => {
          if (result.value) {
            let info = {
              _id: this.form._id,
              estatus: "Aprobado",
              co_cli: result.value
            };
            Api.put("clientesWeb/admin", info)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Cliente aprobado exitosamente.",
                  "success"
                );
                this.form = Object.assign({}, this.defaultForm);
                this.dialog = false;
                this.dialog2 = false;
                console.log(res.data)
                for (let i = 0; i < this.clientes.length; i++) {
                  if (this.clientes[i]._id.toString() === res.data._id.toString() ) {
                    this.$set(this.clientes, i, res.data);
                  }
                }
                // this.close();
              })
              .catch(err => {
                console.error(err);
                this.$swal.fire({
                  title: "Error de encontrado",
                  text:
                    "Error al conectar con el servidor, por favor intente nuevamente.",
                  icon: "error"
                });
              });
          } else {
            this.$swal.fire(
              "Error encontrado",
              "Estimado usuario, debe ingresar el codigo del cliente para continuar con la operación",
              "error"
            );
          }
        });
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "¿Estás seguro?",
          text: "No podrás revertir esta operación!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!"
        })
        .then(result => {
          if (result.value) {
            Api.delete("clientesWeb/" + item._id)
              .then(res => {
                this.$swal.fire(
                  "Felicidades.!",
                  "Eliminación realizada exitosamente.",
                  "success"
                );
                this.initialize();
              })
              .catch(err => {
                console.error(err);
              });
          }
        });
    }
  }
};
</script>
